<div class="fivef-message-panel--panel" *ngIf="avatar$">
  <!-- Avatar -->
  <span class="fivef-message-panel--panel--performer">
      <fivef-avatar [size]="'sm'" [email]="avatar$" [matTooltip]="avatar$?.name"
                    [showTitle]="false"></fivef-avatar>
    </span>

  <!-- Right aligned content box -->
  <div class="fivef-message-panel--panel--container">
    <div class="fivef-message-panel--panel--inner-wrapper">
      <ul class="fivef-message-panel--panel--main">
        <!-- Header line: Date/performer -->
        <li class="fivef-message-panel--header">
          <div class="fivef-message-panel--header--left">
                <span
                  class="fivef-message-panel--created-at">{{ _message.createdAt | date : dateFormat }}
                  <ng-container
                    *ngIf="_message?.createdAt !== _message?.updatedAt"><em> ({{ 'GENERAL.EDITED' | translate }}) </em></ng-container> - <strong>{{ avatar$?.name || avatar$?.email }}</strong>
                </span>
          </div>

          <!-- Comment toolbar & artifact / Collecto element reference -->
          <div class="fivef-message-panel--header--right">
            <div fivefFetchCollectorItem
                 [fetchCollectorItemId]="_message?.itemId"
                 [fetchCollectorItemProcessId]="_message?.processId"
                 class="fivef-message-panel--reference-bar">
              <ng-container *ngIf="item$ | async as item">
                    <span [matTooltip]="item?.categoryTitle + ' / ' + item?.itemTitle"
                          matTooltipPosition="left">
                      <mat-icon class="fivef-message-panel--collector-reference-icon">playlist_add_check</mat-icon>
                      <span class="fivef-message-panel--collector-grouping">
                        {{ ((item?.categoryTitle | translate)?.length > 22) ? (item?.categoryTitle | translate | slice:0:22) : (item?.categoryTitle | translate) }}
                        {{ ((item?.categoryTitle | translate)?.length > 22) ? '...' : '' }}
                      </span>
                      <span class="fivef-message-panel--collector-grouping fivef-message-panel--reference-bar--collector-item">
                        <a [routerLink]="'/collecto/run/' + item.processId + '/dashboard'"
                           [queryParams]="{tabId: 1, itemId: item.id}">
                           {{ ((item?.itemTitle | translate)?.length > 22) ? (item?.itemTitle | translate | slice:0:22) : (item?.itemTitle | translate) }}
                          {{ ((item?.itemTitle | translate)?.length > 22) ? '...' : '' }}</a>
                      </span>
                    </span>
              </ng-container>
            </div>

            <!-- Artifact reference -->
            <div *ngIf="_message.type == ProcessEventType.ArtifactComment"
                 class="fivef-message-panel--reference-bar"
                 [class.fivef-message-panel--active-artifact-link]="process">
                  <span [matTooltip]="_message?.title"
                        matTooltipPosition="left"
                        (click)="openPreviewDialog()">
                    <mat-icon class="fivef-message-panel--artifact-reference-icon">insert_drive_file</mat-icon>
                    <span class="fivef-message-panel--collector-grouping">
                      {{ ((_message?.title | translate)?.length > 22) ? (_message?.title | translate | slice:0:22) : (_message?.title | translate) }}
                      {{ ((_message?.title | translate)?.length > 22) ? '...' : '' }}
                    </span>
                  </span>
            </div>

            <!-- Top right comment 3-dots menu -->
            <div class="fivef-expansion-panel--actions-menu">
              <ng-template [ngIf]="_message.type === ProcessEventType.InstantMessage">
                <dvtx-message-panel-info-dropdown style="margin: -2px"
                                                  [title]="'GENERAL.VISIBILITY' | translate"
                                                  [icon]="'supervised_user_circle'"
                                                  [persons]='recipients$'
                                                  [showAvatarTitle]='true'></dvtx-message-panel-info-dropdown>
              </ng-template>
              <ng-template [ngIf]="_message.type !== ProcessEventType.ArtifactComment">
                <dvtx-message-panel-action-dropdown [title]="'PROJECT_ROOM.ACTIONS' | translate"
                                                    class="ml-1"
                                                    [isClosed]="process?.status._code === 'closed' || readonly"
                                                    [isAuthor]="isFromCurrentUser()"
                                                    (onEditMode)="enableEditMode()"
                                                    [enableReplyButton]="_message?.enableReply"
                                                    (onReply)="toggleComment()"
                                                    (onDelete)="deleteComment()"></dvtx-message-panel-action-dropdown>
              </ng-template>
            </div>
          </div>
        </li>
      </ul>

      <!-- Message body -->
      <ul class="box-body" *ngIf="_message?.details || _message?.message">
        <!-- Reply to reference header -->
        <li class="fivef-message-panel--section" *ngIf="_message?.replyTo">
          <div class="fivef-message-panel--reply-to--headline">
            <em>{{ _message?.replyToAuthor }} {{ 'GENERAL.WROTE_AT' | translate }} {{ _message?.replyToDate | date:'medium' }}</em>
          </div>
          <div class="fivef-message-panel--reply-to" [froalaView]="_message?.replyTo"></div>
        </li>

        <!-- Artifact performer reference header -->
        <li class="fivef-message-panel--section ff-pb-zero" *ngIf="_message.type === ProcessEventType.ArtifactComment">
          <ng-container [ngSwitch]="lang">
            <ng-container *ngSwitchCase="'de'">
              <div class="fivef-message-panel--reply-to--headline">
                <em><strong>{{ avatar$?.name || avatar$?.email }}</strong> hat einen Kommentar zu Dokument
                  <strong [class.fivef-message-panel--active-artifact-link]="process"
                          (click)="openPreviewDialog()">{{ _message?.title }}</strong> hinzugefügt:
                </em>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'en'">
              <div class="fivef-message-panel--reply-to--headline">
                <em><strong>{{ avatar$?.name || avatar$?.email }}</strong> has added a comment to document
                  <strong [class.fivef-message-panel--active-artifact-link]="process"
                          (click)="openPreviewDialog()">{{ _message?.title }}</strong>:
                </em>
              </div>
            </ng-container>
          </ng-container>
        </li>

        <!-- Message body -->
        <li class="fivef-message-panel--section">
            <span class="fivef-message-panel--seen-by-overlay"
                  fivefMarkRead
                  [markReadMessage]="_message"
                  [markReadMessageFrom]="'process'"
                  (onMarkedRead)="updateComment(_message, $event)"></span>
          <ng-template [ngIf]="editMode" [ngIfElse]="viewMode">
            <!-- Edit comment editor -->
            <fivef-message-editor [showCancelAction]="true"
                                  [slimToolbar]="false"
                                  [saveIcon]="'save'"
                                  [message]="_message?.details || _message?.message"
                                  (onSave)="editComment($event)"
                                  (onCancel)="cancelEdit()"></fivef-message-editor>
          </ng-template>
          <ng-template #viewMode>
            <div [froalaView]="_message?.details || _message?.message"></div>
          </ng-template>
        </li>

        <!-- Seen by & reaction bar -->
        <li class="fivef-message-panel--footer"
            *ngIf="!readonly && !editMode">
          <div class="fivef-message-panel--statistics">
            <fivef-reactions (onReaction)="react($event)"
                             [reactions]="reactions"></fivef-reactions>
            &nbsp;|&nbsp;
            <span class="d-flex align-items-center" [matTooltip]="'PROJECT_ROOM.SEEN_BY' | translate"
                  [matTooltipPosition]="'above'">
                <ng-container>
                  <dvtx-message-panel-info-dropdown [icon]="'visibility'" [showTitle]="true"
                                                    [title]="!(seenBy$ | async) ? '0' : '(' + (seenBy$ | async)?.length + ')'"
                                                    [persons]='seenBy$ | async' [showAvatarTitle]='true'
                                                    [class.active]="(seenBy$ | async)?.length > 0">
                  </dvtx-message-panel-info-dropdown>
                </ng-container>
              </span>
          </div>
        </li>

        <!-- Reply comment editor -->
        <li *ngIf="showInputComment" class="message-comment-area ff-px-m">
          <fivef-message-editor [showCancelAction]="true"
                                [slimToolbar]="false"
                                [message]="comment"
                                [processId]="_message?.processId"
                                [id]="contentCacheContextId"
                                (onSave)="sendMessage($event)"
                                (onCancel)="toggleComment()"></fivef-message-editor>
        </li>
      </ul>
    </div>
  </div>
</div>
