<ng-template #dialogTpl>
  <fivef-dialog [title]="'PROJECT_ROOM.EXPORT_COMMENTS'" [isCallToAction]="true">
    <div class="mb-2 dvtx-comment-export-dialog">

      <ng-container *ngIf="commentsLoading">
        <div class="w-100 my-3 d-flex justify-content-center">
          <fivef-loading-indicator [message]="'PROJECT_ROOM.LOADING_COMMENTS'"></fivef-loading-indicator>
        </div>
      </ng-container>

      <fivef-icon-message-block *ngIf="!commentsLoading && (comments$ | async)?.length === 0"
                                [icon]="'chat_bubble_outline'"
                                [message]="'PROJECT_ROOM.NO_COMMENTS'"></fivef-icon-message-block>

      <div class="mb-2 dvtx-comment-export-dialog__select-all-container"
           *ngIf="!commentsLoading && (comments$ | async)?.length">
        <fivef-alert [text]="'PROJECT_ROOM.EXPORT_COMMENT_INFO'" [icon]="'info'"
                     [color]="'info'"></fivef-alert>
        <div class="mt-2">
          <mat-checkbox [checked]="selectAllComments" (change)="toggleAllComments($event)">
            {{ 'GENERAL.SELECT_ALL' | translate }}
          </mat-checkbox>
        </div>
        <hr>
      </div>

      <ul class="timeline-panel" *ngIf="id">
        <li class="list-item user-item" *ngFor="let event of (comments$ | async)">
          <div *ngIf="showDate(event.id) | async" class="grouping--date--headline">
            <hr class="first-line">
            <span>{{ event.createdAt | date: 'dd.MM.yyyy' }}</span>
            <hr>
          </div>
          <ng-template [ngIf]="!!id && id !== event.processId && !currentProcessArtifact">
            <ng-container *ngIf="(getProcessTitleById(event.processId) | async) as title">
              <div style="width: 100%; padding: 0 auto; margin-left: 4rem;" *ngIf="title">
                <span class="dvtx-label">{{ 'PROJECT_ROOM.TITLE' | translate }}:</span>&nbsp;&nbsp;<a
                class="dvtx-panel-resource-link">{{ title }}</a>
              </div>
            </ng-container>
          </ng-template>

          <ng-container [ngSwitch]="event.type">
            <div *ngSwitchCase="ProcessEventType.InstantMessage">
              <div class="d-flex">
                <div style="width: 40px">
                  <mat-checkbox [checked]="selectedComments[event.id]"
                                (change)="selectComment($event, event)"></mat-checkbox>
                </div>
                <fivef-message-panel [process]="process$ | async" [message]="event" [readonly]="true"
                                    class="w-100"></fivef-message-panel>
              </div>
            </div>

            <div *ngSwitchCase="ProcessEventType.Comment">
              <div class="d-flex">
                <div style="width: 40px">
                  <mat-checkbox [checked]="selectedComments[event.id]"
                                (change)="selectComment($event, event)"></mat-checkbox>
                </div>
                <fivef-message-panel [message]="event" [readonly]="true" class="w-100"></fivef-message-panel>
              </div>
            </div>

            <div *ngSwitchCase="ProcessEventType.ExternalMessage">
              <div class="d-flex">
                <div style="width: 40px">
                  <mat-checkbox [checked]="selectedComments[event.id]"
                                (change)="selectComment($event, event)"></mat-checkbox>
                </div>
                <fivef-message-panel [message]="event" [readonly]="true" class="w-100"></fivef-message-panel>
              </div>
            </div>
          </ng-container>
        </li>
      </ul>
    </div>

    <div fivef-dialog-footer>
      <button mat-button (click)="closeCommentDialog()">
        <mat-icon>close</mat-icon>
        {{ 'GENERAL.CANCEL_ACTION' | translate }}
      </button>
      <button mat-raised-button color="primary" (click)="generateCommentExport()"
              [disabled]="creatingCommentExport || commentsLoading || !(comments$ | async)?.length">
        <mat-icon>file_download</mat-icon>
        {{ 'GENERAL.CREATE_ACTION' | translate }}
      </button>
    </div>
  </fivef-dialog>
</ng-template>
