import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  DocumentPreviewDocumentActionTypes,
  RequestDocuments,
  RequestDocumentsFail,
  RequestDocumentsSuccess
} from './document-preview-document.actions';
import {DocumentPreviewDocument} from './document-preview-document';
import {DocumentPreviewDocumentService} from './document-preview-document.service';

@Injectable()
export class DocumentPreviewDocumentEffects {
  requestDocuments$ = createEffect(() => this.actions.pipe(
    ofType(DocumentPreviewDocumentActionTypes.RequestDocuments),
    switchMap((action: RequestDocuments) => {
      return this.svc.requestPreviews(action.ids)
        .pipe(
          first(),
          concatMap((res: DocumentPreviewDocument[]) => {
            return [new RequestDocumentsSuccess(res)];
          }),
          catchError(err => {
            console.error(err);
            return of(new RequestDocumentsFail(err));
          }));
    })
  ));

  constructor(private actions: Actions,
              private svc: DocumentPreviewDocumentService) {
  }
}
