import {CommonModule} from '@angular/common'
import {NgModule} from '@angular/core'
import {EffectsModule} from '@ngrx/effects'
import {StoreModule} from '@ngrx/store'
import {StoreDevtoolsModule} from '@ngrx/store-devtools'
import {environment} from 'environments/environment';
import * as reducer from '../app.state';
import {ProcessContextEffects} from './_legacy/effects/process-context.effects';
import {UserEffects} from './_legacy/effects/user-effects';
import {CurrentUserEffects} from './_legacy/effects/current-user-effects';
import {FoxdoxStatusEffects} from 'app/+store/_legacy/effects/foxdox-status.effects';
import {IamProcessActivityStoreModule} from './iam-process-activity';
import {FeatureStoreModule} from './feature/feature-store.module';
import {MessageService} from 'app/+store/message/message.service';
import {MessageSettingsService} from 'app/+store/message-settings/message-settings.service';
import {MessageSettingsStoreModule} from 'app/+store/message-settings/message-settings-store.module';
import {OrganizationLogoStoreModule} from './organization-logo';
import {VerifiedUserStoreModule} from 'app/+store/verified-user';
import {VerifiedUserService} from 'app/+store/verified-user/verified-user.service';
import {InvitationService} from './invitation/invitation.service';
import {AuditThirdPartyConfirmationStoreModule} from './audit-third-party-confirmation/audit-third-party-confirmation-store.module';
import {CommentStoreModule} from './comment';
import {CollectorConfirmationStoreModule} from './collector-confirmation/collector-confirmation-store.module';
import {CollectorItemStoreModule} from './collector-item/collector-item-store.module';
import {CollectorCategoryStoreModule} from './collector-category/collector-category-store.module';
import {CollectorAttachmentStoreModule} from './collector-attachment';
import {MembershipStoreModule} from './membership/membership-store.module';
import {LicenceAssignmentStoreModule} from './licence-assignment/licence-assignment-store.module';
import {InvitationStoreModule} from './invitation/invitation-store.module';
import {OrganizationStoreModule} from './organization/organization-store.module';
import {NaturalPersonStoreModule} from './natural-person/natural-person-store.module';
import {ProjectRoomStoreModule} from './project-room/project-room-store.module';
import {NotificationStoreModule} from './notification/notification-store.module';
import {ContactsOrganizationStoreModule} from './contact-organization/contacts-organization-store.module';
import {DocumentTemplateStoreModule} from './document-template/document-template-store.module';
import {FavoriteStoreModule} from './favorite/favorite-store.module';
import {LicenceStoreModule} from './licence/licence-store.module';
import {ContactPersonOrganizationAssociationStoreModule} from './contact-person-organization-association/contact-person-organization-association-store.module';
import {EmailFooterStoreModule} from './email-footer/email-footer-store.module';
import {TaskStoreModule} from './task/task-store.module';
import {ContactsPersonStoreModule} from './contact-person/contacts-person-store.module';
import {ContactsStoreModule} from './contact/contacts-store.module';
import {RoleStoreModule} from './role/role-store.module';
import {MessageStoreModule} from './message/message-store.module';
import {MilestoneStoreModule} from './milestone/milestone-store.module';
import {TaskAssigneeStoreModule} from './task-assignee/task-assignee-store.module';
import {TaskEventStoreModule} from './task-event/task-event-store.module';
import {DmsDocumentStoreModule} from './dms-document/dms-document-store.module';
import {PageSidebarStoreModule} from './page-sidebar/page-sidebar-store.module';
import {DmsFolderStoreModule} from './dms-folder/dms-folder-store.module';
import {CollectorTemplateStoreModule} from './collector-template/collector-template-store.module';
import {ProcessStoreModule} from './process/process-store.module';
import {ProcessArtifactStoreModule} from './process-artifact/process-artifact-store.module';
import {ProcessActionStoreModule} from './process-action/process-action-store.module';
import {ProcessRoleStoreModule} from './process-role/process-role-store.module';
import {AuditContracteeStoreModule} from './audit-contractee/audit-contractee-store.module';
import {CollectorStoreModule} from './collector/collector-store.module';
import {ProcessEventStoreModule} from './process-event/process-event-store.module';
import {ProcessParticipantStoreModule} from './process-participant/process-participant-store.module';
import {ProcessTreeStoreModule} from './process-tree/process-tree-store.module';
import {UserSignatureStoreModule} from './user-signature';
import {QuickshareStoreModule} from './quickshare';
import {AuthPasswordStoreModule} from './auth-password';
import {QuickshareAttachmentStoreModule} from './quickshare-attachment';
import {QuickshareRecipientStoreModule} from './quickshare-recipient';
import {AuditOrderStoreModule} from './audit-order/audit-order-store.module';
import {DmsStatusStoreModule} from './dms-status/dms-status-store.module';
import {DmsFolderAclStoreModule} from './dms-folder-acl/dms-folder-acl-store.module';
import {DmsContactStoreModule} from './dms-contact/dms-contact-store.module';
import {FoxdoxAccountStoreModule} from './foxdox-account/foxdox-account-store.module';
import {DavContactStoreModule} from './dav-contact/dav-contact-store.module';
import {SalesStoreModule} from './sales/sales-store.module';
import {WorkflowEngineService} from 'app/+store/_legacy/api/services/workflow-engine.service';
import {ProjectStoreModule} from './project';
import {ProcessAttributeStoreModule} from './process-attribute/process-attribute-store.module';
import {ProcessFolderStoreModule} from './process-folder/process-folder-store.module';
import {AuditTrailStoreModule} from './audit-trail/audit-trail-store.module';
import {UserAvatarStoreModule} from './user-avatar';
import {ProcessParticipantAclStoreModule} from './process-participant-acl/process-participant-acl-store.module';
import {ClientStoreModule} from './client/client-store.module';
import {TaskStatusStoreModule} from './task-status/task-status-store.module';
import {DmsDocumentExportStoreModule} from './dms-document-export/dms-document-export-store.module';
import {TaskStatusSchemeStoreModule} from './task-status-scheme/task-status-scheme-store.module';
import {TaskResourceStoreModule} from './task-resource/task-resource.module';
import {TasksStatsStoreModule} from './tasks-stats/tasks-stats-store.module';
import {ProjectStatisticsStoreModule} from './project-statistics/project-statistics-store.module';
import {CacStoreModule} from './cac/cac-store.module';
import {CacEntityStoreModule} from './cac-entity/cac-entity-store.module';
import {MotdStoreModule} from './motd/motd-store.module';
import {CmsBlogStoreModule} from './cms-blog/cms-blog-store.module';
import {CmsArticleStoreModule} from './cms-article/cms-article-store.module';
import {OperatorOrganizationStoreModule} from './operator/organization/organization-store.module';
import {OperatorMemberStoreModule} from './operator/member/member-store.module';
import {OperatorSmsRecordStoreModule} from './operator/sms-record/sms-record-store.module';
import {ExternalAccessStoreModule} from './external-access/external-access-store.module';
import {ResumableUploadStoreModule} from './resumable-upload/resumable-upload-store.module'
import {OperatorUserStoreModule} from './operator/user/user-store.module';
import {PartnerLinkStoreModule} from './partner-link/partner-link-store.module';
import {PartnerLinkParticipationStore} from './partner-link-participation/partner-link-participation-store.module';
import {CollectorExportStoreModule} from './collector-export/collector-export-store.module';
import {ProcessManagementStoreModule} from './process-management/process-management-store.module';
import {TwoFactorSessionStoreModule} from './two-factor-session/two-factor-session-store.module';
import {LabelStoreModule} from './label';
import {ItemLabelsStoreModule} from './item-labels';
import {DocumentPreviewStoreModule} from './document-preview/document-preview-store.module';
import {DocumentPreviewPreviewStoreModule} from './document-preview-preview/document-preview-preview-store.module';
import {DocumentPreviewDocumentStoreModule} from './document-preview-document/document-preview-document-store.module';
import {DocumentSignatureStoreModule} from './document-signature/document-signature-store.module';
import {WorkflowTemplateStoreModule} from './workflow-template/workflow-template-store.module';
import {WorkflowTemplateCategoryStoreModule} from './workflow-template-category/workflow-template-category-store.module';
import {OperatorSystemHealthStoreModule} from './operator/system-health/system-health-store.module';
import {FastdocsStoreModule} from './fastdocs/fastdocs-store.module';
import {ExcelExportStoreModule} from './excel-export/excel-export-store.module';
import {FibuStoreModule} from './fibu/fibu-store.module';
import {FibuBookmanAccountStoreModule} from './fibu-bookman-account/fibu-bookman-account-store.module';
import {OperatorTenantStoreModule} from './operator/tenant/tenant-store.module';
import {BookmanClientStoreModule} from './bookman-client';
import {BookmanServiceAccountStoreModule} from './bookman-service-account';
import {FibuProcessStoreModule} from './fibu-process';
import {KanbanBoardStoreModule} from './kanban-store/kanban-board';
import {KanbanGroupStoreModule} from './kanban-store/kanban-group';
import {KanbanItemStoreModule} from './kanban-store/kanban-item';
import {SlimFolderStoreModule} from './slim-folder/slim-folder-store.module';
import {FavoriteProcessStoreModule} from './favorite-process/favorite-process-store.module';
import {CmsFaqStoreModule} from './cms-faq/cms-faq-store.module';
import {ItemStoreModule} from './label/item/item-store.module';
import {EmailToNameModule} from './email-to-name/email-to-name.module'
import {ProcessLookupStoreModule} from './process-lookup/process-lookup-store.module';
import {DatevClientStoreModule} from './datev/datev-client/datev-client-store.module';
import {TenantTenantStoreModule} from './tenant/tenant/tenant-store.module';
import {TenantUserStoreModule} from './tenant/user/user-store.module';
import {TenantOrganizationStoreModule} from './tenant/organization/organization-store.module';
import {TenantMemberStoreModule} from './tenant/member/member-store.module';
import {CollectorItemLookupStoreModule} from './collector-item-lookup/collector-item-lookup-store.module';
import {ArtifactKeywordStoreModule} from './artifact-keyword/artifact-keyword-store.module';
import {GrantThorntonStoreModule} from './customer/grant-thornton/grant-thornton-store.module';
import {CmsSlideshowStoreModule} from './cms-slideshow/cms-slideshow-store.module';
import {FileInboxStoreModule} from './file-inbox/file-inbox-store.module';

const appEffectsRun = [
  CurrentUserEffects,
  UserEffects,
  FoxdoxStatusEffects,
  ProcessContextEffects
];

@NgModule({
  imports: [
    CommonModule,
    FeatureStoreModule,
    MembershipStoreModule,
    OrganizationStoreModule,
    NaturalPersonStoreModule,
    TwoFactorSessionStoreModule,
    NotificationStoreModule,
    RoleStoreModule,
    ContactsStoreModule,
    ContactsOrganizationStoreModule,
    ContactsPersonStoreModule,
    ContactPersonOrganizationAssociationStoreModule,
    DocumentTemplateStoreModule,
    TaskStoreModule,
    EmailFooterStoreModule,
    UserSignatureStoreModule,
    UserAvatarStoreModule,
    FavoriteStoreModule,
    AuthPasswordStoreModule,
    IamProcessActivityStoreModule,
    LicenceStoreModule,
    LicenceAssignmentStoreModule,
    OrganizationLogoStoreModule,
    VerifiedUserStoreModule,
    CommentStoreModule,
    MessageStoreModule,
    MessageSettingsStoreModule,
    MilestoneStoreModule,
    InvitationStoreModule,
    TaskAssigneeStoreModule,
    TaskEventStoreModule,
    DmsDocumentStoreModule,
    DmsFolderStoreModule,
    PageSidebarStoreModule,
    ProcessStoreModule,
    ProcessActionStoreModule,
    ProcessArtifactStoreModule,
    ProcessEventStoreModule,
    ProcessParticipantStoreModule,
    ProcessRoleStoreModule,
    ProcessFolderStoreModule,
    ProjectRoomStoreModule,
    ProcessTreeStoreModule,
    CollectorStoreModule,
    CollectorAttachmentStoreModule,
    CollectorConfirmationStoreModule,
    CollectorItemStoreModule,
    CollectorItemLookupStoreModule,
    CollectorCategoryStoreModule,
    CollectorTemplateStoreModule,
    QuickshareStoreModule,
    QuickshareAttachmentStoreModule,
    QuickshareRecipientStoreModule,
    DmsStatusStoreModule,
    DmsFolderAclStoreModule,
    DmsContactStoreModule,
    FoxdoxAccountStoreModule,
    DavContactStoreModule,
    SalesStoreModule,
    ProjectStoreModule,
    ProcessAttributeStoreModule,
    AuditContracteeStoreModule,
    AuditOrderStoreModule,
    AuditThirdPartyConfirmationStoreModule,
    AuditTrailStoreModule,
    ProcessParticipantAclStoreModule,
    ClientStoreModule,
    TaskStatusStoreModule,
    DmsDocumentExportStoreModule,
    TaskStatusSchemeStoreModule,
    TaskResourceStoreModule,
    TasksStatsStoreModule,
    ProjectStatisticsStoreModule,
    CacStoreModule,
    CacEntityStoreModule,
    MotdStoreModule,
    CmsBlogStoreModule,
    CmsArticleStoreModule,
    CmsFaqStoreModule,
    PartnerLinkStoreModule,
    PartnerLinkParticipationStore,
    OperatorOrganizationStoreModule,
    OperatorMemberStoreModule,
    OperatorUserStoreModule,
    OperatorSmsRecordStoreModule,
    OperatorTenantStoreModule,
    ExternalAccessStoreModule,
    ResumableUploadStoreModule,
    ProcessManagementStoreModule,
    CollectorExportStoreModule,
    ExcelExportStoreModule,
    LabelStoreModule,
    ItemLabelsStoreModule,
    DocumentPreviewStoreModule,
    DocumentPreviewPreviewStoreModule,
    DocumentPreviewDocumentStoreModule,
    DocumentSignatureStoreModule,
    WorkflowTemplateStoreModule,
    WorkflowTemplateCategoryStoreModule,
    OperatorSystemHealthStoreModule,
    FastdocsStoreModule,
    FibuStoreModule,
    FibuBookmanAccountStoreModule,
    BookmanClientStoreModule,
    DatevClientStoreModule,
    BookmanServiceAccountStoreModule,
    FibuProcessStoreModule,
    KanbanBoardStoreModule,
    KanbanGroupStoreModule,
    EmailToNameModule,
    KanbanItemStoreModule,
    SlimFolderStoreModule,
    FavoriteProcessStoreModule,
    ItemStoreModule,
    ProcessLookupStoreModule,
    TenantUserStoreModule,
    TenantOrganizationStoreModule,
    TenantMemberStoreModule,
    TenantTenantStoreModule,
    ArtifactKeywordStoreModule,
    GrantThorntonStoreModule,
    CmsSlideshowStoreModule,
    FileInboxStoreModule,
    StoreModule.forRoot(reducer.reducers, {
      metaReducers: reducer.metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictStateSerializability: false,
        strictActionSerializability: false,
      },
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production
    }),
    EffectsModule.forRoot(appEffectsRun),
  ],
  declarations: [],
  providers: [
    InvitationService,
    VerifiedUserService,
    MessageService,
    MessageSettingsService,
    WorkflowEngineService
  ]
})
export class RootStoreModule {
}
