import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ProcessTreeNode} from 'app/+store/process-tree/process-tree';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.state';
import {concatMap, distinctUntilChanged, map, takeUntil} from 'rxjs/operators';
import {ProcessArtifactSelectors} from 'app/+store/process-artifact';
import {Process} from 'app/+store/process/process';
import {IFivefResourceTreeNode} from '../../tree/fivef-tree/fivef-tree.interface';
import {Subject} from 'rxjs/internal/Subject';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {of} from 'rxjs/internal/observable/of';
import {CommonModule} from '@angular/common';
import {FivefSearchComponent} from '../../input/fivef-search/fivef-search.component';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {FivefTreeComponent} from '../../tree/fivef-tree/fivef-tree.component';
import {FivefProcessTreeNodeComponent} from '../../process/fivef-process-tree-node/fivef-process-tree-node.component';
import {FivefTreeNodeDevDirective} from '../../tree/fivef-tree/fivef-tree-node-dev.directive';

@Component({
  selector: 'fivef-process-artifact-tree',
  standalone: true,
  imports: [CommonModule, FivefSearchComponent, TranslateModule, FivefTreeComponent, FivefProcessTreeNodeComponent, FivefTreeNodeDevDirective],
  templateUrl: './fivef-process-artifact-tree.component.html',
  styleUrls: ['./fivef-process-artifact-tree.component.scss']
})
export class FivefProcessArtifactTreeComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject<void>();

  protected _id: string;
  public nodes$ = new BehaviorSubject<IFivefResourceTreeNode[]>([]);

  process$ = new BehaviorSubject<Process>(null);
  _process;

  public searchTerm: string = null;

  @Output()
  onSelect = new EventEmitter<{ id: string }>();

  @Input() set process(process: Process) {
    if (process) {
      this._process = process;
      this.process$.next(process);
    }
  }

  constructor(private store: Store<AppState>,
              private translateSvc: TranslateService,
              private cdr: ChangeDetectorRef) {
    const rootName = translateSvc.instant('PROJECT_ROOM.ALL_DOCUMENTS');
    this.process$
      .pipe(
        distinctUntilChanged(),
        concatMap((process) => {
          if (process) {
            switch (process.processType) {
              case 'quickcollector':
                return this.store.select(ProcessArtifactSelectors.groupedDocumentsForCollecto(process.id))
                  .pipe(map(nodes => {
                    return nodes.map(node => {
                      if (node.title === 'COLLECTOR.UNSORTED_UPLOADS') {
                        node.title = this.translateSvc.instant('COLLECTOR.UNSORTED_UPLOADS');
                      }
                      return node;
                    })
                  }))
              case 'project':
              case 'quickstart':
              case 'cac':
              case 'cac_request':
              case 'quickshare_v2':
                return this.store.select(ProcessArtifactSelectors.groupedDocumentsBySubProject(process.id));
              default:
                return this.store.select(ProcessArtifactSelectors.groupedDocumentsBySubProject(process.id));
            }
          } else {
            return of([])
          }
        }),
        takeUntil(this.onDestroy)
      )
      .subscribe(nodes => {
        this.nodes$.next(ProcessTreeNode.buildGroupedDocumentTreeFrom(nodes, rootName));
      });
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.nodes$.complete();
    this.process$.complete();
  }

  public selectNode(node: { id: string }) {
    if (!node) return;
    this.onSelect.emit(node);
  }

  /**
   * Sets the searchTerm to be used as input of the search.
   *
   * @param $event
   */
  public applySearch($event: string) {
    this.searchTerm = $event;
    this.cdr.detectChanges();
  }
}
