import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {FileItem, FileUploader, FileUploadModule} from 'ng2-file-upload';
import {FileUtils} from 'app/lib/file_utils/functions';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.state';
import {AngularTokenService} from 'angular-token';
import {TranslateModule} from '@ngx-translate/core';
import {FivefNotificationService} from 'app/lib/fivef-ui/notification/fivef-notification/fivef-notification.service';
import {UploadUtils} from 'app/lib/upload_utils';
import {ProcessSelectors} from 'app/+store';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {distinctUntilChanged, filter, switchMap, takeUntil} from 'rxjs/operators';
import {Process, UploadType} from '../../../../+store/process/process';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {of} from 'rxjs/internal/observable/of';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {MatButtonModule} from '@angular/material/button';
import {FivefUploadService} from './fivef-upload.service';
import {DocumentType} from '../../../../+store/process-artifact/process-artifact';

export enum ProcessArtifactUpdateMode {
  None = 'none',
  ProcessOnly = 'process_only',
  Recursive = 'recursive',
}

@Component({
  selector: 'fivef-upload',
  standalone: true,
  host: {class: 'fivef-upload'},
  imports: [CommonModule, FileUploadModule, MatIconModule, TranslateModule, MatTooltipModule, MatProgressBarModule, MatProgressSpinnerModule, MatButtonModule],
  providers: [FivefUploadService],
  templateUrl: './fivef-upload.component.html',
  styleUrls: ['./fivef-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefUploadComponent implements OnInit, OnDestroy {
  private onDestroy: Subject<void> = new Subject<void>();

  public showUploadField = true;

  /*
   * fileTransferEnded = new Subject();
   * transferOngoing = false;
   *
   * selectCallback() {
   *   return this.onSelectCallback.bind(this);
   * }
   * onSelectCallback = (data: any) => {
   *   const dialogRef = this._dialog.open(FileTransferOngoingDialogComponent,
   *     {
   *       height: '200',
   *       width: '400'
   *     });
   *   this._store.select(ProcessArtifactSelectors.transferOngoing)
   *     .pipe(takeUntil(this.fileTransferEnded))
   *     .subscribe(transferOngoing => {
   *       if (this.transferOngoing && transferOngoing === false) {
   *         dialogRef.close();
   *         this.transferOngoing = false;
   *         this.fileTransferEnded.next();
   *       }
   *     });
   *   this._store.dispatch(new ProcessArtifactActions.PickFileFromDms(this.process.id, null, data.id, data.accountType));
   *   this.transferOngoing = true;
   * };
   *
   * ngOndestroy
   * this.fileTransferEnded.next();
   * this.fileTransferEnded.complete();
   */

  private processId$ = new BehaviorSubject<string>(null);
  public _processId: string;

  public uploader: FileUploader;

  @Input()
  public appearance: 'widget' | 'cell' = 'widget';

  public uploadInfoTooltip = true;
  public uploadInformation = 'UPLOAD.PLACEHOLDER_UPLOAD_INFORMATION_2';
  public enableDmsFilePicker = true;

  @Input()
  supportedFileTypes: string[] = FileUtils.DEFAULT_SUPPORTED_FILE_TYPES;

  @Input()
  maxFileSize: number = FileUtils.DEFAULT_MAX_FILE_SIZE;

  public hasBaseDropZoneOver = false;
  isUploaded = false;

  @Input()
  color = 'gray';

  @Input()
  parentId: string = null;

  @Input()
  organizationId: string;

  @Input()
  resourceId: string = null;

  @Input()
  role = 'default';

  @Input()
  canUpload = false;

  @Input()
  locked = false;

  @Input()
  title = 'PROJECT_ROOM.UPLAOD_DOCUMENT';

  /**
   * Enables the title in cell mode. Default: disabled.
   */
  @Input()
  enableCellTitle = false;

  @Input()
  uploadType: UploadType = 'project_room';

  @Input()
  documentType: DocumentType = DocumentType.Document;

  @Input()
  isDraft = false;

  /**
   * Upload by token.
   */
  @Input()
  token: string = null;

  @Input()
  processArtifactUpdateMode: ProcessArtifactUpdateMode = ProcessArtifactUpdateMode.Recursive;

  /**
   * Unique label identifier if the upload component is used multiple
   * times on one view.
   */
  @Input()
  labelId = '';

  @Output()
  onCompleteItem = new EventEmitter();

  @Output()
  onCompleteAll = new EventEmitter();

  public progressUpload$ = new BehaviorSubject(0);

  @Input()
  set processId(pid: string) {
    this._processId = pid;
    this.processId$.next(pid);
  }

  constructor(private cdr: ChangeDetectorRef,
              private store: Store<AppState>,
              private tokenSvc: AngularTokenService,
              private notifyService: FivefNotificationService,
              private uploadSvc: FivefUploadService) {
  }

  ngOnInit(): void {
    // Special handling of external large file view: No process present.
    if (this.uploadType === 'large_file') {
      const uploadConfiguration = Process.uploadConfigurationFor(null, this.uploadType, null, null)
      if (uploadConfiguration) {
        this.supportedFileTypes = uploadConfiguration.supportedFileTypes;
        this.maxFileSize = uploadConfiguration.maxFileSize;
        this.uploadInformation = uploadConfiguration.uploadInformation;
        this.uploadInfoTooltip = uploadConfiguration.uploadInfoTooltip;
        this.enableDmsFilePicker = uploadConfiguration.enableDmsFilePicker
      }
    }

    this.initUploadWorker();

    const process$ = this.processId$
      .pipe(filter(pid => !!pid), distinctUntilChanged(), switchMap(pid => this.store.select(ProcessSelectors.getProcessById(pid))));

    combineLatest([process$, of(this.role)])
      .pipe(takeUntil(this.onDestroy))
      .subscribe(([process, role]) => {
        const uploadConfiguration = Process.uploadConfigurationFor(process, this.uploadType, role, this.documentType)
        if (uploadConfiguration) {
          this.supportedFileTypes = uploadConfiguration.supportedFileTypes;
          this.maxFileSize = uploadConfiguration.maxFileSize;
          this.uploadInformation = uploadConfiguration.uploadInformation;
          this.uploadInfoTooltip = uploadConfiguration.uploadInfoTooltip;
          this.enableDmsFilePicker = uploadConfiguration.enableDmsFilePicker;
          this.cdr.detectChanges();
        }
      });
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.processId$.complete();
    this.progressUpload$.complete();
  }

  addFile(fileContext) {
    if (this._validateFile(fileContext)) {
      return;
    }
    this.isUploaded = true;
    return fileContext;
  }

  fileOverDropzone($event: any): void {
    this.hasBaseDropZoneOver = $event;
    this.cdr.detectChanges();
  }

  private initUploadWorker() {
    const options = {
      url: this.url,
      autoUpload: true,
      itemAlias: 'attachment'
    }

    if (this.uploadType !== 'accessToken' && this.tokenSvc.currentAuthData) {
      options['headers'] = UploadUtils.authHeaders(this.tokenSvc.currentAuthData, this.organizationId);
    }

    this.uploader = new FileUploader(options);

    this.uploader.onBeforeUploadItem = (item => {
      item.url = this.url;
    });

    this.uploader.onAfterAddingFile = (fileContext) => {
      fileContext.withCredentials = false;
      if (!this.addFile(fileContext)) {
        fileContext.remove();
      }
    };

    this.uploader.onBuildItemForm = (item, form) => {
      item.url = this.url;
      form.append('title', item.file.name);
      form.append('display_name', item.file.name);
      form.append('role', this.role);
      form.append('attachment_process_id', this._processId);
      if (this.resourceId) {
        form.append('reference_id', this.resourceId);
      }
    };

    // this.uploader.onCancelItem = (item: FileItem, response: string, status: number, headers: any) => {
    //   const index = this.uploader.queue.indexOf(item);
    //   this.removeFile(index);
    // };

    this.uploader.onSuccessItem = (item: FileItem, response: any, status: number, headers: any) => {
      const index = this.uploader.queue.indexOf(item);
      this.uploader.removeFromQueue(item);
    };

    this.uploader.onProgressItem = (item, progress) => {
      this.progressUpload$.next(progress);
    };

    this.uploader.onErrorItem = (item: FileItem, response: string, status: number, headers: any) => {
      if (status === 422 || status === 400) {
        this.notifyService.error('UPLOAD.UPLOAD_FAILURE_SERVER_ISSUE')
      } else {
        this.notifyService.error('UPLOAD.UPLOAD_FAILURE_FILE_ISSUE')
      }
    };

    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      if (status === 200) {
        // Dashboard case e.g. 3rd Party: Update parent.

        const obj = this.uploadSvc.handleComplete(this._processId, this.parentId, this.isDraft, this.processArtifactUpdateMode, this.uploadType, response);
        this.onCompleteItem.emit(obj)

      } else if (status === 422 || status === 400) {
        this.notifyService.error('UPLOAD.UPLOAD_FAILURE_SERVER_ISSUE')
      } else {
        this.notifyService.error('UPLOAD.UPLOAD_FAILURE_FILE_ISSUE')
      }
    };

    this.uploader.onCompleteAll = () => {
      if (this.uploader.queue.length === 0) {
        this.onCompleteAll.emit();
      }
      this.cdr.detectChanges();
    };

    this.showUploadField = this.validUploadSetup();
    this.cdr.detectChanges();
  }

  private validUploadSetup(): boolean {
    return (!!this._processId ||
        (this.uploadType === 'accessToken' || this.uploadType === 'large_file' || this.uploadType === 'third_party') && !!this.token) &&
      !!this.url &&
      !!this.uploader;
  }

  private _validateFile(fileItem) {
    const validityCheck = UploadUtils.validate(fileItem, this.supportedFileTypes, this.maxFileSize);
    if (validityCheck) {
      this.notifyService.error(validityCheck.reason);
    }
    return validityCheck;
  }

  public getInvalidFiles() {
    const files = this.uploader.queue;
    const invalidFiles = [];
    files.forEach(fileItem => {
      const invalidFile = this._validateFile(fileItem);
      if (invalidFile) {
        invalidFiles.push(invalidFile);
      }
    });
    return invalidFiles;
  }

  /**
   * Getter of the URL to ensure an accurate and up-2-date upload path.
   */
  get url(): string {
    return this.uploadSvc.getUrl(this.uploadType, this.token, this.isDraft, this._processId, this.resourceId, this.role);
  }
}
