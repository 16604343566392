import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FivefCommentComponent} from './fivef-comment.component';
import {FivefMarkReadDirective} from '../../input/fivef-mark-read.directive';
import {FivefAvatarModule} from '../../profile/fivef-avatar/fivef-avatar.module';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TranslateModule} from '@ngx-translate/core';
import {MatMenuModule} from '@angular/material/menu';
import {FroalaEditorModule, FroalaViewModule} from 'angular-froala-wysiwyg';
import {FivefMessageEditorComponent} from '../../input/fivef-message-editor/fivef-message-editor.component';
import {FivefMessagePanelComponent} from './fivef-message-panel/fivef-message-panel.component';
import {FivefExportCommentsComponent} from './fivef-export-comments/fivef-export-comments.component';
import {FivefDialogComponent} from '../../common/fivef-dialog/fivef-dialog.component';
import {FivefLoadingIndicatorComponent} from '../../util/fivef-loading-indicator/fivef-loading-indicator.component';
import {FivefIconMessageBlockComponent} from '../../util/fivef-icon-message-block/fivef-icon-message-block.component';
import {FivefAlertComponent} from '../../util/fivef-alert/fivef-alert.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {FivefCommentsRepository} from './fivef-comments/fivef-comments.repository';
import {FivefCommentsComponent} from './fivef-comments/fivef-comments.component';
import {FivefFetchCollectorItemDirective} from '../../bom/fivef-fetch-collector-item.directive';
import {RouterLink} from '@angular/router';
import {FivefReactionComponent} from './fivef-reaction/fivef-reaction.component';
import {FivefReactionsComponent} from './fivef-reactions/fivef-reactions.component';
import {FivefSeenbyComponent} from './fivef-seenby/fivef-seenby.component';
import {CdkConnectedOverlay, CdkOverlayOrigin} from '@angular/cdk/overlay';
import {FivefMessagePanelActionDropdownComponent} from './fivef-message-panel-action-dropdown/fivef-message-panel-action-dropdown.component';
import {FivefMessagePanelInfoDropdownComponent} from './fivef-message-panel-info-dropdown/fivef-message-panel-info-dropdown.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FivefMenuItemComponent} from '../../navigation/fivef-menu-item/fivef-menu-item.component';


@NgModule({
  declarations: [
    FivefCommentComponent,
    FivefMessagePanelComponent,
    FivefExportCommentsComponent,
    FivefCommentsComponent,
    FivefReactionComponent,
    FivefReactionsComponent,
    FivefSeenbyComponent,
    FivefMessagePanelActionDropdownComponent,
    FivefMessagePanelInfoDropdownComponent
  ],
  imports: [
    CommonModule,
    FivefMarkReadDirective,
    FivefAvatarModule,
    MatTooltipModule,
    TranslateModule,
    MatMenuModule,
    FroalaViewModule,
    FivefMessageEditorComponent,
    FivefDialogComponent,
    FivefLoadingIndicatorComponent,
    FivefIconMessageBlockComponent,
    FivefAlertComponent,
    MatCheckboxModule,
    MatIconModule,
    MatButtonModule,
    FivefFetchCollectorItemDirective,
    RouterLink,
    CdkOverlayOrigin,
    CdkConnectedOverlay,
    FroalaEditorModule,
    FormsModule,
    ReactiveFormsModule,
    FivefMenuItemComponent
  ],
  exports: [
    FivefCommentsComponent,
    FivefMessagePanelComponent
  ],
  providers: [
    FivefCommentsRepository
  ],
})
export class FivefCommentModule {
}
