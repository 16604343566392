import * as modelInterface from './system-health.interface';
import {IResource} from '../../../lib/fivef-net/fivef-api-resource/models/resource.interface';
import {IApiResourceBuilder} from '../../../lib/fivef-net/fivef-api-resource/models/api.interface';

/// <reference path="./system-health.interface.ts" />
export namespace Operator {
  export class SystemHealth implements modelInterface.Operator.ISystemHealth {
    readonly type = 'system_health';


    constructor(public id,
                public cppStatus: boolean,
                public pvmsStatus: boolean,
                public criticalQueueSize: number,
                public defaultQueueSize: number,
                public lowQueueSize: number,
                public dmsStatus: boolean,
                public customerQueueSize: number,
                public dmsUploadQueueSize: number,
                public dmsDeletionQueueSize: number,
                public dmsCleanUpQueueSize: number,
                public dmsSyncQueueSize: number,
                public emailQueueSize: number,
                public pushNotificationsQueueSize: number) {
    }
  }

  /**
   * {
   * 	"data": {
   * 		"attributes": {
   * 			"size": "1.8Ti",
   * 			"used": "1.5Ti",
   * 			"available": "305Gi",
   * 			"capacity": "84%",
   * 			"path": "/System/Volumes/Data"
   * 		}
   * 	}
   * }
   */
  export class StorageInfo implements IResource {
    public readonly type = 'storage_info';

    constructor(public id: string,
                public size: string,
                public used: string,
                public available: string,
                public capacity: string) {
    }
  }

  export class StorageInfoBuilder implements IApiResourceBuilder<StorageInfo> {
    fromResponse(data): StorageInfo {
      return new StorageInfo(
        data.attributes.path,
        data.attributes.size,
        data.attributes.used,
        data.attributes.available,
        data.attributes.capacity);
    }

    toRequest(_: StorageInfo) {
      return null;
    }
  }
}
