import {Component, EventEmitter, forwardRef, Injector, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {NG_VALUE_ACCESSOR, ReactiveFormsModule, UntypedFormControl} from '@angular/forms';
import {combineLatest, Observable, Subject} from 'rxjs';
import {ContactListDto, contactListDtoType} from 'app/+store/contact/contact';
import {filter, map, startWith, switchMap, takeUntil} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.state';
import {ContactSelectors} from 'app/+store/contact';
import {MatAutocompleteModule, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {LoadAll} from 'app/+store/contact/contact.actions';
import {OrganizationSelectors} from 'app/+store/organization';
import {FivefControlValueAccessor} from 'app/lib/fivef-ui/input/fivef-control-value-accessor.directive';
import {AngularTokenService} from 'angular-token';
import {MatDialog} from '@angular/material/dialog';
import {FivefConfirmParticipantDialogComponent} from '../../participation/fivef-confirm-participant-dialog/fivef-confirm-participant-dialog.component';
import {CommonModule} from '@angular/common';
import {MatInputModule} from '@angular/material/input';
import {TranslateModule} from '@ngx-translate/core';
import {CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {FivefAvatarModule} from '../../profile/fivef-avatar/fivef-avatar.module';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';

@Component({
  selector: 'fivef-contact-autocomplete',
  standalone: true,
  imports: [CommonModule, MatInputModule, TranslateModule, ReactiveFormsModule, MatAutocompleteModule, FivefAvatarModule, CdkVirtualForOf, CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll],
  host: {class: 'fivef-contact-autocomplete'},
  templateUrl: './fivef-contact-autocomplete.component.html',
  styleUrls: ['./fivef-contact-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FivefContactAutocompleteComponent),
      multi: true,
    }
  ]
})
export class FivefContactAutocompleteComponent extends FivefControlValueAccessor implements OnInit, OnDestroy {
  private onDestroy = new Subject();

  myControl = new UntypedFormControl();
  public filteredContacts$: Observable<ContactListDto[]>;
  private excludedIds$ = new BehaviorSubject<string[]>([]);

  @Input()
  filterType?: contactListDtoType;

  @Input()
  valueIsEmail: boolean = false;

  @Input()
  showEmail = false;

  @Input()
  excludeTwoFactorWhitelist = false;

  @Input()
  placeholder = 'GENERAL.EMAIL_OR_NAME';

  @Input()
  clientContact: boolean = false;

  @Input()
  loadNew: boolean = true;

  @Output()
  onSelect = new EventEmitter();

  @Input() set value(val: string) {
    this.writeValue(val);
  }

  @Input()
  set excludedIds(excluded: string[]) {
    if (excluded?.length && excluded?.length > 0) {
      const exc = excluded.map(a => a.toLocaleLowerCase());
      this.excludedIds$.next(exc);
    } else {
      this.excludedIds$.next([]);
    }
  }

  constructor(protected injector: Injector,
              private store: Store<AppState>,
              public dialog: MatDialog,
              private _tokenSvc: AngularTokenService) {
    super();
  }

  ngOnInit(): void {
    this.store.select(OrganizationSelectors.getSelected)
      .pipe(
        filter(x => !!x),
        takeUntil(this.onDestroy)
      )
      .subscribe((organization) => {
        if (this.loadNew)
          this.store.dispatch(new LoadAll(organization))
      });

    const query$ = this.myControl.valueChanges.pipe(startWith(''));
    const contacts$ = this.excludedIds$.pipe(switchMap(excludedIds => this.store.select(ContactSelectors.getFilteredPersonContactsOfSelectedOrg(undefined, excludedIds))));
    this.filteredContacts$ =
      combineLatest(query$, contacts$, this.excludedIds$)
        .pipe(
          map(([term, contacts, excludedIds]: [string, ContactListDto[], string[]]) => {
            const filteredContacts = this._filter(term, contacts);
            if (this.clientContact) {
              return filteredContacts.filter(contact => (excludedIds.indexOf(contact.email.toLocaleLowerCase()) === -1)
              );
            } else {
              return filteredContacts.filter(contact => (
                  contact.email !== this._tokenSvc.currentAuthData.uid && excludedIds.indexOf(contact.email.toLocaleLowerCase()) === -1
                )
              );
            }
          })
        );
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.excludedIds$.complete();
  }

  private _filter(term: string, contacts: ContactListDto[]): ContactListDto[] {
    if (typeof term === 'string') {
      const filterValue = term.toLowerCase();
      return contacts.filter((entry: ContactListDto) => {
        return entry.name.toLowerCase().indexOf(filterValue) >= 0
          || entry.email.toLowerCase().indexOf(filterValue) >= 0
          || entry.firstName && entry.firstName.toLowerCase().indexOf(filterValue) >= 0
          || entry.lastName && entry.lastName.toLowerCase().indexOf(filterValue) >= 0
      });
    } else {
      return [];
    }
  }

  writeValue(obj: any): void {
    this.myControl.setValue(obj);
  }

  public selectContact($event: MatAutocompleteSelectedEvent) {
    const email = $event?.option?.value?.email;
    if (!email) return;

    if (this.excludeTwoFactorWhitelist) {
      this.notifyOnChange($event.option.value);
      this.onSelect.emit($event.option.value);

      setTimeout(() => {
        this.writeValue(null);
      }, 1500);
      return;
    }

    if (this.clientContact) {
      this.contactSelected($event, false, false, false, null);
      return;
    }

    this.dialog.open(FivefConfirmParticipantDialogComponent, {
      disableClose: true,
      autoFocus: false,
      data: {
        title: 'GENERAL.ADD_PARTICIPANT',
        message: 'GENERAL.ADD_PARTICIPANT_CONFIRMATION',
        submitButtonTitle: 'GENERAL.CONFIRM_ACTION',
        cancelButtonTitle: 'GENERAL.CANCEL_ACTION',
        email: $event.option.value?.email,
        recursiveOption: true,
        adminOption: true,
        canDelegateOption: false,
        expiresAtOption: true,
        onSubmitAction: (recursive, admin, canDelegate, expiresAt) => {
          this.contactSelected($event, recursive, admin, canDelegate, expiresAt);
        },
        onCancelAction: () => {
          this.writeValue(null);
        }
      }
    });
  }

  contactSelected($event: MatAutocompleteSelectedEvent, recursive, admin, canDelegate, expiresAt) {
    if (this.valueIsEmail) {
      this.notifyOnChange($event.option.value.email);
      this.onSelect.emit({email: $event.option.value.email, admin: admin, recursive: recursive, canDelegate: canDelegate, expiresAt: expiresAt});
    } else {
      this.notifyOnChange($event.option.value);
      this.onSelect.emit({email: $event.option.value, admin: admin, recursive: recursive, canDelegate: canDelegate, expiresAt: expiresAt});
    }
    this.writeValue(null);
  }

  // Used to display the text in the input field.
  displayFn(contact: ContactListDto): string {
    return contact ? contact.name : '';
  }

  onKeyEnter() {
    if (typeof this.myControl.value !== 'string' && !this.valueIsEmail) {
      this.onSelect.emit(this.myControl.value);

    } else if (this.valueIsEmail) {
      const value = (this.myControl.value && this.myControl.value.email) ? this.myControl.value.email : this.myControl.value;
      this.onSelect.emit(value);
    }
    this.writeValue(null);
  }

  onBlurEventHandler() {
    if (typeof this.myControl.value !== 'string' && !this.valueIsEmail) {
      this.notifyOnChange(this.myControl.value);

    } else if (this.valueIsEmail) {
      const value = (this.myControl.value && this.myControl.value.email) ? this.myControl.value.email : this.myControl.value;
      this.notifyOnChange(value);
    }
  }

  triggerFocus($event) {
    this.myControl.setValue(this.myControl.value || '')
  }
}
