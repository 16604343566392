import {Directive, HostListener, Input, Output, EventEmitter} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.state';
import {FeatureSelectors} from 'app/+store/feature';
import {filter, first} from 'rxjs/operators';
import {Feature} from 'app/+store/feature/feature';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Directive({
  selector: '[fivefFeatureAuthorization]',
  standalone: true
})
export class FivefFeatureAuthorizationDirective {
  /**
   * Feature of feature set necessary to grant access.
   * Can be a string (property of feature set or an array
   * for combined modules.
   *
   * Refer to the feature set class for all options.
   */
  @Input()
  fivefFeatureAuthorization: string | string[];

  /**
   * Route to be navigated on success.
   */
  @Input()
  fivefFeatureRoute;

  @Output()
  public onAction = new EventEmitter();

  @HostListener('click', ['$event, $event.target'])
  onClick(_event, _targetElement) {
    this.store.select(FeatureSelectors.getCurrentFeatureSet)
      .pipe(filter(fs => !!fs), first())
      .subscribe((featureSet: Feature) => {
        let authorizedFeature = false;

        if (!!this.fivefFeatureAuthorization && typeof this.fivefFeatureAuthorization === 'string') {
          authorizedFeature = !!featureSet[this.fivefFeatureAuthorization];
        }

        if (!!this.fivefFeatureAuthorization && Array.isArray(this.fivefFeatureAuthorization)) {
          this.fivefFeatureAuthorization.forEach(feature => {
            authorizedFeature = authorizedFeature || !!featureSet[feature];
          })
        }

        if (authorizedFeature) {
          if (this.fivefFeatureRoute) {
            this.router.navigate(this.fivefFeatureRoute);
          }
        } else {
          const msg = this.i18nSvc.instant('SALES.ERRORS.FEATURE_NOT_BOOKED');
          alert(msg);

          this.onAction.emit();
        }
      });
  }

  constructor(private store: Store<AppState>,
              private router: Router,
              private i18nSvc: TranslateService) { }
}
