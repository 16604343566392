import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {IProcessArtifactStats, ProcessArtifact} from './process-artifact';
import * as dayjs from 'dayjs';
import {ICollectionStore} from '../collection/collection';

export interface State extends EntityState<ProcessArtifact> {
  loading: boolean;
  transfering: boolean;

  /**
   * Collections of 'paginated' artifact scopes:
   * See also process-artifact.collection.ts.
   */
  collections: { [id: string]: ICollectionStore };

  /**
   * Minimal artifact statistics containing the reference, role and new upload state.
   */
  statistics: IProcessArtifactStats[];

  /**
   * Statistic object ID map for fast access, e.g. to set newUpload property on mark seen action.
   */
  statisticEntities: {[id: string]: IProcessArtifactStats };

  /**
   * Selectable or available IDs in current context.
   */
  selectableIds: string[];
}

export const adapter = createEntityAdapter<ProcessArtifact>({
  sortComparer: (l, r) => -(dayjs(l.updatedAt).diff(dayjs(r.updatedAt)))
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  transfering: false,
  collections: {},
  statistics: [],
  statisticEntities: {},
  selectableIds: [],
});
