import {DocumentPreviewDocumentActions, DocumentPreviewDocumentActionTypes} from './document-preview-document.actions';
import {adapter, initialState, State} from './document-preview-document.state';

export function reducer(state = initialState, action: DocumentPreviewDocumentActions): State {
  switch (action.type) {
    case DocumentPreviewDocumentActionTypes.RequestDocuments:
      return {
        ...state,
        loading: true
      };
    case DocumentPreviewDocumentActionTypes.RequestDocumentsSuccess:
      return adapter.setAll(action.documents, {
        ...state,
        loading: false,
      });
    case DocumentPreviewDocumentActionTypes.RequestDocumentsFail:
      return {
        ...state,
        loading: false,
      };
    case DocumentPreviewDocumentActionTypes.Reset:
      return Object.assign({}, initialState);
  }
  return state;
}

