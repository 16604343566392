<div class="ff-w100 ff-centered ff-column">
  <fivef-icon-message-block [icon]="'report'"></fivef-icon-message-block>

  <div class="ff-centered ff-column">
    <h1 class="error--text font-weight-light h1 mb-1">{{ error?.error?.data?.attributes?.message_de }}</h1>
    <h2 class="error--text font-weight-light h2 mb-3">{{ error?.error?.data?.attributes?.message_en }}</h2>

    <a mat-stroked-button (click)="refresh()" class="mb-5">
      <mat-icon>refresh</mat-icon>
      {{ 'GENERAL.TRY_AGAIN' | translate }}</a>
  </div>
</div>
