<div class="fivef-artifact-preview-browser-overview--container">
  <div
    class="five-f-preview-overview__preview five-f-preview-overview__preview__thumbnail-container five-f-widget five-f-elevation-z-2"
    *ngFor="let thumbnail of thumbnails; trackBy: trackBy"
    inViewport
    [inViewportOptions]="{ partial: true, threshold: [0] }"
    (inViewportAction)="showItem($event, thumbnail.id)">
    <div *ngIf="isInViewPort[thumbnail.id]">

      <!-- label tag -->
      <fivef-template-tag *ngIf="itemLookupMap[thumbnail.id]?.role === 'template'"
                          class="fivef-artifact-preview-browser-overview--template-tag"></fivef-template-tag>

      <div class="fivef-artifact-preview-browser-overview--new-upload"
           *ngIf="itemLookupMap[thumbnail.id]?.newUpload"></div>

      <!--  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" style="padding: 0rem 0.5rem 1rem 0.5rem;" *ngFor="let doc of documents">-->
      <div class="five-f-preview-overview__preview__description-overlay"
           *ngIf="itemLookupMap[thumbnail.id] as artifact"
           (click)="onImageClick(thumbnail.id)">

        <div class="w-100 five-f-preview-overview__preview__description-overlay__title"
             [matTooltip]="artifact?.title">
          <div class="d-flex align-items-center justify-content-center">
            <p style="font-size: 1rem" class="text-truncate">{{ artifact?.title }}</p>
          </div>

          <div class="d-flex align-items-center text-truncate"
               style="padding-bottom: 5px;"
               matTooltip="{{ 'DMS.UPLOADED_BY' | translate }}: {{ artifact?.uploaderEmail }}">
            <fivef-avatar [size]="'xs'" [email]="artifact?.uploaderEmail"></fivef-avatar>
            <div class="text-truncate">{{ artifact?.uploaderName }}</div>
          </div>

          <div class="ff-space-between">
            <div class="ff-column ff-truncate">
              <div style="color: #d3d6da">{{ 'DMS.SIZE' | translate }}
                : {{ artifact?.size | fivefFileSize }}
              </div>
              <div style="color: #d3d6da">{{ 'GENERAL.UPLOADED_AT' | translate }}
                : {{ artifact?.createdAt | date: 'shortDate' }}
              </div>
            </div>

            <div>
              <button mat-icon-button
                      (click)="download($event, artifact.id, artifact?.title)">
                <mat-icon>file_download</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div style="position: relative;"
           class="w-90 ff-pointer"
           [style.paddingTop.%]="calculateImageHeightPercent(thumbnail)"
           (click)="onImageClick(thumbnail.id)">

        <div *ngIf="isNotFoundDocument(thumbnail)"
             class="fivef-artifact-preview-browser-overview--inner-container">
          <!-- Alternative 'PREVIEW_BROWSER.DOCUMENT_NOT_FOUND' -->
          <fivef-icon-message-block [icon]="itemLookupMap[thumbnail.id] | fivefFileIcon"
                                    [message]="itemLookupMap[thumbnail.id] | fivefHumanFileType | translate"></fivef-icon-message-block>
        </div>

        <div *ngIf="!isNotFoundDocument(thumbnail)"
             class="fivef-artifact-preview-browser-overview--inner-container">
          <ng-container *ngIf="thumbnail.pages > 0 && thumbnail.previews.length > 0">
            <img class="p-0 m-0 w-100 h-100 dvtx-document-preview-image"
                 inViewport
                 [inViewportOptions]="{ threshold: 0.0001 }"
                 [dvtxLazyPreview]="thumbnail.previews[0]"
                 #preview="dvtxLazyPreview"
                 src=""/>

            <dvtx-lazy-preview-loading-skeleton *ngIf="preview.loading"></dvtx-lazy-preview-loading-skeleton>

            <ng-container *ngIf="preview.loaded && !preview.isComplete">
              <!-- Alternative 'PREVIEW_BROWSER.PREVIEW_NOT_AVAILBLE_OR_DAMAGED' -->
              <fivef-icon-message-block [icon]="itemLookupMap[thumbnail.id] | fivefFileIcon"
                                        [message]="itemLookupMap[thumbnail.id] | fivefHumanFileType | translate"></fivef-icon-message-block>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="thumbnail.pages === 0 || thumbnail.previews.length === 0">
            <!-- Alternative 'PREVIEW_BROWSER.PREVIEW_NOT_AVAILBLE_OR_DAMAGED' -->
            <fivef-icon-message-block [icon]="itemLookupMap[thumbnail.id] | fivefFileIcon"
                                      [message]="itemLookupMap[thumbnail.id] | fivefHumanFileType | translate"></fivef-icon-message-block>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
