<div class="fivef-comment--container"
     fivefMarkRead
     [markReadMessage]="_comment"
     [markReadMessageFrom]="resourceType"
     [markReadResourceId]="resourceId"
     (onMarkedRead)="onMarkedRead.emit($event)">

  <!-- Header with avatar,  -->
  <div class="fivef-comment--avatar">
    <fivef-avatar [size]="'sm'" [email]="_comment?.performer"
                  [matTooltip]="avatarService.getProfile(_comment.performer)?.name || _comment?.performer"
                  [showTitle]="false"></fivef-avatar>
  </div>

  <!-- top line with menu and date -->
  <article class="fivef-comment--content-wrapper">
    <header class="fivef-comment--header">
      <div class="fivef-comment--header--author-info">
        <strong>{{ avatarService.getProfile(_comment.performer)?.name || _comment.performer }}</strong>
        &nbsp;
        <small>{{ _comment.createdAt | date : 'short' }}
          <ng-container *ngIf="_comment?.createdAt !== _comment?.updatedAt">
            <em>({{ 'GENERAL.EDITED' | translate }})</em>
          </ng-container>
        </small>
      </div>

      <div>
        <button *ngIf="canCreateComment && (isAuthor || enableReply)"
                class="fivef-comment--action-button"
                [matMenuTriggerFor]="menu"
                [matTooltip]="'PROJECT_ROOM.ACTIONS' | translate">
          <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #menu="matMenu" class="fivef-menu">
          <fivef-menu-item *ngIf="isAuthor"
                           [icon]="'edit'"
                           [disabled]="editMode"
                           [title]="'PROJECT_ROOM.EDIT_COMMENT'"
                           (click)="enableEditMode()"></fivef-menu-item>

          <fivef-menu-item *ngIf="isAuthor"
                           [icon]="'delete'"
                           [disabled]="editMode"
                           [title]="'PROJECT_ROOM.DELETE_COMMENT'"
                           (click)="deleteComment()"></fivef-menu-item>

          <fivef-menu-item *ngIf="canCreateComment && enableReply"
                           [icon]="'reply'"
                           [disabled]="showReplyEditor"
                           [title]="'PROJECT_ROOM.REPLY_ACTION'"
                           (click)="enableReplyMode()"></fivef-menu-item>
        </mat-menu>
      </div>
    </header>

    <!-- Real comment or edit mode -->
    <section class="fivef-comment--content">
      <!-- Source content if replied message -->
      <div class="fivef-comment--content--reply" *ngIf="_comment?.replyTo">
        <div class="fivef-comment--content--reply--title">
          {{ _comment?.replyToAuthor }} {{ 'GENERAL.WROTE_AT' | translate }} {{ _comment?.replyToDate | date:'medium' }}
        </div>

        <div class="fivef-comment--content--reply--content"
             [froalaView]="_comment?.replyTo"></div>
      </div>

      <ng-template [ngIf]="editMode" [ngIfElse]="viewMode">
        <fivef-message-editor [message]="_comment?.message"
                              [saveIcon]="'save'"
                              [slimToolbar]="slimToolbar"
                              [showCancelAction]="true"
                              (onSave)="saveComment($event)"
                              (onCancel)="cancelEdit()"></fivef-message-editor>
      </ng-template>
      <ng-template #viewMode>
        <div [froalaView]="_comment?.message"></div>
      </ng-template>
    </section>

    <!-- Footer: Only shown when editing is off. -->
    <footer class="fivef-comment--footer"
            *ngIf="!editMode">
      <ng-template [ngIf]="!showReplyEditor" [ngIfElse]="showReplyEditorTpl">
        <div class="fivef-comment--footer--stats">
          <fivef-reactions [reactions]="_comment?.reactionList"
                           (onReaction)="react($event)"></fivef-reactions>
          &nbsp;|&nbsp;
          <span class="d-flex align-items-center"
                [matTooltip]="'PROJECT_ROOM.SEEN_BY' | translate"
                [matTooltipPosition]="'above'">
            <fivef-seenby [icon]="'visibility'"
                          [showTitle]="true"
                          [title]="'(' + _comment.seenBy.length + ')'"
                          [persons]='_comment.seenBy' [showAvatarTitle]='true'
                          [class.active]="_comment.seenBy.length > 0"></fivef-seenby>
          </span>
        </div>
      </ng-template>

      <!-- Reply mode: Shows reply editor -->
      <ng-template #showReplyEditorTpl>
        <fivef-message-editor [showCancelAction]="true"
                              [slimToolbar]="true"
                              [processId]="_comment?.backtrackId"
                              [id]="contentCacheContextId"
                              (onSave)="sendReply($event)"
                              (onCancel)="cancelReply()"></fivef-message-editor>
      </ng-template>
    </footer>
  </article>
</div>
