import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './document-preview-document.state';
import {DocumentPreviewDocument} from './document-preview-document';

export const stateKey = 'document-preview-document';
const getDocumentPreviewDocumentState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getDocumentPreviewDocumentEntities,
  selectAll: getAllDocumentPreviewDocuments,
} = adapter.getSelectors(getDocumentPreviewDocumentState);

export const loadingState = createSelector(
  getDocumentPreviewDocumentState,
  (state) => state.loading
);

export const documentWithId = (id: string) => createSelector(
  getDocumentPreviewDocumentEntities,
  (documentEntities) => documentEntities[id]
);

/**
 * Returns all artifact preview configurations from PVMS with ID out of ids.
 *
 * @param ids
 */
export const previewConfigsByIds = (ids: string[]) => createSelector(
  getDocumentPreviewDocumentEntities,
  (entities) => {
    if (!ids?.length) {
      return [];
    }

    const res = [];
    ids.forEach(id => {
      if (entities[id]) {
        res.push(entities[id]);
      }
    })
    return res;
  }
);

