import {ChangeDetectorRef, Component, Inject, NgZone, OnDestroy, OnInit} from '@angular/core';
import {AppState} from 'app/app.state';
import {Store} from '@ngrx/store';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {Observable} from 'rxjs/internal/Observable';
import {of} from 'rxjs/internal/observable/of';
import {Subject} from 'rxjs/internal/Subject';
import {DocumentPreviewDocument} from 'app/+store/document-preview-document/document-preview-document';
import {DocumentPreviewDocumentActions, DocumentPreviewDocumentSelectors} from 'app/+store/document-preview-document';
import {DocumentPreviewPreview} from 'app/+store/document-preview-preview/document-preview-preview';
import {DocumentPreviewPreviewState} from 'app/+store/document-preview-preview/document-preview-preview.interface';
import {distinctUntilChanged, filter, first, map, switchMap, take, takeUntil} from 'rxjs/operators';
import {IProcessArtifactStats, ProcessArtifact} from 'app/+store/process-artifact/process-artifact';
import {ProcessArtifactActions, ProcessArtifactSelectors} from 'app/+store/process-artifact';
import {Net} from 'app/lib/fivef-net/uuid';
import {ProcessArtifactService} from 'app/+store/process-artifact/process-artifact.service';
import {DmsPdfExportService} from 'app/+store/_legacy/api/services/dms-pdf-export.service';
import {DocumentPreview} from 'app/+store/document-signature/document-signature';
import {DocumentSignatureService} from 'app/+store/document-signature/document-signature.service';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {FeatureSelectors, ItemLabelsActions} from 'app/+store';
import {Feature} from 'app/+store/feature/feature';
import {DocumentSignatureSelectionMenuViewType, FivefSignatureSelectionComponent} from '../../../sign/fivef-signature-selection/fivef-signature-selection.component';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import * as api from '../../../../../+store/iam/process-policy.interface';
import {ProcessService} from '../../../../../+store/process/process.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Comment} from '../../../../../+store/comment/comment';
import {SUPPORTED_SELECT_ATTRS} from '../../../../../+store/process-artifact/process-artifact.interface';

/**
 *
 */
export interface IFivefArtifactPreviewBrowserDialogConfig {
  viewMode: PreviewBrowserViewmode;
  selectedTab: number;
  processId: string;
  referenceId?: string;
  role?: 'default' | 'template';
  newUploadsOnly?: boolean;
  selectedId?: string;
  selectedIds?: string[];
}

/**
 * Previewer modes: Single preview of document or thumbnail browser.
 */
export enum PreviewBrowserViewmode {
  Document = 'document',
  Imagelist = 'imagelist'
}

/**
 * Toggle of preview browser between fast images and PDF previewer.
 */
export enum DocumentViewMode {
  Image = 'Image',
  PDF = 'PDF'
}

@Component({
  selector: 'fivef-artifact-preview-browser',
  host: {'class': 'fivef-preview-browser'},
  templateUrl: './fivef-artifact-preview-browser.component.html',
  styleUrls: ['./fivef-artifact-preview-browser.component.scss'],
})
export class FivefArtifactPreviewBrowserComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject<void>();

  /**
   * True if one upload is unread.
   *
   * @protected
   */
  protected newUploadsPresent = false;

  protected policy$: Observable<api.Iam.IProcessPolicy>;
  protected featureSet$: Observable<Feature>;

  protected previewConfigs$: Observable<DocumentPreviewDocument[]>;

  /**
   * Loading state.
   * @protected
   */
  protected loading: boolean = false;
  private collectionLoading$ = new BehaviorSubject<boolean>(false);

  /**
   * Query filters.
   */
  protected processId: string;
  protected selectedId: string;
  protected selectedIds: string[];
  private role: 'default' | 'template' = null;
  private newUploadsOnly: boolean = false;
  private referenceId: string;

  /**
   * Toggle single preview mode and file browser.
   */
  protected viewMode: PreviewBrowserViewmode = PreviewBrowserViewmode.Document;
  readonly DocumentViewMode = DocumentViewMode;
  protected documentViewMode: DocumentViewMode = DocumentViewMode.Image;
  protected selectedTab = 0;

  readonly DocumentSignatureSelectionMenuViewType = DocumentSignatureSelectionMenuViewType;

  protected fullScreen: boolean = false;

  protected thumbnails$: Observable<DocumentPreviewDocument[]>;

  /**
   * Local authorized collection of artifact info objects.
   *
   * @protected
   */
  protected artifactCollection$ = new BehaviorSubject<IProcessArtifactStats[]>([]);

  private processId$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private viewDocumentId$: BehaviorSubject<string> = new BehaviorSubject(null);

  protected pdfLoading = true;
  protected pdfIsAvailable = true;
  protected pdf$ = new BehaviorSubject<DocumentPreview>(null);

  protected currentPreview$: Observable<DocumentPreviewDocument>;
  protected currentProcessArtifactId$ = new BehaviorSubject<string>(null);
  private currentProcessArtifact$ = new BehaviorSubject<DocumentPreviewDocument>(null);
  protected currentProcessArtifact: ProcessArtifact;

  constructor(@Inject(MAT_DIALOG_DATA) public data: IFivefArtifactPreviewBrowserDialogConfig,
              private dialogRef: MatDialogRef<FivefArtifactPreviewBrowserComponent>,
              private store: Store<AppState>,
              private processSvc: ProcessService,
              private artifactSvc: ProcessArtifactService,
              private pdfSvc: DmsPdfExportService,
              private signSvc: DocumentSignatureService,
              private cdr: ChangeDetectorRef,
              private ngZone: NgZone) {
    this.selectedTab = data.selectedTab;

    this.processId = data.processId;
    this.referenceId = data.referenceId;
    this.role = data.role;
    this.newUploadsOnly = data.newUploadsOnly;

    if (!!data.selectedId) {
      this.selectedId = data.selectedId;
      // TODO BY INPUT this.openArtifact(documentId);
    }

    this.viewMode = data.viewMode;
  }

  ngOnInit() {
    // Feature authorization.
    this.policy$ = this.processSvc.myIamProcessPolicy(this.processId);
    this.featureSet$ = this.store.select(FeatureSelectors.getCurrentFeatureSet);

    // Data loading.
    this.installLoadingHandler();

    // Authorized collection: All candidates by process, role, reference ID or newUpload flag..
    this.fetchArtifactCollection();

    // Filtered artifact IDs for the PVMS call. Filtered subset if data.selectedIds is defined.
    const selectedIds$ = this.artifactCollection$.pipe(map((collection: IProcessArtifactStats[]) => {
      // Default: All IDs from collection.
      let selectedIds = collection.map(a => a.id);
      let filteredCollection = collection;

      // Filter returned IDs, if a selected is requested.
      // Update the newUploads flag based on the filtered collection.
      const requestedIds = this.data.selectedIds
      if (requestedIds?.length) {
        const filterMap = {};
        requestedIds.forEach((id) => filterMap[id] = true)
        selectedIds = selectedIds.filter(id => !!filterMap[id]);

        const selectedIdsMap = {};
        selectedIds.forEach(id => selectedIdsMap[id] = true);
        filteredCollection = collection.filter(a => !!selectedIdsMap[a.id]);
      }

      this.updateNewUploadFlag(filteredCollection);
      return selectedIds;
    }));

    // Creates a getter for the selectedIds.
    // Prevents a material changed after checked error.
    selectedIds$
      .pipe(takeUntil(this.onDestroy))
      .subscribe(ids => {
        this.selectedIds = ids || [];
        this.cdr.detectChanges();
      });

    // Returns the PVMS thumbnail preview configs.
    this.previewConfigs$ = selectedIds$.pipe(switchMap(ids => this.store.select(DocumentPreviewDocumentSelectors.previewConfigsByIds(ids))));

    // Fetches the PVMS preview configs.
    selectedIds$
      .pipe(filter((ids: string[]) => !!ids?.length), take(1), takeUntil(this.onDestroy))
      .subscribe(ids => this.store.dispatch(new DocumentPreviewDocumentActions.RequestDocuments(ids)));

    // Open the artifact in document view of explicitly demanded by ID.
    if (this.selectedId) {
      this.openArtifactDetails(this.selectedId);
    }

    this.currentPreview$ = combineLatest(this.viewDocumentId$, this.previewConfigs$)
      .pipe(
        map(([viewDocumentId, docs]: [string, DocumentPreviewDocument[]]) => {
          let document = null;
          this.currentProcessArtifactId$.next(viewDocumentId);

          if (!viewDocumentId && docs.length === 0) {
            return null;
          }

          if (!viewDocumentId && docs.length > 0) {
            document = docs[0];
          }

          document = document || docs.find((doc) => doc.id === viewDocumentId);

          // If document is found and valid run mark seen call to remove new indicator.
          if (document) {
            this.currentProcessArtifact$.next(document);
          }
          return document || this.getNotFoundDocument(viewDocumentId);
        })
      );

    this.thumbnails$ = combineLatest(this.previewConfigs$, selectedIds$)
      .pipe(
        map(([docs, ids]: [DocumentPreviewDocument[], string[]]) => {
          const res: DocumentPreviewDocument[] = [];
          ids.forEach((id: string) =>
            res.push(docs.find((doc) => doc.id === id) || this.getNotFoundDocument(id))
          );
          return res;
        }));

    this.installFullScreenHandler();
    this.installCurrentProcessArtifactHandler();
    this.installFetchLabelHandler();
  }

  ngOnDestroy() {
    this.pdf$.complete();
    this.currentProcessArtifactId$.complete();
    this.currentProcessArtifact$.complete();
    this.viewDocumentId$.complete();
    this.processId$.complete();
    this.onDestroy.next();
    this.onDestroy.complete();
    this.artifactCollection$.complete();
    this.collectionLoading$.complete();
  }

  /**
   * Opens an artifact.
   *
   * TODO: Code smell! Refactor/reimplementation needed. Never work sync with streams!
   *
   * @param artifactId
   * @private
   */
  private openArtifactDetails(artifactId: string): void {
    this.viewMode = PreviewBrowserViewmode.Document;
    this.loadPDFPreview(artifactId);
    this.viewDocumentId$.next(artifactId);
    this.currentProcessArtifactId$.next(artifactId);

    this.loadArtifactById(artifactId);
    this.store.select(ProcessArtifactSelectors.getProcessArtifactById(artifactId))
      .pipe(filter(a => !!a), first(), takeUntil(this.onDestroy))
      .subscribe(processArtifact => {
        if (processArtifact && artifactId === processArtifact.id) {
          this.currentProcessArtifact = processArtifact;
          this.cdr.detectChanges();
        }
      })

    this.markSeen(artifactId);
    this.cdr.detectChanges();
  }

  /**
   * `Next artifact` button handler.
   *
   * @param artifactId
   * @protected
   */
  protected openNextArtifact(artifactId: string = null): void {
    if (!artifactId) {
      artifactId = this.viewDocumentId$.value;
    }
    this.openArtifactDetails(this.getNextDocumentId(artifactId));
  }

  /**
   * `Previous artifact` button handler.
   *
   * @param artifactId
   * @protected
   */
  protected openPreviousArtifact(artifactId: string = null): void {
    if (!artifactId) {
      artifactId = this.viewDocumentId$.value;
    }
    this.openArtifactDetails(this.getPreviousDocumentId(artifactId));
  }

  /**
   * Opens the artifact thumbnail overview.
   *
   * @protected
   */
  protected openOverview(): void {
    this.viewMode = PreviewBrowserViewmode.Imagelist;
    this.cdr.detectChanges();
  }

  /**
   * Opens the document view with the artifact given by ID.
   * Delegator for opening the artifact details in the document view mode.
   *
   * @param artifactId
   * @protected
   */
  protected openDocumentView(artifactId: string) {
    this.openArtifactDetails(artifactId);
  }

  /**
   * Document view button handler.
   *
   * @protected
   */
  protected toDocumentView(): void {
    this.viewMode = PreviewBrowserViewmode.Document;
    this.cdr.detectChanges();
  }

  /**
   * `Next artifact` action handler.
   *
   * @param artifactId
   * @private
   */
  private getNextDocumentId(artifactId: string = null): string {
    if (!artifactId) {
      artifactId = this.viewDocumentId$.value;
    }
    const index: number = this.selectedIds.indexOf(artifactId);
    if (index < 0) {
      return this.selectedIds[0];
    }
    if (index === this.selectedIds.length - 1) {
      return this.selectedIds[0];
    }
    return this.selectedIds[index + 1];
  }

  /**
   * `Previous artifact` action handler.
   *
   * @param artifactId
   * @private
   */
  private getPreviousDocumentId(artifactId: string = null): string {
    if (!artifactId) {
      artifactId = this.viewDocumentId$.value;
    }
    const index: number = this.selectedIds.indexOf(artifactId);
    if (index < 0) {
      return this.selectedIds[0];
    }
    if (index === 0) {
      return this.selectedIds[this.selectedIds.length - 1];
    }
    return this.selectedIds[index - 1];
  }

  private getNotFoundDocument(
    artifactId: string = null
  ): DocumentPreviewDocument {
    return new DocumentPreviewDocument(
      artifactId,
      0,
      'Document Not Found',
      '',
      null,
      []
    );
  }

  protected isNotFoundDocument(document: DocumentPreviewDocument): boolean {
    return (
      document.pages === 0 &&
      document.displayName === 'Document Not Found' &&
      document.fileName === '' &&
      document.mimeType === null
    );
  }

  /**
   * Preview height calculation for the dialog.
   *
   * @param preview
   * @protected
   */
  protected calculateImageHeightPercent(preview: DocumentPreviewPreview): number {
    if (preview.state !== DocumentPreviewPreviewState.Completed) {
      return 50;
    }
    if (
      !preview.width ||
      !preview.height ||
      preview.width === 0 ||
      preview.height === 0
    ) {
      return 50;
    }

    return (preview.height / preview.width) * 90;
  }

  /**
   * Direct PDF download.
   * See alternate version for the PDF view below.
   *
   * @protected
   */
  protected loadPDF() {
    if (!this.viewDocumentId$.value) return;

    this.pdfSvc.getPdf(this.viewDocumentId$.value);
  }

  /**
   * Direct download of the artifact.
   *
   * @protected
   */
  protected download() {
    if (!this.viewDocumentId$.value || !this.currentProcessArtifact) return;
    this.artifactSvc.download(this.processId, this.currentProcessArtifact);
  }

  /**
   * Loads the PDF preview.
   * Uses the in principle generic signature PDF API.
   *
   * @param did
   * @private
   */
  private loadPDFPreview(did: string) {
    this.pdfIsAvailable = true;
    this.pdfLoading = true;
    // this.pdf$.next(null);

    if (this.documentViewMode === DocumentViewMode.Image) return;

    if (Net.validUUID(did)) {
      this.ngZone.runOutsideAngular((_) => {
        this.signSvc
          .getDocumentPreview(did)
          .pipe(first())
          .subscribe(
            (document) => {
              this.pdfIsAvailable = true;
              this.pdfLoading = false;
              this.pdf$.next(document);
              this.cdr.detectChanges();
            },
            (err) => {
              this.pdfIsAvailable = false;
              this.pdfLoading = false;
              this.pdf$.next(null);
              this.cdr.detectChanges();
            }
          );
      });
    }
  }

  /**
   * Toggles the details view between PDF and image preview mode.
   *
   * @param $event
   * @protected
   */
  protected switchDocumentViewMode($event: MatSlideToggleChange) {
    if ($event.checked) {
      this.switchToPdfPreview();
    } else {
      this.switchToImagePreview();
    }
  }

  /**
   * Switches the dialog to the PDF variant (in contrast to image preview mode).
   *
   * @protected
   */
  protected switchToPdfPreview() {
    this.documentViewMode = DocumentViewMode.PDF;
    this.loadPDFPreview(this.viewDocumentId$.value);
    this.cdr.detectChanges();
  }

  /**
   * Switches the dialog to the image preview variant (in contrast to PDF mode).
   * @protected
   */
  protected switchToImagePreview() {
    this.documentViewMode = DocumentViewMode.Image;
    this.cdr.detectChanges();
  }

  /**
   * Returns true if artifact is supporting signature.
   *
   * @param artifact
   * @protected
   */
  protected signatureSupported(artifact): boolean {
    if (!artifact) {
      return false;
    }
    return FivefSignatureSelectionComponent.supportedDocument(artifact);
  }

  /**
   * Public refreshes the artifact on mark read.
   *
   * @param comment
   */
  protected loadArtifactById(id: string) {
    this.store.dispatch(new ProcessArtifactActions.LoadOneById(id));
  }

  /**
   * Public refreshes the artifact on mark read.
   *
   * @param comment
   */
  protected loadArtifactByComment(comment: Comment) {
    this.store.dispatch(new ProcessArtifactActions.LoadOneById(comment.backtrackId));
  }

  /**
   * Sets the fulssscreen mode of the preview dialog.
   *
   * @param value
   * @protected
   */
  protected openFullScreenDialog(value) {
    this.adjustFullScreen(value);
  }

  /**
   * Closes the dialog.
   *
   * @protected
   */
  protected closeDialog() {
    this.dialogRef.close();
  }

  /**
   * Marks the artifact read and updates the inner collection of this
   * component.
   *
   * @param artifactId
   * @private
   */
  private markSeen(artifactId: string) {
    this.ngZone.runOutsideAngular(_ => {
      const collection = [...this.artifactCollection$.value];
      let found = false;
      collection.map(a => {
        if (a.id === artifactId && a.newUpload) {
          a.newUpload = false;
          found = true;
        }
        return a;
      });

      // Only update on change.
      if (found) {
        this.artifactCollection$.next(collection);
        this.store.dispatch(new ProcessArtifactActions.MarkSeen(artifactId));
      }

      this.updateNewUploadFlag(collection);
    });
  }

  /**
   * Installs a handler for the <tt>currentProcessArtifactId$</tt> and
   * sets the member currentProcessArtifact;
   *
   * @private
   */
  private installCurrentProcessArtifactHandler() {
    this.currentProcessArtifactId$.pipe(
      switchMap((id) => {
        if (!Net.validUUID(id)) return of(null);
        return this.store.select(ProcessArtifactSelectors.getProcessArtifactById(id));
      }),
      takeUntil(this.onDestroy)
    ).subscribe(artifact => {
      if (artifact) {
        this.currentProcessArtifact = artifact;
        this.cdr.detectChanges();
      }
    });
  }

  /**
   * Fetches the labels for the current selected artifact.
   *
   * @private
   */
  private installFetchLabelHandler() {
    combineLatest(this.policy$, this.currentProcessArtifactId$.pipe(filter(id => !!id), distinctUntilChanged()))
      .pipe(takeUntil(this.onDestroy))
      .subscribe(([policy, id]) => {
        if (!policy.canUpdateLabels || !id) {
          return;
        }
        this.ngZone.runOutsideAngular(() => this.store.dispatch(new ItemLabelsActions.LoadAll([id])));
      });
  }

  /**
   * Sets the dialog to fullscreen depending on the window screen size.
   * @private
   */
  private installFullScreenHandler() {
    const smallScreen = window.innerWidth < 1200 ? true : false;
    if (smallScreen) {
      this.openFullScreenDialog(true);
    } else {
      this.openFullScreenDialog(false);
    }
  }

  private updateNewUploadFlag(collection) {
    this.newUploadsPresent = !!collection.find(a => a.newUpload);
    this.cdr.detectChanges();
  }

  /**
   * Fetches a local collection of minimal artifact data into the dialog.
   *
   * @private
   */
  private fetchArtifactCollection() {
    this.collectionLoading$.next(true);

    const selectedAttributes: SUPPORTED_SELECT_ATTRS[] = ['id', 'title', 'role', 'new_upload', 'size', 'performer', 'created_at', 'content_type'];
    const filterConfig = {}
    if (this.newUploadsOnly) {
      filterConfig['newUploadsOnly'] = this.newUploadsOnly;
    }

    if (this.role) {
      filterConfig['role'] = this.role;
    }

    if (this.referenceId) {
      filterConfig['referenceId'] = this.referenceId;
    }

    this.artifactSvc.getArtifactStatisticsForProcess(this.processId, selectedAttributes, filterConfig)
      .pipe(first())
      .subscribe(artifactStatistics => {
        this.artifactCollection$.next(artifactStatistics.map(a => ProcessArtifact.buildArtifactStatObject(a)));
        this.collectionLoading$.next(false);
      }, error => {
        this.collectionLoading$.next(false);
      });
  }

  /**
   * Sets fullscreen panels classes for improved scrolling and width/height behavior.
   *
   * @param value
   */
  private adjustFullScreen = (value) => {
    this.fullScreen = value;

    if (value) {
      this.dialogRef.updateSize('100%', '100%');
      this.dialogRef.addPanelClass('fivef-dialogue-fullScreen');
    } else {
      this.dialogRef.updateSize('80vw', '');
      this.dialogRef.removePanelClass('fivef-dialogue-fullScreen');
    }
    this.cdr.detectChanges();
  }

  /**
   * Sets loading member. Combines the stats call and loading state of the PVMS response.
   * @private
   */
  private installLoadingHandler() {
    combineLatest(this.collectionLoading$, this.store.select(DocumentPreviewDocumentSelectors.loadingState))
      .pipe(takeUntil(this.onDestroy))
      .subscribe((([cLoading, pLoading]: [boolean, boolean]) => {
        this.loading = cLoading || pLoading;
        this.cdr.detectChanges();
      }));
  }
}
