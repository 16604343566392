import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {FivefApiResourceService} from 'app/lib/fivef-net/fivef-api-resource/services/fivef-api-resource.service';
import {Client, ClientContact} from './client';
import {ClientBuilder, ClientContactBuilder} from './client.builder';
import {IClientV3Query} from './client.interface';
import {ContactClientBuilder} from './contact-client.builder';

@Injectable()
export class ClientService {
  readonly BASE_PATH = 'api/v1/clients/clients';
  readonly BASE_V3_PATH = 'api/v3/clients/clients';
  readonly BASE_V3_PATH_PROCESSES = 'api/v3/workflow_engine/processes/clients';
  readonly BASE_V3_PATH_2 = 'api/v3/clients';
  readonly BASE_V3_PATH_ORG = 'api/v3/organizations';

  constructor(private _http: FivefApiResourceService) {
  }

  getAll(dense = true): Observable<Client[]> {
    const builder = new ClientBuilder();
    return <Observable<Client[]>>this._http.get<ClientBuilder, Client>(builder, `${this.BASE_PATH}?dense=${dense}`);
  }

  getOne(id: string): Observable<Client> {
    const builder = new ClientBuilder();
    return <Observable<Client>>this._http.get<ClientBuilder, Client>(builder, `${this.BASE_PATH}/${id}`);
  }

  getAllPaginated(page: number = 1, query: IClientV3Query = null): Observable<Client[]> {
    const builder = new ClientBuilder();
    let queryParams = `?page=${page}`;
    const _queryParams = [];
    if (query && query['search']) {
      _queryParams.push(`q=${query['search']}`);
    }
    queryParams = _queryParams.length > 0 ? `${queryParams}&${_queryParams.join('&')}` : queryParams;
    return <Observable<Client[]>>this._http.get<ClientBuilder, Client>(builder, `${this.BASE_V3_PATH}${queryParams}`);
  }

  getAllForProcesses(parentId: string = null, dense = true): Observable<Client[]> {
    const builder = new ClientBuilder();
    const query: string = parentId ? `?parent_id=${parentId}` : '';
    if (dense) {
      const denseParam = '&dense=true';
    }
    return <Observable<Client[]>>this._http.get<ClientBuilder, Client>(builder, `${this.BASE_V3_PATH_PROCESSES}${query}`);
  }

  create(client: Client): Observable<Client> {
    const builder = new ClientBuilder();
    const payload = builder.toRequest(client);
    return <Observable<Client>>this._http.post<ClientBuilder, Client>(builder, `${this.BASE_PATH}`, payload);
  }

  update(client: Client): Observable<Client> {
    const builder = new ClientBuilder();
    const payload = builder.toRequest(client);
    return <Observable<Client>>this._http.put<ClientBuilder, Client>(builder, `${this.BASE_PATH}/${client.id}`, payload);
  }

  destroy(clientId: Client): Observable<Client> {
    const builder = new ClientBuilder();
    return <Observable<Client>>this._http.del<ClientBuilder, Client>(builder, `${this.BASE_PATH}/${clientId}`);
  }

  getContactsByClientId(clientId): Observable<any[]> {
    const builder = new ContactClientBuilder();
    return <Observable<any[]>>this._http.get<any, any>(builder, `${this.BASE_V3_PATH}/${clientId}/contacts`);
  }

  getContactClientMappings(): Observable<any[]> {
    const builder = new ContactClientBuilder();
    return <Observable<any[]>>this._http.get<any, any>(builder, `${this.BASE_V3_PATH_2}/mappings`);
  }

  createContactClient(clientId, contactId, roleName): Observable<ClientContact> {
    const builder = new ClientContactBuilder(clientId);
    const payload = {
      data: {
        attributes: {
          'contact_id': contactId,
          'role_name': roleName
        }
      }
    }
    return <Observable<ClientContact>>this._http.post<ClientContactBuilder, ClientContact>(builder, `${this.BASE_V3_PATH}/${clientId}/contacts`, payload);
  }

  updateContactClient(clientId, contactId, roleName, relationId): Observable<any[]> {
    const builder = new ClientBuilder();
    const payload = {
      data: {
        attributes: {
          'contact_id': contactId,
          'role_name': roleName
        }
      }
    }
    return <Observable<any[]>>this._http.put<any, any>(builder, `${this.BASE_V3_PATH}/${clientId}/contacts/${relationId}`, payload);
  }

  removeContactClient(clientId, contactId): Observable<any> {
    const builder = new ClientBuilder();
    return <Observable<Client>>this._http.del<ClientBuilder, Client>(builder, `${this.BASE_V3_PATH}/${clientId}/contacts/${contactId}`);
  }

  // save(collectorId: string, categoryId: string, id: string, params: IClientParams): Observable<Client> {
  //   const builder = new ClientBuilder();
  //   const payload = {
  //     data: {
  //       attributes: {
  //         title: params.title,
  //         description: params.description,
  //         due_date: params.dueDate,
  //         pre_due_date: params.preDueDate,
  //         started_at: params.startedAt,
  //         completed_at: params.completedAt
  //       }
  //     }
  //   };
  //   return <Observable<Client>>this._http.put<ClientBuilder, Client>(builder, `${this.BASE_PATH}/checklists/${collectorId}/categories/${categoryId}/items/${id}`, payload);
  // }
  //
  // remove(collectorId: string, categoryId: string, id: string): Observable<Client> {
  //   const builder = new ClientBuilder();
  //   return <Observable<Client>>this._http.del<ClientBuilder, Client>(builder, `${this.BASE_PATH}/checklists/${collectorId}/categories/${categoryId}/items/${id}`);
  // }
  getAllByOrgId(page, orgId, query): Observable<Client[]> {
    const builder = new ClientBuilder();
    let queryParams = `?page=${page}`;
    const _queryParams = [];
    if (query && query['search']) {
      _queryParams.push(`q=${query['search']}`);
    }
    queryParams = _queryParams.length > 0 ? `${queryParams}&${_queryParams.join('&')}` : queryParams;
    return <Observable<Client[]>>this._http.get<ClientBuilder, Client>(builder, `${this.BASE_V3_PATH_ORG}/${orgId}/clients/search${queryParams}`);
  }
}
