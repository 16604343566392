import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {Subject} from 'rxjs/internal/Subject';
import {environment} from 'environments/environment';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {MatButtonModule} from '@angular/material/button';
import {FroalaEditorModule} from 'angular-froala-wysiwyg';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FivefConfirm} from '../../util/fivef-confirm-dialog/fivef-confirm.decorator';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.state';
import {takeUntil} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {Observable} from 'rxjs/internal/Observable';
import {FroalaRepository} from '../../../../+store/froala/froala.repository';
import {ContentCacheService} from '../../../../+store/content-cache/content-cache.service';
import {WYSIWYG_DEFAULT_TOOLBAR, WYSIWYG_DEFAULT_TOOLBAR_XS} from '../../../fivef-legacy/base-form-elements/components/wysiwyg-editor/wysiwyg.interface';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';

declare let $: any; // The Froala instance will be attached to the $ variable

@Component({
  selector: 'fivef-message-editor',
  standalone: true,
  host: {'class': 'fivef-message-editor'},
  templateUrl: './fivef-message-editor.component.html',
  styleUrls: ['./fivef-message-editor.component.scss'],
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule,
    MatButtonModule,
    FroalaEditorModule,
    FormsModule,
    MatTooltipModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefMessageEditorComponent implements OnDestroy, OnInit {
  private onDestroy = new Subject<void>();

  public _message: string = '';

  @ViewChild('editor', {static: true})
  editor: ElementRef;

  private _froalaInstance: any;
  public froalaOptions;
  DEFAULT_OPTIONS
  // private tribute: Tribute<any> = new Tribute({values: []});
  private processId$: BehaviorSubject<string> = new BehaviorSubject(null);
  private _froalaInstance$: BehaviorSubject<any> = new BehaviorSubject(null);
  private repository: FroalaRepository;
  // private tribute$: Observable<any>;
  private returnedCachedValue$: Observable<string>;

  dasError: boolean = false;
  fileError: boolean = false;

  @Input()
  saveIcon: 'save' | 'send' = 'send';

  @Input()
  showCancelAction: boolean = false;

  /**
   * Uses a minimal froala toolbar for little space embedment.
   */
  @Input()
  slimToolbar = false;

  @Input()
  height = 100;

  @Input()
  heightMax = 100;

  /**
   * Resets the message after sending/saving.
   * Default case for all comment sections.
   */
  @Input()
  resetOnSave = true;

  private _messagePrefixExists = false;
  private _messageInitialized = false;

  @Input()
  public placeholder = 'MESSAGING.PLACEHOLDER';

  private contentCacheContextId$: BehaviorSubject<string> = new BehaviorSubject(null);

  @Input()
  private set id(value: string) {
    this.contentCacheContextId$.next(value);
  }


  @Output()
  public onSave: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public onBlur: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public onCancel: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public onChange = new EventEmitter<string>();

  _options;


  /**
   * Helper variable to signalize that content was changed by the user
   * e.g. to adapt styling.
   * Use case: simple focus will encrease the min-height after focus.
   * Using one of froalas dialogs will shrink it again, event if something was written.
   * This feels unrelaxed during writing.
   */
  public _messageChanged: boolean = false;

  @Input()
  set autoHeight(enable) {
    this._messageChanged = enable;
  }

  get options() {
    return Object.assign({}, this.DEFAULT_OPTIONS, {
      placeholderText: this.editor.nativeElement.placeholder,
      heightMax: this.heightMax
    }, this._options);
  }

  @Input() set processId(id: string) {
    this.processId$.next(id);
  }

  @Input()
  set message(msg: string) {
    // Check if previously a prefix was set by @Input: Do not override a prefix if it was set first:
    if (this._messagePrefixExists && this._message && (!msg || msg.length === 0)) {
      this._message = `${this._message}${msg}`;
      this._messagePrefixExists = false;
      this._messageInitialized = true;

    } else {
      this._message = msg;
      // Mark for eventually setting a prefix, that message @Input was already set. Everything's fine.
      this._messageInitialized = true;
    }
  }

  @Input()
  set commentPrefix(prefix) {
    if (prefix) {
      this._message = `${prefix}<br>`;
      // @Input() message came first if this._messageInitialized
      this._messagePrefixExists = !this._messageInitialized;
    }
  }

  constructor(private _cdr: ChangeDetectorRef, private _store: Store<AppState>, private contentCacheSvc: ContentCacheService) {
    this.repository = new FroalaRepository(this._store, contentCacheSvc)
    // this.tribute$ = this.repository.tribute;
    this.returnedCachedValue$ = this.repository.returnedCachedValue;
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.processId$.complete();
    this._froalaInstance$.complete();

    // if (this._froalaInstance) {
    //   this.tribute.detach(this._froalaInstance.el);
    // }
  }

  ngOnInit() {
    this.initOptions();
    combineLatest(this.processId$, this.contentCacheContextId$).pipe(takeUntil(this.onDestroy))
      .subscribe(([processId, contentCacheContextId]) => {
        this.repository.processId = processId;
        this.repository.froalaId = contentCacheContextId;
        this.repository.initContentCache();

        // if (!id) {
        //   this.tribute = new Tribute({values: []});
        //   if (this._froalaInstance) {
        //     this.tribute.detach(this._froalaInstance.el);
        //   }
        // }
      });

    // Raises an error for non-initialized Observable
    // this._initTribute();
    this._initCachedFroalaText();
  }

  private _initCachedFroalaText() {
    this.returnedCachedValue$.pipe(takeUntil(this.onDestroy)).subscribe(cachedValue => {
      if (this.contentCacheContextId$ && this.contentCacheContextId$.value) {
        setTimeout(() => {
          this.message = cachedValue;
          this._cdr.detectChanges();
        }, 200);
      }
    })
  }

  // get options() {
  //   return Object.assign({}, this.DEFAULT_OPTIONS, {
  //     placeholderText: this.editor.nativeElement.placeholder,
  //     heightMax: this.heightMax
  //   }, this._options);
  // }

  initializeLink(controls) {
    controls.initialize();
    this._froalaInstance = controls.getEditor();
    if (!this._froalaInstance) {
      this._froalaInstance = this.editor.nativeElement['data-froala.editor'];
    }
    this._froalaInstance$.next(this._froalaInstance);
  }

  public setMessage(message: string) {
    this._messageChanged = typeof message === 'string' && message.length > 0;
    this._message = message;
    this.onChange.emit(message);
    this._cdr.detectChanges();
  }


  @FivefConfirm({
    message: 'GENERAL.DISCARD_CHANGES',
    icon: 'block',
    color: 'warn'
  })
  public cancel(msg) {
    this.onCancel.emit(msg)
  }

  public send($event) {
    $event.stopPropagation();

    const message = this._message.replace(/\n/g, '<br>');
    this.onSave.emit(message);
    this.repository.RemoveContentCache()

    if (this.resetOnSave) {
      this.message = '';
      this._cdr.detectChanges();
    }
  }

  // Raises an error for non-initialized Observable
  // private _initTribute() {
  //   combineLatest(this.tribute$, this._froalaInstance$).pipe(takeUntil(this.onDestroy), distinctUntilChanged()).subscribe(
  //     ([tribute, froalaInstance]) => {
  //       this.tribute = tribute;
  //       if (tribute && froalaInstance) {
  //         this.tribute.detach(froalaInstance.el)
  //         this.tribute.attach(froalaInstance.el)
  //       } else {
  //         this.tribute = new Tribute({values: []});
  //         if (froalaInstance) {
  //           this.tribute.detach(froalaInstance.el);
  //         }
  //       }
  //     })
  // }

  private initOptions() {
    const slimToobar = WYSIWYG_DEFAULT_TOOLBAR_XS;
    const self = this;
    this.froalaOptions = {
      emoticonsUseImage: false,
      key: environment.froalaKey,
      attribution: false,
      toolbarButtons: this.slimToolbar ? slimToobar : WYSIWYG_DEFAULT_TOOLBAR,
      toolbarButtonsXS: WYSIWYG_DEFAULT_TOOLBAR_XS,
      // videoResponsive: true,
      // videoInsertButtons: ['videoByURL', 'videoEmbed'],
      charCounterCount: false,
      quickInsertTags: [],
      fontSizeDefaultSelection: '14',
      heightMin: this.height,
      heightMax: this.heightMax,
      zIndex: 9999,
      enter: $.FE?.ENTER_BR,
      imageMaxSize: 200 * 1024,
      fileAllowedTypes: ['*'],
      fileMaxSize: 20 * 1024 * 1024,
      fileUpload: true,
      draggable: true,
      placeholderText: this.editor.nativeElement.placeholder,
      events: {
        initialized: function () {
          // const editor = this;
          // if (self.tribute) {
          //   editor.events.on('keydown', function (e) {
          //     if (e.which == 13 && self.tribute.isActive) {
          //       return false;
          //     }
          //   }, true);
          // }
        },
        'image.beforeUpload': function (files) {
          self.fileError = false;
          const editor = this;
          if (files.length) {
            const reader = new FileReader();
            reader.onload = function (event: any) {
              const result = event.target.result;
              editor.image.insert(result, null, null, editor.image.get());
            };
            let dasFiles = files[0];
            // if image is copy pasted convert blob to file
            if (dasFiles && dasFiles.type && !dasFiles.name) {
              // A Blob() is almost a File() - it's just missing the two properties below which we will add
              // Cast to a File() type
              dasFiles = new File([dasFiles], 'name', {type: dasFiles.type.replace(';base64', '')});
            }
            const imageSize = dasFiles.size;
            if (imageSize > 100000) {  // around 100KB
              self.dasError = true;
              editor.image.remove();
            } else {
              reader.readAsDataURL(dasFiles);
              self.dasError = false;
            }
          }
          editor.popups.hideAll();
          self._cdr.detectChanges();
          return false;
        },
        'file.beforeUpload': function (files) {
          const editor = this;
          self.fileError = true;
          self.dasError = false;
          editor.popups.hideAll();
          self._cdr.detectChanges();
          return false;
          let dasFiles = files[0];
          if (files.length) {
            const reader = new FileReader();
            reader.onload = function (event: any) {
              const result = event.target.result;
              editor.file.insert(result, dasFiles.name, {result: result});
            };
            // if image is copy pasted convert blob to file
            if (dasFiles && dasFiles.type && !dasFiles.name) {
              // A Blob() is almost a File() - it's just missing the two properties below which we will add
              // Cast to a File() type
              dasFiles = new File([dasFiles], 'name', {type: dasFiles.type.replace(';base64', '')});
            }
            const imageSize = dasFiles.size;
            if (imageSize > 2000000) {  // around 20MB
              self.dasError = true;
              editor.file.remove();
            } else {
              reader.readAsDataURL(dasFiles);
              self.dasError = false;
            }
          }
          editor.popups.hideAll();
          return false;
        },
        'froalaEditor.initialized': (_e, editor) => {
          this._froalaInstance = editor;
        },
        'blur': () => {
          if (this._froalaInstance) {
            this._froalaInstance.selection.save();
          }
          this.repository.cacheContent(this.editor.nativeElement.value);
        },
        'focus': () => {
          if (!this._froalaInstance) {
            this._froalaInstance = this.editor.nativeElement['data-froala.editor'];
          }
          // This is done to remove any invisible space when focusing in an empty editor
          if (this._froalaInstance && this._froalaInstance.html.get(true) === '') {
            this._froalaInstance.html.set('');
          }
        }
      }
    };
  }
}
