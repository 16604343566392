import {saveAs} from 'file-saver';
import * as environment from 'environments/environment';

export namespace FileUtils {
  export const DEFAULT_SUPPORTED_FILE_TYPES: string[] = [
    '7z', 'ac', 'ac_', 'accdb', 'avi', 'csv', 'pdf', 'jpeg', 'jpg', 'doc', 'docx', 'dtd', 'dvsdrw', 'fib', 'fibdoc', 'gz', 'htm', 'html', 'ics', 'pkb', 'pks', 'ppt', 'pptx',
    'gif', 'indd', 'png', 'txt', 'json', 'yaml', 'yml', 'eml', 'kor', 'm4v', 'mdb', 'msg', 'mp3', 'mp4', 'mov', 'wmv', 'log',
    'odt', 'odg', 'odp', 'ods', 'psd', 'ai', 'eps', 'rar', 'rft', 'sta', 'svg', 'tar', 'tax', 'tiff', 'tif', 'vcf', 'vcard',
    'xhtml', 'xlsb', 'xls', 'xlsx', 'xlsm', 'xml', 'xslt', 'webm', 'zip'
  ];
  export const DEFAULT_MAX_FILE_SIZE = environment.environment.maxFileSize;

  export function getExtension(name) {
    return name.split('.').pop().toLowerCase();
  }

  /**
   * Saves a file given as base64 encoded string.
   *
   * Directly writing into the blob fails and creates a corrupted docx.
   * Reference solution
   * - https://stackoverflow.com/questions/12168909/blob-from-dataurl
   */
  export function saveAsFile(fileName, content, mimeType) {
    const byteString = atob(content);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeType });
    saveAs(blob, fileName);
  }

  export function downloadByUrl(url) {
    const isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !('MSStream' in window);

    if (isSafari && iOS) {
      // Window open is not supported on click a-tags without local inplace javascript
      window.location.href = url;
    } else if (isSafari) {
      // Triggers popup blocker: We change location instead
      // window.open(url, '_blank');
      window.location.href = url;
    } else {
      window.open(url);
    }
  }

  /**
   * Mime config for displaying in views.
   */
  export interface IMimeConfig {
    key: string;
    i18n: string;
    icon?: string;
    isSvg?: boolean;
    framework: 'material' | 'tabler',
  }

  /**
   * Translates a file's content type to an icon the icon framework.
   *
   * === Add new types
   *
   * - Search item at https://tabler.io/icons.
   * - Add the tabler icon module to FivefIconsModule.
   * - Add the translation to de/en.json.
   *   NOTE: The content type must not contain dots '.'.
   *         Replace them by '_'.
   * - Add the new icon config to FivefIconComponent.
   *
   * @param contentType
   */
  export function getMimeTypeIconConfig(contentType: string) {
    const safeKey = contentType?.replace(/\./g, '_');
    const config: IMimeConfig = {
      key: contentType,
      i18n: `MIME_TYPE.${safeKey}`,
      icon: 'hide_image',
      framework: 'material',
      isSvg: false
    }
    switch (contentType) {
      case 'application/csv':
        config.icon = 'csv';
        config.framework = 'tabler';
        break;
      case 'application/gzip':
        config.icon = 'zip';
        config.framework = 'tabler';
        break;
      case 'application/json':
        config.icon = 'json';
        config.framework = 'tabler';
        break;
      case 'application/mbox':
        config.icon = 'archive';
        break;
      case 'application/msword':
        config.icon = 'doc';
        config.framework = 'tabler';
        break;
      case 'application/octet-stream':
        config.icon = 'binary';
        config.framework = 'material';
        break;
      case 'application/pdf':
        config.icon = 'pdf';
        config.framework = 'tabler';
        break;
      case 'application/pgp-signature':
        config.icon = 'certificate';
        config.framework = 'tabler';
        break;
      case 'application/postscript':
        config.icon = 'pdf';
        config.framework = 'tabler';
        break;
      case 'application/rtf':
        config.icon = 'document';
        break;
      case 'application/vnd.fdf':
        config.icon = 'binary';
        config.framework = 'tabler';
        break;
      case 'application/vnd.ms-access':
        config.icon = 'database';
        config.framework = 'tabler';
        break;
      case 'application/vnd.ms-excel':
        config.icon = 'xls';
        config.framework = 'tabler';
        break;
      case 'application/vnd.ms-excel.sheet.3':
        config.icon = 'xls';
        config.framework = 'tabler';
        break;
      case 'application/vnd.ms-excel.sheet.binary.macroenabled.12':
        config.icon = 'xls';
        config.framework = 'tabler';
        break;
      case 'application/vnd.ms-excel.sheet.macroenabled.12':
        config.icon = 'xls';
        config.framework = 'tabler';
        break;
      case 'application/vnd.ms-outlook':
        config.icon = 'archive';
        break;
      case 'application/vnd.ms-powerpoint':
        config.icon = 'ppt';
        config.framework = 'tabler';
        break;
      case 'application/vnd.oasis.opendocument.graphics':
        config.icon = 'image';
        config.framework = 'material';
        break;
      case 'application/vnd.oasis.opendocument.presentation':
        config.icon = 'ppt';
        config.framework = 'tabler';
        break;
      case 'application/vnd.oasis.opendocument.spreadsheet':
        config.icon = 'xls';
        config.framework = 'tabler';
        break;
      case 'application/vnd.oasis.opendocument.text':
        config.icon = 'doc';
        config.framework = 'tabler';
        break;
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        config.icon = 'ppt';
        config.framework = 'tabler';
        break;
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        config.icon = 'xls';
        config.framework = 'tabler';
        break;
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        config.icon = 'doc';
        config.framework = 'tabler';
        break;
      case 'application/vnd.rar':
        config.icon = 'archive';
        config.framework = 'tabler';
        break;
      case 'application/x-7z-compressed':
        config.icon = 'archive';
        config.framework = 'tabler';
        break;
      case 'application/x-adobe-indesign':
        config.icon = 'image';
        break;
      case 'application/x-apple-systemprofiler+xml':
        config.icon = 'binary';
        config.framework = 'tabler';
        break;
      case 'application/x-bytecode.python':
        config.icon = 'python';
        config.framework = 'tabler';
        break;
      case 'application/x-dosexec':
        config.icon = 'binary';
        config.framework = 'tabler';
        break;
      case 'application/xhtml+xml':
        config.icon = 'html';
        config.framework = 'tabler';
        break;
      case 'application/x-matroska':
        config.icon = 'movie';
        break;
      case 'application/xml':
        config.icon = 'xml';
        config.framework = 'tabler';
        break;
      case 'application/x-msaccess':
        config.icon = 'database';
        config.framework = 'tabler';
        break;
      case 'application/x-ms-dos-executable':
        config.icon = 'binary';
        config.framework = 'tabler';
        break;
      case 'application/x-msdownload':
        config.icon = 'binary';
        config.framework = 'tabler';
        break;
      case 'application/x-ole-storage':
        config.icon = 'binary';
        config.framework = 'tabler';
        break;
      case 'application/x-rar':
        config.icon = 'archive';
        config.framework = 'tabler';
        break;
      case 'application/x-rar-compressed;version=4':
        config.icon = 'archive';
        config.framework = 'tabler';
        break;
      case 'application/x-rar-compressed;version=5':
        config.icon = 'archive';
        config.framework = 'tabler';
        break;
      case 'application/xslt+xml':
        config.icon = 'xml';
        config.framework = 'tabler';
        break;
      case 'application/x-sqlite3':
        config.icon = 'database';
        config.framework = 'tabler';
        break;
      case 'application/x-subrip':
        config.icon = 'file';
        config.framework = 'tabler';
        break;
      case 'application/x-tar':
        config.icon = 'archive';
        config.framework = 'tabler';
        break;
      case 'application/x-tika-ooxml':
        config.icon = 'xml';
        config.framework = 'tabler';
        break;
      case 'application/x-wine-extension-ini':
        config.icon = 'settings';
        break;
      case 'application/x-x509-ca-cert':
        config.icon = 'zip';
        config.framework = 'tabler';
        break;
      case 'application/zip':
        config.icon = 'zip';
        config.framework = 'tabler';
        break;
      case 'application/zlib':
        config.icon = 'zip';
        config.framework = 'tabler';
        break;
      case 'audio/mpeg':
        config.icon = 'movie';
        break;
      case 'audio/x-ms-asx':
        config.icon = 'file';
        config.framework = 'tabler';
        break;
      case 'biosig/edf':
        config.icon = 'file';
        config.framework = 'tabler';
        break;
      case 'image/gif':
        config.icon = 'gif';
        config.framework = 'tabler';
        break;
      case 'image/heic':
        config.icon = 'image';
        break;
      case 'image/jpeg':
        config.icon = 'jpg';
        config.framework = 'tabler';
        break;
      case 'image/png':
        config.icon = 'png';
        config.framework = 'tabler';
        break;
      case 'image/svg+xml':
        config.icon = 'svg';
        config.framework = 'tabler';
        break;
      case 'image/tiff':
        config.icon = 'image';
        break;
      case 'image/vnd.adobe.photoshop':
        config.icon = 'image';
        break;
      case 'image/vnd.microsoft.icon':
        config.icon = 'image';
        break;
      case 'image/webp':
        config.icon = 'image';
        break;
      case 'image/x-eps':
        config.icon = 'image';
        break;
      case 'image/x-portable-bitmap':
        config.icon = 'image';
        break;
      case 'image/x-portable-pixmap':
        config.icon = 'image';
        break;
      case 'inode/x-empty':
        config.icon = 'file';
        break;
      case 'message/rfc822':
        config.icon = 'txt';
        config.framework = 'tabler';
        break;
      case 'multipart/related':
        config.icon = 'file';
        config.framework = 'tabler';
        break;
      case 'text/calendar':
        config.icon = 'event';
        break;
      case 'text/csv':
        config.icon = 'csv';
        config.framework = 'tabler';
        break;
      case 'text/html':
        config.icon = 'html';
        config.framework = 'tabler';
        break;
      case 'text/plain':
        config.icon = 'txt';
        config.framework = 'tabler';
        break;
      case 'text/spreadsheet':
        config.icon = 'table';
        break;
      case 'text/troff':
        config.icon = 'txt';
        config.framework = 'tabler';
        break;
      case 'text/vcard':
        config.icon = 'person';
        config.framework = 'tabler';
        break;
      case 'text/x-diff':
        config.icon = 'diff';
        config.framework = 'tabler';
        break;
      case 'text/x-fortran':
        config.icon = 'sourcecode';
        config.framework = 'tabler';
        break;
      case 'text/x-java':
        config.icon = 'sourcecode';
        config.framework = 'tabler';
        break;
      case 'text/x-log':
        config.icon = 'txt';
        config.framework = 'tabler';
        break;
      case 'text/x-matlab':
        break;
      case 'text/xml':
        config.icon = 'xml';
        config.framework = 'tabler';
        break;
      case 'text/x-mpsub':
        config.icon = 'file';
        config.framework = 'tabler';
        break;
      case 'application/xml-dtd':
        config.icon = 'xml';
        config.framework = 'tabler';
        break;
      case 'text/x-patch':
        config.icon = 'diff';
        config.framework = 'tabler';
        break;
      case 'text/x-python':
        config.icon = 'python';
        config.framework = 'tabler';
        break;
      case 'unknown/unknown':
        config.icon = 'hide_image';
        config.framework = 'material';
        break;
      case 'video/mp4':
        config.icon = 'movie';
        break;
      case 'video/quicktime':
        config.icon = 'movie';
        break;
      case 'video/webm':
        config.icon = 'movie';
        break;
      case 'video/x-ms-wmv':
        config.icon = 'movie';
        break;
      default:
        config.i18n = 'PREVIEW_BROWSER.PREVIEW_NOT_AVAILBLE';
        config.icon = 'hide_image';
        config.framework = 'material';
    }
    return config;
  }
}
