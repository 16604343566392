<!-- Material icons -->
<ng-template [ngIf]="framework === 'material'" [ngIfElse]="tablerIconTpl">
  <ng-container *ngIf="_icon">
    <ng-template [ngIf]="isSvg"
                 [ngIfElse]="defaultIconType">
      <mat-icon [svgIcon]="_icon"></mat-icon>
    </ng-template>
    <ng-template #defaultIconType>
      <mat-icon>{{ _icon }}</mat-icon>
    </ng-template>
  </ng-container>
</ng-template>

<!-- Tabler icons -->
<ng-template #tablerIconTpl>
  <i-tabler [name]="_icon"></i-tabler>
</ng-template>
