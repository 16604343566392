export class ContentCache {
  readonly type = 'content-cache';

  constructor(public id: string,
              public content: string) {
  }

  static getContentCacheContextId(context: string = '', processId: string, referenceId: string = '') {
    return `${context}|${processId || ''}|${referenceId || ''}`;
  }
}

