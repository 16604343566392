import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {FivefIconsModule} from '../fivef-icons/fivef-icons.module';

export interface IFivefIconConfig {
  icon: string;
  isSvgIcon: boolean;
  framework: 'material' | 'tabler'
}

@Component({
  selector: 'fivef-icon',
  host: {class: 'fivef-icon'},
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    FivefIconsModule
  ],
  templateUrl: './fivef-icon.component.html',
  styleUrls: ['./fivef-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefIconComponent {
  protected isSvg = false;
  protected _icon: string;
  protected framework: 'material' | 'tabler' = 'material';

  @HostBinding('class')
  iconSize = 'fivef-icon--md'

  @Input()
  public set size(s: 'xs' | 'sm' | 'md' | 'lg' | 'xl') {
    this.iconSize = `fivef-icon--${s}`;
  }

  @Input()
  set icon(icn: string) {
    const config: IFivefIconConfig = FivefIconComponent.getIconConfig(icn);
    this.isSvg = config.isSvgIcon;
    this._icon = config.icon;
    this.framework = config.framework;
  }

  /**
   * Returns material icon definition and SVG type.
   * The default is isSvgIcon: false and the icon string
   * as passthrough.
   *
   * @param icon
   */
  static getIconConfig(icon: string): IFivefIconConfig {
    const config: IFivefIconConfig = {
      icon: null,
      isSvgIcon: false,
      framework: 'material'
    };

    switch (icon) {
      case 'sourcecode':
        config.icon = 'source-code';
        config.framework = 'tabler';
        break;
      case 'file':
        config.icon = 'file';
        config.framework = 'tabler';
        break;
      case 'certificate':
        config.icon = 'certificate';
        config.framework = 'tabler';
        break;
      case 'json':
        config.icon = 'json';
        config.framework = 'tabler';
        break;
      case 'html':
        config.icon = 'file-type-html';
        config.framework = 'tabler';
        break;
      case 'svg':
        config.icon = 'file-type-svg';
        config.framework = 'tabler';
        break;
      case 'python':
        config.icon = 'brand-python';
        config.framework = 'tabler';
        break;
      case 'database':
        config.icon = 'database';
        config.framework = 'tabler';
        break;
      case 'diff':
        config.icon = 'arrows-diff';
        config.framework = 'tabler';
        break;
      case 'txt':
        config.icon = 'file-type-txt';
        config.framework = 'tabler';
        break;
      case 'binary':
        config.icon = 'binary';
        config.framework = 'tabler';
        break;
      case 'zip':
      case 'archive':
        config.icon = 'file-zip';
        config.framework = 'tabler';
        break;
      case 'csv':
        config.icon = 'file-type-csv';
        config.framework = 'tabler';
        break;
      case 'gif':
        config.icon = 'gif';
        config.framework = 'tabler';
        break;
      case 'jpg':
        config.icon = 'file-type-jpg';
        config.framework = 'tabler';
        break;
      case 'doc':
        config.icon = 'file-type-doc';
        config.framework = 'tabler';
        break;
      case 'png':
        config.icon = 'file-type-png';
        config.framework = 'tabler';
        break;
      case 'docx':
        config.icon = 'file-type-docx';
        config.framework = 'tabler';
        break;
      case 'ppt':
        config.icon = 'file-type-ppt';
        config.framework = 'tabler';
        break;
      case 'xls':
        config.icon = 'file-type-xls';
        config.framework = 'tabler';
        break;
      case 'pdf':
        config.icon = 'file-type-pdf';
        config.framework = 'tabler';
        break;
      case 'xml':
        config.icon = 'file-type-xml';
        config.framework = 'tabler';
        break;
      case 'category':
        config.icon = 'category';
        config.isSvgIcon = true;
        break;
      case 'engineering':
        config.icon = 'engineering';
        config.isSvgIcon = true;
        break;
      case 'hide_image':
        config.icon = 'hide_image';
        config.isSvgIcon = true;
        break;
      case 'cavLink':
        config.icon = 'cavLink';
        config.isSvgIcon = true;
        break;
      case 'excel_download':
        config.icon = 'excel_download';
        config.isSvgIcon = true;
        break
      case 'new_template_from':
        config.icon = 'new_template_from';
        config.isSvgIcon = true;
        break
      case 'change_reference':
        config.icon = 'change_reference';
        config.isSvgIcon = true;
        break
      case 'arrow_outward':
        config.icon = 'arrow_outward';
        config.isSvgIcon = true;
        break
      case 'client':
      case 'clients':
        config.icon = 'clients';
        config.isSvgIcon = true;
        break
      case 'file_version':
        config.icon = 'file_version';
        config.isSvgIcon = true;
        break
      case 'datev_data_service_logo':
        config.icon = 'datev_data_service_logo';
        config.isSvgIcon = true;
        break
      case 'drive_file_move_outline':
        config.icon = 'drive_file_move_outline';
        config.isSvgIcon = true;
        break
      case 'gt':
        config.icon = 'gt';
        config.isSvgIcon = true;
        break
      case 'project_room':
      case 'projectroom':
      case 'quickstart':
        config.icon = 'projectroom';
        config.isSvgIcon = true;
        break;
      case 'templates':
        config.icon = 'templates';
        config.isSvgIcon = true;
        break;
      case 'file_preview':
        config.icon = 'file_preview';
        config.isSvgIcon = true;
        break;
      case 'society':
      case 'organization':
        config.icon = 'domain';
        break;
      case 'attachment':
        config.icon = 'attach_file';
        break;
      case 'recycling':
        config.icon = 'recycling';
        config.isSvgIcon = true;
        break;
      case 'tasks':
      case 'task':
      case 'standard_task':
        config.icon = 'tasks';
        config.isSvgIcon = true;
        break;
      case 'supervised_user_circle':
        config.icon = 'supervised_user_circle';
        config.isSvgIcon = true;
        break;
      case 'invoice_approval':
        config.icon = 'invoice_approval';
        config.isSvgIcon = true;
        break;
      case 'project':
      case 'process':
        config.icon = 'process';
        config.isSvgIcon = true;
        break;
      case 'quickshare_v2':
      case 'quickshare':
      case 'share':
        config.icon = 'share';
        break;
      case 'cac':
      case 'exchange_circle':
      case 'cac_request':
      case 'audit_component_auditor_communications':
      case 'audit_component_auditor_communication_contribution_process':
        config.icon = 'exchange_circle';
        config.isSvgIcon = true;
        break;
      case 'collector':
      case 'quickcollector':
      case 'playlist_add_check':
        config.icon = 'playlist_add_check';
        break;
      case 'cav':
      case 'person_verif':
      case 'audit_contact_imports':
      case 'contact_verification':
      case 'audit_contact_verifications':
        config.icon = 'person_verif';
        config.isSvgIcon = true;
        break;
      case 'signature':
      case 'sign':
        config.icon = 'sign';
        config.isSvgIcon = true;
        break;
      case 'lohn':
      case 'fastdocs_employee_master_data':
        config.icon = 'lohn';
        config.isSvgIcon = true;
        break;
      case 'third_party':
      case 'leasing':
      case 'bank':
      case 'safekeeping':
      case 'expert':
      case 'insurance':
      case 'claim_invoice':
      case 'claim_balance':
      case 'liability_balance':
      case 'liability_invoice':
      case 'third_party_request':
        config.icon = 'third_party';
        config.isSvgIcon = true;
        break
      case 'fibu':
        config.icon = 'fibu_workflow';
        config.isSvgIcon = true;
        break;
      case 'rocket_launch':
        config.icon = 'rocket_launch';
        config.isSvgIcon = true;
        break;
      default:
        config.icon = icon;
        config.isSvgIcon = false;
    }
    return config;
  }
}
