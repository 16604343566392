<div *ngIf="dasError"
     class="fivef-message-editor--error-container">
  <mat-icon>warning</mat-icon>
  <p> {{ 'GENERAL.BIG_IMAGE' | translate }} </p>
</div>

<div *ngIf="fileError"
     class="fivef-message-editor--error-container">
  <mat-icon>warning</mat-icon>
  <p> {{ 'GENERAL.FILE_ERROR' | translate }} </p>
</div>

<div class="fivef-message-editor--editor"
     [class.fivef-message-editor--content-changed]="_messageChanged">
  <textarea #editor
            (froalaInit)="initializeLink($event)"
            [froalaEditor]="froalaOptions"
            [(froalaModel)]="_message"
            (froalaModelChange)="setMessage($event)"
            [placeholder]="placeholder | translate"></textarea>
</div>

<div class="fivef-message-editor--actions">
  <div>
    <button mat-icon-button
            [matTooltip]="'GENERAL.CANCEL_ACTION' | translate"
            *ngIf="showCancelAction"
            (click)="cancel(_message)">
      <mat-icon>close</mat-icon>
    </button>

    <button mat-icon-button
            [matTooltip]="(saveIcon === 'save' ? 'GENERAL.SAVE_ACTION' : 'GENERAL.SEND_ACTION') | translate"
            [disabled]="!_message || _message.length === 0"
            (click)="send($event)"
            color="primary">
      <mat-icon>{{ saveIcon }}</mat-icon>
    </button>
  </div>
</div>
