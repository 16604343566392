<div class="fivef-comments--editor-wrapper">
  <ng-template [ngIf]="canCreateComment"
               [ngIfElse]="noCommentRef">

    <fivef-message-editor (onSave)="send($event)"
                          [message]="comment"
                          [processId]="processId$ | async"
                          [id]="contentCacheContextId"
                          [slimToolbar]="slimToolbar"
                          [height]="100"
                          [heightMax]="400"
                          [commentPrefix]="commentPrefix"
                          class="d-inline-block w-100">
    </fivef-message-editor>
  </ng-template>

  <ng-template #noCommentRef>
    <fivef-icon-message-block [message]="'GENERAL.COMMENTS_DISABLED'"
                              [icon]="'chat_bubble_outline'"
                              [alignment]="'horizontal'"
                              [size]="'sm'"></fivef-icon-message-block>
  </ng-template>

  <!--<div class="mb-1 d-flex justify-content-end" *ngIf="isMember$ | async">-->
  <!--  <button mat-button (click)="openCommentDownloadDialog()" >-->
  <!--    {{ 'PROJECT_ROOM.DOWNLOAD_COMMENTS' | translate }}-->
  <!--    <mat-icon matSuffix>file_download</mat-icon>-->
  <!--  </button>-->
  <!--</div>-->

  <!--<dvtx-export-comments [selectedId]="selected?.id"-->
  <!--                      [id]="collector.id"-->
  <!--                      #exportCommentsComponentRef></dvtx-export-comments>-->

  <!-- Variant for artifacts -->
  <div class="five-f-comments-tab__download-comments d-flex justify-content-end" *ngIf="isMember$ | async">
    <button mat-button (click)="openCommentDownloadDialog()">
      {{ 'PROJECT_ROOM.DOWNLOAD_COMMENTS' | translate }}
      <mat-icon>file_download</mat-icon>
    </button>
  </div>
  <fivef-export-comments [currentProcessArtifact]="currentProcessArtifact"
                         [id]="currentProcessArtifact? currentProcessArtifact.id : (processId$ | async)"
                         #exportCommentsComponentRef></fivef-export-comments>
</div>

<ul class="fivef-comments--comment-listing">
  <li class="fivef-comments--comment-item"
      *ngFor="let comment of comments$ | async; trackBy: trackByFn">
    <fivef-comment [comment]="comment"
                   [resourceType]="_resourceType"
                   [canCreateComment]="canCreateComment"
                   (onSave)="saveComment(comment.id, $event)"
                   (onReply)="send($event, comment.id)"
                   (onDelete)="deleteComment($event)"
                   (onReaction)="react(comment?.id, $event)"></fivef-comment>
  </li>
</ul>

<ng-template [ngIf]="(loading$ | async) || (sendingLoading$ | async)">
  <fivef-loading-indicator></fivef-loading-indicator>
</ng-template>
