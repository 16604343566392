import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Comment} from 'app/+store/comment/comment';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';
import {Observable} from 'rxjs/internal/Observable';
import {FivefCommentsRepository} from './fivef-comments.repository';
import {CommentReactionType, CommentResourceType} from '../../../../../+store/comment/comment.interface';
import {ProcessOrganizationSelectorService} from '../../../../../+store/process/process-organization-selector.service';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {FivefExportCommentsComponent} from '../fivef-export-comments/fivef-export-comments.component';
import {ContentCache} from '../../../../../+store/content-cache/content-cache';

@Component({
  selector: 'fivef-comments',
  host: {'class': 'fivef-node-comments'},
  templateUrl: './fivef-comments.component.html',
  styleUrls: ['./fivef-comments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefCommentsComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject<void>();

  // @ViewChild('exportCommentsComponentRef')
  // private exportCommentsComponentRef: ExportCommentsComponent;

  public loading$: Observable<boolean>;

  public sendingLoading$: Observable<boolean>;
  public comments$: Observable<Comment[]>;
  public comment = '';

  @ViewChild('exportCommentsComponentRef')
  exportCommentsComponentRef: FivefExportCommentsComponent;

  @Input()
  public canCreateComment = false;

  /**
   * Uses a minimal froala toolbar for little space embedment.
   */
  @Input()
  slimToolbar = false;

  @Input()
  public commentPrefix = null;

  // added for document preview download comments
  @Input()
  public currentProcessArtifact = null;

  @Output()
  onSend = new EventEmitter();

  public _resourceType: CommentResourceType = 'process';
  public isMember$: Observable<boolean>;
  public processId$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public rid: string;

  @Input()
  set resourceType(type: CommentResourceType) {
    this._resourceType = type;
    this.repo.resourceType = type;
  }

  @Input()
  set resourceId(rid: string) {
    this.repo.resourceId = rid;
    this.rid = rid;
    this.setContentCacheContextId();
  }

  @Input()
  set processId(id: string) {
    this.repo.processId = id;
    this.processId$.next(id);
    this.setContentCacheContextId();
  }

  public contentCacheContextId: string = '';

  constructor(private repo: FivefCommentsRepository,
              private cdr: ChangeDetectorRef, private _processOrganizationService: ProcessOrganizationSelectorService) {
  }

  ngOnInit() {
    this.loading$ = this.repo.loading;
    this.comments$ = this.repo.comments;
    this.isMember$ = this._processOrganizationService.isMember$()
    // Restore the message on error cases.
    this.repo.restoreMessageOnFailure
      .pipe(takeUntil(this.onDestroy))
      .subscribe(msg => {
        if (msg && typeof msg === 'string' && msg.length > 0) {
          this.comment = msg;
          this.cdr.detectChanges();
        }
      })

  }

  private setContentCacheContextId() {
    this.contentCacheContextId = ContentCache.getContentCacheContextId('comment', (this.processId$ && this.processId$.value ? this.processId$.value : ''), this.rid)
  }

  public react(commentId: string, reactionType: CommentReactionType) {
    this.repo.react(commentId, reactionType);
  }

  /**
   * Send comment with current process/item context.
   * @param message
   * @param replyToId
   */
  public send(message: string, replyToId: string = null) {
    this.repo.send(message, replyToId);
    this.onSend.emit();
  }

  public saveComment(commentId: string, message: string) {
    this.repo.saveComment(commentId, message);
  }

  public deleteComment(commentId: string) {
    this.repo.deleteComment(commentId);
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.processId$.complete();
  }

  // openCommentDownloadDialog() {
  //   if (this.exportCommentsComponentRef) {
  //     this.exportCommentsComponentRef.openCommentDialog();
  //   }
  // }

  public trackByFn = (_index, comment: Comment) => {
    return comment.trackBy;
  }

  openCommentDownloadDialog() {
    if (this.exportCommentsComponentRef) {
      this.exportCommentsComponentRef.openCommentDialog();
    }
  }
}
