<ng-container [ngSwitch]="appearance">
  <div *ngSwitchCase="'progressbar'"
       class="fivef-loading-indicator--progressbar">
    <div class="fivef-loading-indicator--wrapper fivef-loading-indicator--font-size-{{fontSize}}">
      {{ message | translate }}<span>.</span><span>.</span><span>.</span></div>
    <mat-progress-bar class="fivef-loading-indicator--progressbar--progressbar"
                      [mode]="mode"></mat-progress-bar>
  </div>

  <div *ngSwitchCase="'spinnerOnly'" class="fivef-loading-indicator--spinner-only">
    <svg class="fivef-loading-indicator--spinner-only--loader">
      <circle class="fivef-loading-indicator--spinner-only--path-loader" cx="50" cy="50" r="20" fill="none"
              stroke-width="3" stroke-miterlimit="10"></circle>
    </svg>
  </div>

  <div *ngSwitchDefault class="fivef-loading-indicator--wrapper">
    <mat-spinner [diameter]="20" style="margin-right: 15px;"></mat-spinner>
    {{ message | translate }}<span>.</span><span>.</span><span>.</span>
  </div>
</ng-container>
