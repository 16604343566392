import {ProcessArtifactActions, ProcessArtifactActionTypes} from './process-artifact.actions';
import {adapter, initialState, State} from './process-artifact.state';
import {Update} from '@ngrx/entity';
import {IProcessArtifactStats, ProcessArtifact} from './process-artifact';
import {CollectionState, ICollectionStore} from '../collection/collection';
import {ProcessArtifactCollection} from './process-artifact.collection';

export function reducer(state = initialState, action: ProcessArtifactActions): State {
  let contextId: string;
  let collections: {[id: string]: ICollectionStore};
  let statisticEntities: {[id: string]: IProcessArtifactStats};

  switch (action.type) {
    /**
     * Resets present selectable IDs by an empty list.
     */
    case ProcessArtifactActionTypes.LoadSelectableIds:
      return {
        ...state,
        selectableIds: []
      };

    /**
     * Places currently selectable IDs in the store as `selectableIds` property.
     */
    case ProcessArtifactActionTypes.LoadSelectableIdsSuccess:
      return {
        ...state,
        selectableIds: action.artifacts.map(a => a.id)
      };

    /**
     * Artifact statistics for new upload state and further meta data.
     * The store property contains a minimal object.
     * It must be reset before on each process retrieval to be up-2-date.
     * The statistics help to keep data in dashboards in paginated contexts,
     * where not all artifact resources are in store.
     */
    case ProcessArtifactActionTypes.LoadStatistics:
      return {
        ...state,
        statistics: [],
        statisticEntities: {},
      };
    /**
     * Set the statistics on success and replace all existing values.
     */
    case ProcessArtifactActionTypes.LoadStatisticsSuccess:
      const statObjects: IProcessArtifactStats[] = [];
      const statEntities: {[id: string]: IProcessArtifactStats } = {};
      action.artifacts.forEach(a => {
        const statObj: IProcessArtifactStats = ProcessArtifact.buildArtifactStatObject(a);
        statObjects.push(statObj);
        statEntities[statObj.id] = statObj;
      });
      return {
        ...state,
        statistics: statObjects,
        statisticEntities: statEntities
      };
    case ProcessArtifactActionTypes.UpdateAllSuccess:
      return adapter.updateMany(action.payload, {
        ...state,
        loading: false,
      });
    case ProcessArtifactActionTypes.LoadAll:
      return {
        ...state,
        loading: action.loading
      };
    case ProcessArtifactActionTypes.MarkSeenSuccess:
    case ProcessArtifactActionTypes.MarkDownloaded:
      const artifact = state.entities[action.id];
      if (artifact) {
        const downloadedArtifact = Object.assign({}, artifact) as ProcessArtifact;
        downloadedArtifact.newUpload = false;

        // Reset statistic object that is consumed by according selectors for indicators in dashboards.
        statisticEntities = Object.assign({}, state.statisticEntities);
        if (statisticEntities[action.id]) {
          statisticEntities[action.id]['newUpload'] = false;
        }

        return adapter.upsertOne(downloadedArtifact, {
          ...state,
          loading: false,
          statisticEntities: statisticEntities
        });
      }
      return {
        ...state
      };
    case ProcessArtifactActionTypes.LoadOne:
    case ProcessArtifactActionTypes.LoadAllExternal:
      return {
        ...state,
        loading: true
      };
    case ProcessArtifactActionTypes.Delete:
      return {
        ...state,
        loading: false
      };
    case ProcessArtifactActionTypes.PickFileFromDms:
    case ProcessArtifactActionTypes.CollectorPickFileFromDms:
      return {
        ...state,
        transfering: true,
        loading: true
      };
    case ProcessArtifactActionTypes.LoadAllSuccess:
      if (action.upsertEntities) {
        const artifacts: Update<ProcessArtifact>[] = [];
        for (const item of action.payload) {
          artifacts.push({id: item.id, changes: item});
        }
        return adapter.updateMany(artifacts, {
          ...state,
          loading: false,
        });
      } else {
        return adapter.setAll(action.payload, {
          ...state,
          loading: false,
        });
      }

    case ProcessArtifactActionTypes.EstablishCollection:
      contextId = action.context.getCollectionId();
      collections = Object.assign({}, state.collections);
      if (!state.collections[contextId]) {
        collections[contextId] = {ids: [], next: null, loading: true, size: 0, total: 0, state: CollectionState.Initialized};
      } else {
        collections[contextId] = {
          ...collections[contextId],
          loading: true
        }
      }

      return {
        ...state,
        collections: collections
      };

    /**
     * Replace an existing collection with new contextualized resource ID mappings.
     * Updates the size and total by last element if available.
     */
    case ProcessArtifactActionTypes.InitOrResetCollectionSuccess:
      contextId = action.context.getCollectionId();
      collections = Object.assign({}, state.collections);

      collections[contextId] = {
        ...collections[contextId],
        state: !!action.nextToken ? CollectionState.Established : CollectionState.Completed,
        next: action.nextToken,
        loading: false,
        ids: [...action.artifacts.map(a => a.id)]
      }

      if (action.artifacts.length > 0) {
        collections[contextId]['size'] = action.artifacts[action.artifacts.length - 1].records;
        collections[contextId]['total'] = action.artifacts[action.artifacts.length - 1].total;
      }

      return adapter.upsertMany(action.artifacts, {
        ...state,
        collections: collections
      });

    case ProcessArtifactActionTypes.LoadNext:
      contextId = action.context.getCollectionId();
      collections = Object.assign({}, state.collections);
      if (!state.collections[contextId]) {
        collections[contextId] = {ids: [], next: null, loading: true, size: 0, total: 0, state: CollectionState.Initialized};
      } else {
        collections[contextId] = {
          ...collections[contextId],
          loading: true
        }
      }

      return {
        ...state,
        collections: collections
      };

    case ProcessArtifactActionTypes.LoadNextSuccess:
      contextId = action.context.getCollectionId();
      collections = Object.assign({}, state.collections);

      collections[contextId] = {
        ...collections[contextId],
        state: !!action.nextToken ? CollectionState.Established : CollectionState.Completed,
        next: action.nextToken,
        loading: false,
        ids: [...collections[contextId].ids, ...action.artifacts.map(a => a.id)]
      }

      if (action.artifacts.length > 0) {
        collections[contextId]['size'] = action.artifacts[action.artifacts.length - 1].records;
        collections[contextId]['total'] = action.artifacts[action.artifacts.length - 1].total;
      }

      return adapter.upsertMany(action.artifacts, {
        ...state,
        collections: collections
      });

    case ProcessArtifactActionTypes.LoadNextFail:
      contextId = action.context.getCollectionId();
      collections = Object.assign({}, state.collections);
      collections[contextId] = {
        ...collections[contextId],
        loading: false
      }

      return {
        ...state,
        collections: collections
      };

    case ProcessArtifactActionTypes.MoveAllSuccess:
      return adapter.upsertMany(action.payload, {
        ...state,
        loading: false,
      });
    case ProcessArtifactActionTypes.LoadAllExternalSuccess:
      return adapter.upsertMany(action.payload, {
        ...state,
        loading: false,
      });
    case ProcessArtifactActionTypes.UpdateOneSuccess:
      // Update the collections e.g because of role changes.
      collections = ProcessArtifactCollection.updateAllCollections(state.collections, action.artifact);
      return adapter.upsertOne(action.artifact, {
        ...state,
        collections: collections,
      });
    case ProcessArtifactActionTypes.DeleteSuccess:
      collections = ProcessArtifactCollection.removeFromCollections(state.collections, action.id);

      const newStatistics = state.statistics.filter(a => a.id !== action.id);
      statisticEntities = Object.assign({}, state.statisticEntities);
      if (statisticEntities[action.id]) {
        delete statisticEntities[action.id];
      }
      return adapter.removeOne(action.id, {
        ...state,
        loading: false,
        collections: collections,
        statistics: newStatistics,
        statisticEntities: statisticEntities
      });
    case ProcessArtifactActionTypes.LoadOneSuccess:
    case ProcessArtifactActionTypes.LoadOneByIdSuccess:
      collections = ProcessArtifactCollection.updateAllCollections(state.collections, action.artifact);

      const newStatisticEntities: { [id: string]: IProcessArtifactStats } = Object.assign({}, state.statisticEntities);
      let newStatisticsByUpload: IProcessArtifactStats[] = state.statistics;
      if (!newStatisticEntities[action.artifact.id]) {
        const newStatItem: IProcessArtifactStats = ProcessArtifact.buildArtifactStatObject(action.artifact);
        newStatisticEntities[action.artifact.id] = newStatItem;
        newStatisticsByUpload = [newStatItem, ...newStatisticsByUpload];
      }

      return adapter.upsertOne(action.artifact, {
        ...state,
        loading: false,
        collections: collections,
        statistics: newStatisticsByUpload,
        statisticEntities: newStatisticEntities
      });

    case ProcessArtifactActionTypes.PickFileFromDmsSuccess:
    case ProcessArtifactActionTypes.CollectorPickFileFromDmsSuccess:
      return {
        ...state,
        transfering: false,
        loading: false,
      };
    case ProcessArtifactActionTypes.LoadAllFail:
    case ProcessArtifactActionTypes.DeleteFail:
    case ProcessArtifactActionTypes.LoadOneFail:
    case ProcessArtifactActionTypes.PickFileFromDmsFail:
    case ProcessArtifactActionTypes.CollectorPickFileFromDmsFail:
    case ProcessArtifactActionTypes.LoadAllExternalFail:
      return {
        ...state,
        transfering: false,
        loading: false
      };

    case ProcessArtifactActionTypes.Reset:
      return Object.assign({}, initialState);
  }
  return state;
}
