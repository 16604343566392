import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.state';
import {ContentCache} from './content-cache';

export class ContentCacheBuilder implements IApiResourceBuilder<ContentCache> {

  constructor(private _store: Store<AppState> = null) {
  }


  fromResponse(data): ContentCache {
    return new ContentCache(
      data.id,
      data.attributes.content);
  }

  toRequest(_: ContentCache) {
    return null;
  }

}
