import {ChangeDetectionStrategy, Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';

/**
 * Simple read only template label alike tag for artifact listings.
 * Used to mark artifacts with role 'template'.
 */
@Component({
  selector: 'fivef-template-tag',
  standalone: true,
  host: {class: 'fivef-template-tag'},
  imports: [CommonModule, TranslateModule],
  templateUrl: './fivef-template-tag.component.html',
  styleUrls: ['./fivef-template-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefTemplateTagComponent {
}
