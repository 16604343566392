import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {TranslateModule} from '@ngx-translate/core';
import {MatProgressBarModule} from '@angular/material/progress-bar';

@Component({
  selector: 'fivef-loading-indicator',
  host: {'class': 'fivef-loading-indicator'},
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule, TranslateModule, MatProgressBarModule],
  templateUrl: './fivef-loading-indicator.component.html',
  styleUrls: ['./fivef-loading-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefLoadingIndicatorComponent {
  @HostBinding('style.margin-top')
  marginTop = '0';

  @HostBinding('style.margin-bottom')
  marginBottom = '0';

  @Input()
  message = 'GENERAL.LOAD_ITEMS';

  @Input()
  mode: 'determinate' | 'indeterminate' | 'buffer' | 'query' = 'indeterminate'

  @Input()
  value = 100;

  @Input()
  fontSize: 's' | 'm' | 'l' = 's';

  @Input()
  appearance: 'spinner' | 'progressbar' | 'spinnerOnly' = 'spinner';

  @HostBinding('class')
  coverClass = '';

  @Input()
  set cover(c: boolean) {
    this.coverClass = c ? 'fivef-loading-indicator--cover' : '';
  };

  @Input()
  set spacing(sizeClass: 'xs' | 's' | 'm' | 'l' | 'xl') {
    this.marginTop = `var(--fivef-spacing-${sizeClass}`;
    this.marginBottom = `var(--fivef-spacing-${sizeClass}`;
  }
}
