<fivef-dialog [disablePadding]="true" [disableFooter]="true">
  <div class="fivef-preview-browser--toolbar py-1 px-3 fivef-artifact-preview-browser--container">
    <div class="w-100 d-flex justify-content-between align-items-center">
      <ng-container *ngIf="viewMode === 'document'">
        <div class="d-flex">
          <button mat-icon-button aria-label="Back" (click)="openPreviousArtifact()"
                  [disabled]="!selectedIds?.length || selectedIds?.length === 1"
                  [matTooltip]="'PREVIEW_BROWSER.PREVIOUS_PREVIEW' | translate">
            <mat-icon>arrow_back</mat-icon>
          </button>

          <button mat-icon-button aria-label="Next" (click)="openNextArtifact()"
                  [disabled]="!selectedIds?.length || selectedIds?.length === 1"
                  [matTooltip]="'PREVIEW_BROWSER.NEXT_PREVIEW' | translate">
            <mat-icon>arrow_forward</mat-icon>
          </button>

          <button mat-icon-button aria-label="Browse" (click)="openOverview()"
                  [disabled]="!selectedIds?.length || selectedIds?.length === 1"
                  [matTooltip]="'PREVIEW_BROWSER.PREVIEWS_OVERVIEW' | translate">
            <mat-icon>view_comfy</mat-icon>

            <!-- New uploads indicator -->
            <span class="five-f-indicator--unread-count-icon-indicator"
                  *ngIf="newUploadsPresent"></span>
          </button>

          <div class="d-flex align-items-center">
            <button mat-icon-button class="ml-4" (click)="switchToImagePreview()"
                    [matTooltip]="'PREVIEW_BROWSER.IMAGE_PREVIEW' | translate">
              <mat-icon>photo</mat-icon>
            </button>

            <mat-slide-toggle color="primary" (change)="switchDocumentViewMode($event)"
                              [checked]="documentViewMode === DocumentViewMode.PDF"></mat-slide-toggle>

            <button mat-icon-button (click)="this.switchToPdfPreview()"
                    [matTooltip]="'PREVIEW_BROWSER.PDF_PREVIEW' | translate">
              <mat-icon>picture_as_pdf</mat-icon>
            </button>

            <div class="ml-4">
              <button mat-icon-button (click)="download()"
                      [matTooltip]="'GENERAL.DOWNLOAD_ACTION' | translate">
                <mat-icon>file_download</mat-icon>
              </button>
            </div>

            <div>
              <button mat-icon-button (click)="loadPDF()"
                      [matTooltip]="'PREVIEW_BROWSER.LOAD_PDF' | translate">
                <mat-icon>picture_as_pdf</mat-icon>
              </button>
            </div>

            <ng-container *ngIf="currentProcessArtifact as artifact">
              <ng-template
                [ngIf]="(featureSet$ | async)?.canCreateSignature && signatureSupported(artifact) && (policy$ | async)?.hasMemberPriviliges">
                <fivef-signature-selection [action]="DocumentSignatureSelectionMenuViewType.Button"
                                           [processId]="processId" [document]="artifact">
                </fivef-signature-selection>
              </ng-template>
            </ng-container>
            <div>
              <button *ngIf="!fullScreen" mat-icon-button (click)="openFullScreenDialog(true)"
                      [matTooltip]="'Full Screen'">
                <mat-icon>fullscreen</mat-icon>
              </button>
              <button *ngIf="fullScreen" mat-icon-button (click)="openFullScreenDialog(false)"
                      [matTooltip]="'Normal Screen'">
                <mat-icon>fullscreen_exit</mat-icon>
              </button>
            </div>
          </div>
        </div>

        <div class="h3 px-3 d-flex align-items-center" [matTooltip]="currentProcessArtifact?.title">
          <h3 class="fivef-preview-browser--toolbar--filename text-truncate w-100 p-0 m-0">
            {{ currentProcessArtifact?.title }}
          </h3>
        </div>
      </ng-container>

      <ng-container *ngIf="viewMode === 'imagelist'">
        <div class="d-flex">
          <button mat-icon-button aria-label="Back" [matTooltip]="'GENERAL.BACK_ACTION' | translate"
                  (click)="toDocumentView()">
            <mat-icon>arrow_back</mat-icon>
          </button>
        </div>
        <div class="h3 px-3 d-flex align-items-center text-truncate">
          <h3 class="text-truncate w-100 p-0 m-0">
            {{ 'PREVIEW_BROWSER.PREVIEWS_OVERVIEW' | translate }}
            <span *ngIf="(thumbnails$ | async)?.length > 0">({{ (thumbnails$ | async)?.length }})</span>
          </h3>
        </div>
      </ng-container>

      <button mat-icon-button aria-label="Close"
              (click)="closeDialog()"
              [matTooltip]="'GENERAL.CLOSE_ACTION' | translate">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <div class="d-flex fivef-preview-browser--main">
    <div class="fivef-preview-browser--previews-wrapper">
      <!-- Thumbnail browser -->
      <div *ngIf="viewMode === 'imagelist'"
           mat-dialog-content>
        <ng-template [ngIf]="loading" [ngIfElse]="browserViewTpl">
          <fivef-loading-indicator class="ff-w100" [appearance]="'progressbar'"></fivef-loading-indicator>
        </ng-template>

        <ng-template #browserViewTpl>
          <fivef-artifact-preview-browser-overview [thumbnails]="thumbnails$ | async"
                                                   [processId]="processId"
                                                   [collection]="artifactCollection$ | async"
                                                   (onClick)="openDocumentView($event)"></fivef-artifact-preview-browser-overview>
        </ng-template>
      </div>

      <!-- Document view -->
      <ng-container *ngIf="viewMode === 'document' && currentPreview$ | async as preview">
        <!-- PDF viewer -->
        <ng-container *ngIf="documentViewMode === DocumentViewMode.PDF && preview.id">
          <div class="five-f-document-pdf-preview"
               [ngStyle]="fullScreen ? {'min-height': '90vh'} : {'min-height': '70vh'}"
               mat-dialog-content>
            <ng-template [ngIf]="(pdf$ | async)?.content" [ngIfElse]="pdfNotAvailable">
              <div class="d-flex w-100 justify-content-center">
                <fivef-pdf-viewer [content]="(pdf$ | async)?.content" [showEditingMode]="false"
                                  class="w-100"
                                  [ngStyle]="fullScreen ? {'min-height': '90vh'} : {'min-height': '70vh'}"></fivef-pdf-viewer>
              </div>
            </ng-template>

            <ng-template #pdfNotAvailable>
              <div class="p-5 ff-w100 d-flex justify-content-center" *ngIf="!pdfLoading && !pdfIsAvailable">
                <fivef-icon-message-block [icon]="'hide_image'"
                                          [message]="'SIGNATURE.WIZARD.PDF_NOT_AVAILABLE_FOR_DOCUMENT'"></fivef-icon-message-block>
              </div>

              <div *ngIf="pdfLoading"
                   class="ff-p-m ff-w100">
                <fivef-loading-indicator [message]="'SIGNATURE.WIZARD.LOAD_PDF'"
                                         [appearance]="'progressbar'"></fivef-loading-indicator>
              </div>
            </ng-template>
          </div>
        </ng-container>

        <!-- Image based artifact viewer -->
        <ng-container *ngIf="documentViewMode === DocumentViewMode.Image">
          <ng-template [ngIf]="preview.id && !isNotFoundDocument(preview)" [ngIfElse]="notFoundTpl">
            <!-- Found but zero pages: Error. -->
            <div class="fivef-preview-browser--preview-container pl-0 pr-0 pb-0 mb-1 mx-auto"
                 mat-dialog-content
                 *ngIf="preview.pages === 0">
              <div class="p-5 w-100 d-flex justify-content-center text-center">
                <fivef-icon-message-block [icon]="'hide_image'"
                                          [message]="'PREVIEW_BROWSER.PREVIEW_NOT_AVAILABLE_FOR_TYPE'"></fivef-icon-message-block>
              </div>
            </div>

            <!-- Page scroll area -->
            <div class="fivef-preview-browser--document-image-preview"
                 mat-dialog-content>
              <div *ngFor="let prevObj of preview.previews"
                   class="fivef-preview-browser--preview-container pl-0 pr-0 pb-0 mb-1 mx-auto"
                   style="width: 90%; height: 0px; position: relative;"
                   [style.paddingTop.%]="calculateImageHeightPercent(prevObj)">
                <div class="d-block m-1 w-100 h-100 p-0 m-0" style="position: absolute; top: 0; left: 0;">
                  <img class="p-0 m-0 w-100 dvtx-document-preview-image" inViewport
                       [inViewportOptions]="{ threshold: 0.0001 }" [dvtxLazyPreview]="prevObj"
                       #preview="dvtxLazyPreview" src="" alt="preview-browser"/>

                  <dvtx-lazy-preview-loading-skeleton *ngIf="preview.loading"></dvtx-lazy-preview-loading-skeleton>

                  <!-- Error on successful load but incomplete preview -->
                  <ng-container *ngIf="preview.loaded && !preview.isComplete">
                    <div class="p-5 w-100 d-flex justify-content-center">
                      <fivef-icon-message-block [icon]="'hide_image'"
                                                [message]="'PREVIEW_BROWSER.PREVIEW_NOT_AVAILBLE'"></fivef-icon-message-block>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-template>

          <!-- Preview could not be found -->
          <ng-template #notFoundTpl>
            <div class="fivef-preview-browser--preview-container pl-0 pr-0 pb-0 mb-1 mx-auto"
                 mat-dialog-content
                 *ngIf="isNotFoundDocument(preview)">
              <div class="ff-pt-xl ff-w100 ff-column ff-align-center">
                <fivef-icon-message-block [icon]="currentProcessArtifact | fivefFileIcon"
                                          [message]="currentProcessArtifact | fivefHumanFileType"></fivef-icon-message-block>
                <button mat-stroked-button
                        (click)="download()">
                  <mat-icon>file_download</mat-icon>
                  {{ 'GENERAL.DOWNLOAD_ACTION' | translate }}</button>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </ng-container>
    </div>

    <!-- Artifact details split screen (right side) -->
    <div *ngIf="viewMode === 'document'"
         class="pb-1 fivef-preview-browser--metadata-container">
      <ng-container>
        <mat-button-toggle-group class="five-f-mat-button-toggle-group m-0"
                                 style="border: none; padding: 0;"
                                 [(ngModel)]="selectedTab">
          <mat-button-toggle style="border-left: none !important;" [value]="0" [disableRipple]="true">
            {{ 'PREVIEW_BROWSER.DETAILS' | translate }}
          </mat-button-toggle>
          <mat-button-toggle style="border-left: none !important;" [value]="1" [disableRipple]="true">
            {{ 'PROJECT_ROOM.COMMENTS' | translate }}
          </mat-button-toggle>
        </mat-button-toggle-group>

        <ng-container [ngSwitch]="selectedTab">
          <ng-template [ngSwitchCase]="0">
            <dvtx-artifact-details [artifact]="currentProcessArtifact"></dvtx-artifact-details>
          </ng-template>

          <ng-template [ngSwitchCase]="1">
            <div class="fivef-artifact-preview-browser--comment-tab">
              <fivef-comments class="ff-pb-m"
                              [resourceType]="'artifact'"
                              [slimToolbar]="true"
                              [canCreateComment]="(policy$ | async)?.canCommentProcess"
                              [processId]="processId"
                              [currentProcessArtifact]="currentProcessArtifact"
                              [resourceId]="currentProcessArtifact?.id"></fivef-comments>
            </div>
          </ng-template>
        </ng-container>
      </ng-container>

      <!--
        TODO: WIP: Migrate to tab look and feel:
              Code sample: Code below still has issues with height.
              Toggle group is currently only used here at preview and is overridden by styles:
              See: styles > override > _mat_button_toggle.scss
              After migration remove override file to keep the toggle group free for an own implementation!
      <mat-tab-group mat-stretch-tabs
                     [selectedIndex]="selectedTab">
        <mat-tab [label]="'PREVIEW_BROWSER.DETAILS' | translate">
          <ng-template matTabContent>
            <dvtx-artifact-details [artifact]="currentProcessArtifact"></dvtx-artifact-details>
          </ng-template>
        </mat-tab>

        <mat-tab [label]="'PROJECT_ROOM.COMMENTS' | translate">
          <ng-template matTabContent>
            <div>
              <fivef-comments [resourceType]="'artifact'"
                              [canCreateComment]="(policy$ | async)?.canCommentProcess"
                              [processId]="processId"
                              [currentProcessArtifact]="currentProcessArtifact"
                              [resourceId]="currentProcessArtifact?.id"></fivef-comments>
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
      -->
    </div>
  </div>
</fivef-dialog>
