import {NgModule} from '@angular/core';


import {TablerIconsModule} from 'angular-tabler-icons';
import {
  IconStarFilled,
  IconLink,
  IconDotsVertical,
  IconCalendar,
  IconCalendarEvent,
  IconCamera,
  IconChevronLeft,
  IconChevronRight,
  IconFiles,
  IconHeart,
  IconMessage,
  IconUserPlus,
  IconExternalLink,
  IconArrowRight,
  IconCircleMinus,
  IconSend2,
  IconFileTypeDocx,
  IconFileTypeDoc,
  IconFileTypeJpg,
  IconGif,
  IconFileTypeCsv,
  IconFileZip,
  IconFileTypePng,
  IconFileTypePpt,
  IconFileTypeXls,
  IconFileTypePdf,
  IconFileTypeXml,
  IconDatabase,
  IconBinary,
  IconFileTypeTxt,
  IconArrowsDiff,
  IconBrandPython,
  IconFileTypeSvg,
  IconFileTypeHtml,
  IconJson,
  IconCertificate,
  IconFile,
  IconSourceCode
} from 'angular-tabler-icons/icons';

const icons = {
  IconCamera,
  IconHeart,
  IconCalendar,
  IconCalendarEvent,
  IconChevronRight,
  IconChevronLeft,
  IconLink,
  IconExternalLink,
  IconMessage,
  IconFiles,
  IconUserPlus,
  IconDotsVertical,
  IconStarFilled,
  IconArrowRight,
  IconCircleMinus,
  IconSend2,
  IconFileTypeDocx,
  IconFileTypeDoc,
  IconFileTypeJpg,
  IconGif,
  IconFileTypeCsv,
  IconFileZip,
  IconFileTypePng,
  IconFileTypePpt,
  IconFileTypeXls,
  IconFileTypePdf,
  IconFileTypeXml,
  IconDatabase,
  IconBinary,
  IconFileTypeTxt,
  IconArrowsDiff,
  IconBrandPython,
  IconFileTypeSvg,
  IconFileTypeHtml,
  IconJson,
  IconCertificate,
  IconFile,
  IconSourceCode
};

@NgModule({
  imports: [
    TablerIconsModule.pick(icons)
  ],
  exports: [
    TablerIconsModule
  ]
})
export class FivefIconsModule {
}
