import {Component, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import {DocumentPreviewDocument} from 'app/+store/document-preview-document/document-preview-document';
import {IProcessArtifactStats} from '../../../../../+store/process-artifact/process-artifact';
import {ProcessArtifactService} from '../../../../../+store/process-artifact/process-artifact.service';
import {InViewportMetadata} from 'ng-in-viewport';

@Component({
  selector: 'fivef-artifact-preview-browser-overview',
  templateUrl: './fivef-artifact-preview-browser-overview.component.html',
  styleUrls: ['./fivef-artifact-preview-browser-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FivefArtifactPreviewBrowserOverviewComponent {
  protected isInViewPort: {[id: string]: boolean} = {};

  protected itemLookupMap: { [id: string]: IProcessArtifactStats} = {}
  @Input()
  public thumbnails: DocumentPreviewDocument[] = [];

  @Input()
  public processId: string;

  @Output()
  public onClick = new EventEmitter<string>();

  @Input()
  public set collection(collection: IProcessArtifactStats[]) {
    collection?.forEach((a: IProcessArtifactStats) => {
      this.itemLookupMap[a.id] = a;
    })
  }

  constructor(private artifactSvc: ProcessArtifactService,
              private cdr: ChangeDetectorRef) {
  }

  protected onImageClick(artifactId: string): void {
    this.onClick.emit(artifactId);
  }

  protected calculateImageHeightPercent(document: DocumentPreviewDocument): number {
    if (document.previews.length === 0) {
      return 180;
    }
    const preview = document.previews[0];
    if (!preview.width || !preview.height || preview.width === 0 || preview.height === 0) {
      return 180;
    }
    return preview.height / preview.width * 100;
  }

  protected isNotFoundDocument(document: DocumentPreviewDocument): boolean {
    return document.pages === 0
      && document.displayName === 'Document Not Found'
      && document.fileName === ''
      && document.mimeType === null;
  }

  protected trackBy(_index: number, item: {id: string}): string {
    return item.id;
  }

  /**
   * Downloads the file.
   *
   * @param artifactId
   * @param title
   * @protected
   */
  protected download($event, artifactId, title: string) {
    $event.stopPropagation();
    this.artifactSvc.download(this.processId, { id: artifactId, title: title });
  }

  protected showItem(event, id: string): void {
    const {[InViewportMetadata]: {entry}, target, visible} = event;
    const isInViewPort = Object.assign({}, this.isInViewPort);
    isInViewPort[id] = visible;
    this.isInViewPort = isInViewPort;
    this.cdr.markForCheck();
  }
}
