import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {ProcessListingItemV3} from 'app/+store/process/process';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.state';
import {catchError, debounceTime, first, take, takeUntil} from 'rxjs/operators';
import {ProcessListingV3Service} from 'app/+store/process/process-listing-v3.service';
import {Router} from '@angular/router';
import {FivefNotificationService} from 'app/lib/fivef-ui/notification/fivef-notification/fivef-notification.service';
import {ProcessActions} from 'app/+store';
import {IProcessV3Query} from 'app/+store/process/process.interface';
import {Subject} from 'rxjs/internal/Subject';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {of} from 'rxjs/internal/observable/of';

@Component({
  selector: 'fivef-page-header-search-results',
  host: { class: 'fivef-page-header-search-results' },
  templateUrl: './fivef-page-header-search-results.component.html',
  styleUrls: ['./fivef-page-header-search-results.component.scss']
})
export class FivefPageHeaderSearchResultsComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject<void>();
  private cancelRunningSearch$ = new Subject<string>();

  public displayedColumns = ['project', 'type', 'view'];

  public dataSource: MatTableDataSource<ProcessListingItemV3> = new MatTableDataSource<ProcessListingItemV3>([]);

  private _processes: ProcessListingItemV3[] = [];

  public data$ = new BehaviorSubject<ProcessListingItemV3[]>([]);
  public searchTerm$ = new BehaviorSubject<string>(null);

  @Output()
  closeDropdown: EventEmitter<any> = new EventEmitter<any>();

  // Pagination setup
  public loading$ = new BehaviorSubject<boolean>(false);
  public page: number = 1;
  public pages: number = 1;

  private currentSearchTerm = '';

  public excludeClosedStatus: boolean = true;

  @Input()
  set search(searchTerm: string) {
    this.searchTerm$.next(searchTerm);
  }

  constructor(private store: Store<AppState>,
              private processV3Svc: ProcessListingV3Service,
              private router: Router,
              private notifyService: FivefNotificationService,
              private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    // Enable loading directly to give direct user feedback not being delayed by debounce below.
    this.searchTerm$
      .pipe(takeUntil(this.onDestroy))
      .subscribe(_searchTerm => {
        this.loading$.next(true);
      });

    this.searchTerm$
      .pipe(debounceTime(250), takeUntil(this.onDestroy))
      .subscribe(searchTerm => {
        // Cancel running search if term has changed.
        this.cancelRunningSearch$.next(searchTerm);
        this.currentSearchTerm = searchTerm;

        this.configureLocalStorageStatus();
        this._loadPage(1);
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.loading$.complete();
    this.data$.complete();
    this.searchTerm$.complete();
    this.cancelRunningSearch$.next('cancel');
    this.cancelRunningSearch$.complete();
  }

  private configureLocalStorageStatus() {
    if (localStorage.getItem('quick-search-exclude-closed')) {
      localStorage.getItem('quick-search-exclude-closed') == 'false' ? this.excludeClosedStatus = false : this.excludeClosedStatus = true;
    } else {
      localStorage.setItem('quick-search-exclude-closed', this.excludeClosedStatus.toString())
    }
  }

  public loadMore(event): void {
    if (this.page >= this.pages || (event && !event.visible)) {
      return;
    }
    this.page += 1;
    this._loadPage(this.page);
  }

  public _loadPage(page) {
    if (page === 1) {
      this._processes = [];
      this.page = 1;
    }
    const params: IProcessV3Query = {};
    if (this.currentSearchTerm) {
      params.search = this.currentSearchTerm;
    }
    if (params.search) {
      params.search = encodeURIComponent(params.search);
    }

    // set local storage for exclude status
    localStorage.setItem('quick-search-exclude-closed', this.excludeClosedStatus.toString())
    if (this.excludeClosedStatus) {
      params.status = ['in_progress', 'open']
    }

    this.processV3Svc.getAllMinifiedRes(page, params)
      .pipe(first(), takeUntil(this.cancelRunningSearch$))
      .subscribe(processes => {
        if (this.page === 1 && processes.length === 0) {
          this.pages = 0;
        }

        if (this.page === 1) {
          this._processes = [...processes];
        } else {
          this._processes = [...this._processes, ...processes];
        }
        this.data$.next(this._processes);

        this.dataSource.data = this._processes;
        // Pagination counter
        if (processes && processes[0]) {
          this.pages = processes[0].total;
        }
        // Disable auto load loading circle and enable viewport detection
        this.loading$.next(false);
      }, catchError(err => {
        console.error(err);
        return of(err);
      }));
  }

  public navigateToProcess(process) {
    if (!process) return;
    this.notifyService.info('PROJECT_ROOM.LOADING_PROJECT_ROOM')
    this.store.dispatch(new ProcessActions.RunCommand(process.id, 'index', 'on_click'));
    this.closeDropdown.emit();
  }

  public navigateToSearchPage() {
    this.router.navigate([`/workflows/my-workflows`]);
    this.closeDropdown.emit();
  }

  private getQuery() {
    let queryParams = `?page=${this.page}`;
    const _queryParams = [];
    queryParams = _queryParams.length > 0 ? `${queryParams}&${_queryParams.join('&')}` : queryParams;
    return queryParams;
  }
}
