import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import * as model from './system-health';

export namespace Operator {
  export class SystemHealthBuilder implements IApiResourceBuilder<model.Operator.SystemHealth> {
    total = 1;
    perPage = 1;
    records = 1;

    fromResponse(data): model.Operator.SystemHealth {
      return new model.Operator.SystemHealth(
        data.id,
        data.attributes.cpp_status,
        data.attributes.pvms_status,
        data.attributes.jobs.queues.critical,
        data.attributes.jobs.queues.default,
        data.attributes.jobs.queues.low,
        data.attributes.dms_status,
        data.attributes.jobs.queues.customer_queue,
        data.attributes.jobs.queues.dms_upload_queue,
        data.attributes.jobs.queues.dms_deletion_queue,
        data.attributes.jobs.queues.dms_cleanup_queue,
        data.attributes.jobs.queues.dms_sync_queue,
        data.attributes.jobs.queues.email_queue,
        data.attributes.jobs.queues.push_notifications_queue
      );
    }

    toRequest(_sms: model.Operator.SystemHealth) {
      return null;
    }
  }
}
