import {IFeature} from './feature.interface';

/**
 * Feature setup of the current user.
 * Feature toggles are based on the current organization as authorization
 * context.
 *
 * For process related features consider the IAM process policy.
 */
export class Feature implements IFeature {
  readonly type = 'sales_features';

  /**
   * Feature toggle for the audit proof functionality of the DMS.
   * Only available on commercial DMS setup (not supported by the 2GB free account).
   */
  hasAuditProofDms;

  /**
   * Annual audit feature set (german: Jahresabschlussprüfung).
   * Includes Third Party.
   */
  canCreateJap;

  /**
   * Bookman feature toggle.
   */
  hasBookman;

  /**
   * Authorization of the bookman administration module.
   * Necessary to display the admin panel.
   */
  canUpdateBookmanSettings;

  /**
   * Feature toggle for the labels module.
   */
  hasLabels;

  /**
   * @deprecated.
   * Feature toggle for the KANBAN view.
   *
   * Currently unmaintained.
   */
  hasKanban;

  /**
   * Feature toggle for the CMS module.
   */
  hasCms;


  /**
   * Feature toggle for partner links.
   */
  hasPartnerLinks: boolean;

  /**
   * Feature toggle for Fasdocs support.
   */
  hasFastdocs;

  /**
   * Fastdocs settings access for organizational members.
   */
  hasFastdocsSettings;

  /**
   * Digitalbar sync support.
   */
  hasDigitalbar;

  /**
   * Digitalbar settings access.
   */
  hasDigitalbarSettings;

  /**
   * 5F agent access (Outlook plugin).
   */
  hasFivefAgent;

  /**
   * 5F agent settings access (Outlook plugin).
   */
  hasFivefAgentSettings;

  /**
   * Feature toggle to create personal communication processes aka neutral variants of
   * CAV and CAC processes.
   */
  canCreatePc: boolean;

  /**
   * Feature toggle to create CAV and CAC processes.
   */
  canCreateKap: boolean;

  /**
   * Flag to indicate if the private storage is available.
   * Currently it is only available for employees/members not for business partners.
   * In future the private storate will be completely disabled.
   */
  hasMyStorage: boolean;

  /**
   * Big file support.
   */
  hasBigFileSupport: boolean;

  /**
   * DATEV DUo Support.
   */
  hasDatev: boolean;

  /**
   * DATEV DUo organizaional settings access.
   */
  hasDatevSettings: boolean;

  /**
   * Tenant administration support.
   */
  hasTenantAdminSupport;

  /**
   * License management support.
   *
   * NOTE: Feature is currently disabled. License management requires to be reworked.
   *       License booking is currently establsihed by the sales team and the 5F operator interface.
   */
  hasLicenceManagement;

  /**
   * Reporting module is enabled.
   * E.g. for participant excel export.
   */
  hasReporting: boolean;

  /**
   * 5F Desktop Client feature.
   */
  hasFivefDesktop;

  /**
   * 5F Desktop Client authorization for the user. This panel must be enabled at the employee settings details
   * of the employee.
   */
  hasFivefDesktopSettings;

  /**
   * 5F ELO DMS support for organization settings.
   */
  hasEloDms: boolean;

  /**
   * DATEV DMS feature support.
   */
  hasDatevDms;

  /**
   * DATEV DMS feature authorization for settings manipulation.
   * This feature must be enabled at the employee's details page.
   */
  hasDatevDmsSettings;

  /**
   * Signature workflow support. Enables the workflow inside the document context menus and at the dashboard starter.
   */
  canCreateSignature;

  /**
   * Authorization for ability to create main processes from dashboard.
   */
  canCreateWorkflows;

  /**
   * Authorization for ability to create sub processes inside workflows.
   * NOTE: This authorization setting has already a main process as IAM context.
   * Prefer the IAM process model.
   */
  canCreateSubWorkflows;

  /**
   * Special curacon feature to enable/disable the global workflow settings to switch the visibility
   * of the download timestamp tooltips with downloader and timestamp.
   */
  hasExportMarkerSettingsSupport: boolean;

  /**
   * Organization based mobile support toggle.
   * NOTE: Feature authorization can also be achieved at the user level (see User model).
   *       Only one of both is needed to enable the Mobile PIN panel.
   */
  hasMobileSupport: boolean;

  /**
   * Process Trash support.
   */
  hasProcessTrash: boolean;

  /**
   * User is tenant admin or tenant content admin and can configure the login theme setup.
   */
  canAuthorContent: boolean;

  constructor(public id: string) {
  }
}
