import {ICollector, IStatusNode, IStatusNodeVisitor} from './collector.interface';
import {DateUtil} from 'app/lib/date/date-util';
import {ItemStatus} from 'app/+store/collector/collector.interface';
import {Client} from '../client/client';
import {ProcessProfile} from '../process/process.interface';
import {CollectorCategory} from '../collector-category/collector-category';
import {Process} from '../process/process';

export class StatusNode implements IStatusNode {
  children: StatusNode[] = [];

  constructor(public id: string,
              public status: ItemStatus,
              public dueDate: Date,
              public completedAt: Date) {
  }

  visit(visitor) {
    visitor.accept(this);
  }
}

export class AllAcceptedVisitor implements IStatusNodeVisitor {
  nodeCount: number;
  matchCount = 0;

  constructor() {
    this.nodeCount = 0;
    this.matchCount = 0;
  }

  success() {
    return this.nodeCount > 0 && this.matchCount === this.nodeCount;
  }

  accept(node: StatusNode) {
    ++this.nodeCount;
    if (node.status === ItemStatus.Accepted
      || node.status === ItemStatus.Closed
      || node.status === ItemStatus.Completed) {
      ++this.matchCount;
    }

    if (node.children && node.children.length > 0) {
      node.children.forEach(n => n.visit(this));
    }
  }
}

export class AllInTimeAcceptedVisitor implements IStatusNodeVisitor {
  nodeCount: number;
  matchCount = 0;

  constructor() {
    this.nodeCount = 0;
    this.matchCount = 0;
  }

  success() {
    return this.nodeCount > 0 && this.matchCount === this.nodeCount;
  }

  accept(node: StatusNode) {
    ++this.nodeCount;
    if ((node.status === ItemStatus.Accepted
        || node.status === ItemStatus.Closed
        || node.status === ItemStatus.Completed)
      && DateUtil.inTime(node)) {
      ++this.matchCount;
    }

    if (node.children && node.children.length > 0) {
      node.children.forEach(n => n.visit(this));
    }
  }
}

export class StatusCountMapIterator implements IStatusNodeVisitor {
  statusMap = {};
  translationMap = {
    'de': {},
    'en': {}
  };

  constructor(public lang: string = 'de') {
    this.statusMap[ItemStatus.Sent] = 0;
    this.statusMap[ItemStatus.Received] = 0;
    this.statusMap[ItemStatus.InProgress] = 0;
    this.statusMap[ItemStatus.Overdue] = 0;
    this.statusMap[ItemStatus.Completed] = 0;
    this.statusMap[ItemStatus.Review] = 0;
    this.statusMap[ItemStatus.Accepted] = 0;

    this.translationMap['de'][ItemStatus.Sent] = 'Versand';
    this.translationMap['de'][ItemStatus.Received] = 'Empfangen';
    this.translationMap['de'][ItemStatus.InProgress] = 'In Bearbeitung';
    this.translationMap['de'][ItemStatus.Overdue] = 'Überfällig';
    this.translationMap['de'][ItemStatus.Completed] = 'Vollständig';
    this.translationMap['de'][ItemStatus.Review] = 'Im Review';
    this.translationMap['de'][ItemStatus.Accepted] = 'Angenommen';

    this.translationMap['en'][ItemStatus.Sent] = 'Sent';
    this.translationMap['en'][ItemStatus.Received] = 'Received';
    this.translationMap['en'][ItemStatus.InProgress] = 'In Progress';
    this.translationMap['en'][ItemStatus.Overdue] = 'Overdue';
    this.translationMap['en'][ItemStatus.Completed] = 'Completed';
    this.translationMap['en'][ItemStatus.Review] = 'In Review';
    this.translationMap['en'][ItemStatus.Accepted] = 'Accepted';
  }

  result() {
    const result = [];
    result.push({name: this.translationMap[this.lang][ItemStatus.Sent], value: this.statusMap[ItemStatus.Sent]});
    result.push({name: this.translationMap[this.lang][ItemStatus.Received], value: this.statusMap[ItemStatus.Received]});
    result.push({name: this.translationMap[this.lang][ItemStatus.InProgress], value: this.statusMap[ItemStatus.InProgress]});
    result.push({name: this.translationMap[this.lang][ItemStatus.Overdue], value: this.statusMap[ItemStatus.Overdue]});
    result.push({name: this.translationMap[this.lang][ItemStatus.Completed], value: this.statusMap[ItemStatus.Completed]});
    result.push({name: this.translationMap[this.lang][ItemStatus.Review], value: this.statusMap[ItemStatus.Review]});
    result.push({name: this.translationMap[this.lang][ItemStatus.Accepted], value: this.statusMap[ItemStatus.Accepted]});
    return result;
  }

  accept(node: StatusNode) {
    ++this.statusMap[ItemStatus.Sent];
    if (!DateUtil.inTime(node) && node.status !== ItemStatus.Closed && node.status !== ItemStatus.Accepted) {
      ++this.statusMap[ItemStatus.Overdue];
    } else {
      switch (node.status) {
        case ItemStatus.Accepted:
        case ItemStatus.Closed:
          ++this.statusMap[ItemStatus.Accepted];
          break;
        case ItemStatus.Completed:
          ++this.statusMap[ItemStatus.Completed];
          break;
        case ItemStatus.InProgress:
          ++this.statusMap[ItemStatus.InProgress];
          break;
        case ItemStatus.Received:
          ++this.statusMap[ItemStatus.Received];
          break;
        case ItemStatus.Review:
          ++this.statusMap[ItemStatus.Review];
          break;
        // case ItemStatus.Sent:
        //   ++this.statusMap[ItemStatus.Sent];
        //   break;
      }
    }
    if (node.children && node.children.length > 0) {
      node.children.forEach(n => n.visit(this));
    }
  }
}

export class RecipientStatusCount implements IStatusNodeVisitor {
  statusMap = {};
  translationMap = {
    'de': {},
    'en': {}
  };

  constructor(public lang: string = 'de') {
    this.statusMap[ItemStatus.Received] = 0;
    this.statusMap[ItemStatus.InProgress] = 0;
    this.statusMap[ItemStatus.Overdue] = 0;
    this.statusMap[ItemStatus.Completed] = 0;
    this.statusMap[ItemStatus.Accepted] = 0;

    this.translationMap['de'][ItemStatus.Sent] = 'Empfangen';
    this.translationMap['de'][ItemStatus.Received] = 'Empfangen';
    this.translationMap['de'][ItemStatus.InProgress] = 'In Bearbeitung';
    this.translationMap['de'][ItemStatus.Overdue] = 'Überfällig';
    this.translationMap['de'][ItemStatus.Completed] = 'Vollständig';
    this.translationMap['de'][ItemStatus.Review] = 'Im Review';
    this.translationMap['de'][ItemStatus.Accepted] = 'Angenommen';

    this.translationMap['en'][ItemStatus.Sent] = 'Received';
    this.translationMap['en'][ItemStatus.Received] = 'Received';
    this.translationMap['en'][ItemStatus.InProgress] = 'In Progress';
    this.translationMap['en'][ItemStatus.Overdue] = 'Overdue';
    this.translationMap['en'][ItemStatus.Completed] = 'Completed';
    this.translationMap['en'][ItemStatus.Review] = 'In Review';
    this.translationMap['en'][ItemStatus.Accepted] = 'Accepted';
  }

  result() {
    const result = [];
    result.push({name: this.translationMap[this.lang][ItemStatus.Received], value: this.statusMap[ItemStatus.Received]});
    result.push({name: this.translationMap[this.lang][ItemStatus.InProgress], value: this.statusMap[ItemStatus.InProgress]});
    result.push({name: this.translationMap[this.lang][ItemStatus.Overdue], value: this.statusMap[ItemStatus.Overdue]});
    result.push({name: this.translationMap[this.lang][ItemStatus.Completed], value: this.statusMap[ItemStatus.Completed]});
    result.push({name: this.translationMap[this.lang][ItemStatus.Accepted], value: this.statusMap[ItemStatus.Accepted]});
    return result;
  }

  accept(node: StatusNode) {
    ++this.statusMap[ItemStatus.Received];
    if (!DateUtil.inTime(node) && node.status !== ItemStatus.Closed && node.status !== ItemStatus.Accepted) {
      ++this.statusMap[ItemStatus.Overdue];
    } else {
      switch (node.status) {
        case ItemStatus.Accepted:
        case ItemStatus.Closed:
          ++this.statusMap[ItemStatus.Accepted];
          break;
        case ItemStatus.Completed:
        case ItemStatus.Review:
          ++this.statusMap[ItemStatus.Completed];
          break;
        case ItemStatus.InProgress:
          ++this.statusMap[ItemStatus.InProgress];
          break;
        // case ItemStatus.Sent:
        // case ItemStatus.Received:
        //   ++this.statusMap[ItemStatus.Received];
        //   break;
      }
    }
    if (node.children && node.children.length > 0) {
      node.children.forEach(n => n.visit(this));
    }
  }
}

export class RecipientAllAcceptedVisitor implements IStatusNodeVisitor {
  nodeCount: number;
  matchCount = 0;

  constructor() {
    this.nodeCount = 0;
    this.matchCount = 0;
  }

  success() {
    return this.nodeCount > 0 && this.matchCount === this.nodeCount;
  }

  accept(node: StatusNode) {
    ++this.nodeCount;
    if (node.status === ItemStatus.Accepted
      || node.status === ItemStatus.Closed
      || node.status === ItemStatus.Review
      || node.status === ItemStatus.Completed) {
      ++this.matchCount;
    }

    if (node.children && node.children.length > 0) {
      node.children.forEach(n => n.visit(this));
    }
  }
}

export class CollectorClosedVisitor implements IStatusNodeVisitor {
  nodeCount: number;
  matchCount = 0;

  constructor() {
    this.nodeCount = 0;
    this.matchCount = 0;
  }

  success() {
    return this.nodeCount > 0 && this.matchCount === this.nodeCount;
  }

  accept(node: StatusNode) {
    ++this.nodeCount;
    if (node.status === ItemStatus.Closed) {
      ++this.matchCount;
    }

    if (node.children && node.children.length > 0) {
      node.children.forEach(n => n.visit(this));
    }
  }
}

export class DocumentsAcceptedVisitor implements IStatusNodeVisitor {
  nodeCount: number;
  matchCount = 0;

  constructor() {
    this.nodeCount = 0;
    this.matchCount = 0;
  }

  success() {
    return this.nodeCount > 0 && this.matchCount === this.nodeCount;
  }

  accept(node: StatusNode) {
    ++this.nodeCount;
    if (node.status === ItemStatus.Accepted
      || node.status === ItemStatus.Closed) {
      ++this.matchCount;
    }

    if (node.children && node.children.length > 0) {
      node.children.forEach(n => n.visit(this));
    }
  }
}

export class Collector implements ICollector {
  readonly type = 'workflow_engine_quickcollectors';
  color: string;
  client: Client;
  bookmanCockpitEnabled = false;
  processType = 'quickcollector';
  profile: ProcessProfile = ProcessProfile.StandardProfile;
  year: number;
  month: number;

  constructor(public id: string,
              public parentId: string,
              public title: string,
              public description: string,
              public state: string,
              public creatorName: string,
              public creatorEmail: string,
              public ownerName: string,
              public ownerEmail: string,
              public recipients: string[],
              public workers: string[],
              public dmsFolderId: string,
              public createdAt: Date,
              public updatedAt: Date,
              public categories: CollectorCategory[] = [],
              public canCreateTask = true,
              public startsAt = null,
              public endsAt = null,
              public dueDate: Date = null,
              public startedAt = null,
              public identifier = null) {
  }

  clone(categories = []) {
    return Collector.clone(this, categories);
  }

  static clone(collector, categories) {
    return new Collector(
      collector.id,
      collector.parentId,
      collector.title,
      collector.description,
      collector.state,
      collector.ownerName,
      collector.ownerEmail,
      collector.creatorName,
      collector.creatorEmail,
      collector.recipients,
      collector.workers,
      collector.dmsFolderId,
      collector.createdAt,
      collector.updatedAt,
      collector.identifier,
      categories
    );
  }

  static asProcess(collector): Process {
    const p = new Process(
      collector.id,
      collector.title,
      null,
      collector.description,
      collector.dueDate,
      collector.createdAt,
      'workflow_engine_quickcollectors',
      collector.state,
      collector.updatedAt
    );

    p.color = collector.color;
    if (collector.client) {
      p.client = collector.client;
    }
    return p;
  }
}

export class CollectorItemOrder {
  readonly type = '';

  constructor(public nodes: any[]) {
  }
}
