import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';
import {Observable} from 'rxjs/internal/Observable';
import {FivefArtifactPreviewBrowserComponent, IFivefArtifactPreviewBrowserDialogConfig, PreviewBrowserViewmode} from '../fivef-artifact-preview-browser/fivef-artifact-preview-browser.component';

export enum PreviewViewType {
  Icon = 'Icon',
  IconButton = 'IconButton',
  ListItem = 'ListItem',
  Custom = 'Custom'
}

@Component({
  selector: 'fivef-artifact-preview-dialog',
  templateUrl: './fivef-artifact-preview-dialog.component.html',
  styleUrls: ['./fivef-artifact-preview-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefArtifactPreviewDialogComponent implements OnDestroy {
  private onDestroy = new Subject<void>();

  protected readonly PreviewViewType = PreviewViewType;

  /**
   * Appearance of the button.
   */
  @Input()
  appearance: PreviewViewType = PreviewViewType.IconButton;

  /**
   * Process ID and main artifact scope.
   */
  @Input()
  processId: string;

  /**
   * Opens dialog in document or browser mode.
   */
  @Input()
  viewMode = PreviewBrowserViewmode.Document;

  /**
   * Disables the preview button.
   */
  @Input()
  disabled = false;

  /**
   * Restricts preview overview to IDs.
   */
  @Input()
  selectedIds: string[] = [];

  /**
   * Opens the dialog with artifact with ID in document view.
   */
  @Input()
  selectedId: string = null;

  /**
   * Restricts artifacts to those with reference.
   */
  @Input()
  referenceId: string = null;

  /**
   * Restricts artifacts to those with new upload flag true.
   */
  @Input()
  newUploadsOnly: boolean = false

  /**
   * Restricts artifacts to those with role.
   */
  @Input()
  role: 'default' | 'template' = null;

  /**
   * Opens preview in details tab (0) or comment tab (1).
   */
  @Input()
  selectedTab = 0;

  @Output()
  onClose = new EventEmitter();

  @Output()
  onCreateTask = new EventEmitter();

  private dialogRef: MatDialogRef<FivefArtifactPreviewBrowserComponent>;

  private isExtraSmall: Observable<BreakpointState>;

  constructor(private dialog: MatDialog,
              private breakpointObserver: BreakpointObserver) {
    this.isExtraSmall = this.breakpointObserver.observe(Breakpoints.XSmall);
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  public openDialog() {
    if (this.disabled) {
      console.error('[FivefArtifactPreviewDialogComponent] The preview dialog is disabled. Exit...')
      return;
    }

    const config: IFivefArtifactPreviewBrowserDialogConfig = {
      viewMode: this.viewMode,
      selectedTab: this.selectedTab,
      processId: this.processId,
      selectedId: this.selectedId,
      selectedIds: this.selectedIds,
      referenceId: this.referenceId,
      role: this.role,
      newUploadsOnly: this.newUploadsOnly
    }

    this.dialogRef = this.dialog.open(FivefArtifactPreviewBrowserComponent, {
      panelClass: ['five-f-transparent-background-w100-max-width', 'five-f-dialog-border-color'],
      width: '80vw',
      maxWidth: 'unset',
      data: config
    });

    const smallDialogSubscription = this.isExtraSmall.pipe(takeUntil(this.onDestroy)).subscribe(size => {
      if (size.matches) {
        this.dialogRef.updateSize('100%', '100%');
      } else {
        this.dialogRef.updateSize('80vw', '');
      }
    });

    this.dialogRef.afterClosed().subscribe(() => {
      if (smallDialogSubscription) {
        smallDialogSubscription.unsubscribe();
      }
    });

    this.dialogRef.beforeClosed().subscribe(() => {
      if (this.onClose && this.onClose.observers.length > 0) {
        this.onClose.emit();
      }
    });
  }
}
