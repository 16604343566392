import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  CollectorPickFileFromDms,
  CollectorPickFileFromDmsFail,
  CollectorPickFileFromDmsSuccess,
  Delete,
  DeleteFail,
  DeleteSuccess,
  EstablishCollection,
  InitOrResetCollection,
  InitOrResetCollectionAlreadyOngoing,
  InitOrResetCollectionSuccess,
  LoadAll,
  LoadAllExternal,
  LoadAllExternalFail,
  LoadAllExternalSuccess,
  LoadAllFail,
  LoadAllSuccess,
  LoadNext, LoadNextFail,
  LoadNextSuccess,
  LoadOne,
  LoadOneById,
  LoadOneByIdFail,
  LoadOneByIdSuccess,
  LoadOneFail,
  LoadOneSuccess,
  LoadStatistics,
  LoadStatisticsFail,
  LoadStatisticsSuccess,
  LoadSelectableIds,
  LoadSelectableIdsSuccess,
  LoadSelectableIdsFail,
  MarkSeen,
  MarkSeenFail,
  MarkSeenSuccess,
  PickFileFromDms,
  PickFileFromDmsFail,
  ProcessArtifactActionTypes,
  Rename,
} from './process-artifact.actions';
import * as dmsActions from '../dms-document/dms-document.actions';
import {ProcessArtifactService} from './process-artifact.service';
import {FivefNotificationService} from 'app/lib/fivef-ui/notification/fivef-notification/fivef-notification.service';
import {ProcessArtifact} from './process-artifact';
import {RefreshUploads} from '../process-event/process-event.actions';
import {IProcessArtifactQueryParams, IProcessArtifactStatsFilters} from './process-artifact.interface';
import {Store} from '@ngrx/store';
import {ProcessArtifactSelectors} from './index';
import {ICollectionStore} from '../collection/collection';

@Injectable()
export class ProcessArtifactEffects {
  /**
   * Background effect to load selectable IDs in current context.
   */
  loadSelectableIdss = createEffect(() => this.actions.pipe(
    ofType(ProcessArtifactActionTypes.LoadSelectableIds),
    concatMap((action: LoadSelectableIds) => {
      const context = action.context;
      // Currently 3 scope filters are used at ZIP dialog.
      // Role and reference ID as well as restriction to not exported artifacts.
      const config: IProcessArtifactStatsFilters = {
        role: context.role,
        referenceId: context.referenceId,
        excludeExported: context.excludeExported,
      }
      return this._svc.getArtifactStatisticsForProcess(context.processId, ['id'], config).pipe(
        first(),
        concatMap((artifacts: ProcessArtifact[]) => {
          return [new LoadSelectableIdsSuccess(artifacts)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadSelectableIdsFail(err));
        }));
    })
  ));

  loadProcessStatistics = createEffect(() => this.actions.pipe(
    ofType(ProcessArtifactActionTypes.LoadStatistics),
    concatMap((action: LoadStatistics) => {
      return this._svc.getArtifactStatisticsForProcess(action.id, action.select, action.config).pipe(
        first(),
        concatMap((artifacts: ProcessArtifact[]) => {
          return [new LoadStatisticsSuccess(artifacts)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadStatisticsFail(err));
        }));
    })
  ));

  initCollection$ = createEffect(() => this.actions.pipe(
    ofType(ProcessArtifactActionTypes.InitOrResetCollection),
    withLatestFrom(this.store.select(ProcessArtifactSelectors.getCollections)),
    concatMap(([action, collections]: [InitOrResetCollection, { [id: string]: ICollectionStore }]) => {
      const context = action.context;
      const collection = collections[context.getCollectionId()];
      if (!!collection && !action.reset) {
        console.error('[ProcessArtifactEffects] InitOrResetCollection: Collection present. Skipping...');
        return [new InitOrResetCollectionAlreadyOngoing(context)];
      }
      return [new EstablishCollection(context)];
    })
  ));

  establishCollection$ = createEffect(() => this.actions.pipe(
    ofType(ProcessArtifactActionTypes.EstablishCollection),
    concatMap((action: EstablishCollection) => {
      const context = action.context;
      const params: IProcessArtifactQueryParams = {
        referenceId: context.referenceId,
        role: context.role,
        eaId: context.externalAccessId,
        dmsProvider: context.dmsProvider,
        excludeExported: context.excludeExported,
        searchTerm: context.searchTerm
      };

      return this._svc.getCollection(action.context.processId, params).pipe(
        first(),
        concatMap((artifacts: ProcessArtifact[]) => {
          const nextToken = artifacts.length > 0 ? artifacts[artifacts.length - 1].next : null;
          return [new InitOrResetCollectionSuccess(context, artifacts, nextToken)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadNextFail(context, err));
        }));
    })
  ));

  loadNext$ = createEffect(() => this.actions.pipe(
    ofType(ProcessArtifactActionTypes.LoadNext),
    withLatestFrom(this.store.select(ProcessArtifactSelectors.getCollections)),
    concatMap(([action, collections]: [LoadNext, { [id: string]: ICollectionStore }]) => {
      const context = action.context;
      const collection = collections[context.getCollectionId()];
      if (!collection) {
        console.error('[ProcessArtifactEffects] LoadNext: Collection unset. Skipping...');
        return [new LoadNextFail(context, '[ProcessArtifactEffects] LoadNext: Collection unset. Skipping...')];
      }

      const nextToken = collection.next;
      if (!nextToken) {
        console.error('[ProcessArtifactEffects] Missing next token. Skipping...');
        return [new LoadNextFail(context, '[ProcessArtifactEffects] Missing next token. Skipping...')];
      }

      return this._svc.getCollection(action.context.processId, null, nextToken).pipe(
        first(),
        concatMap((artifacts: ProcessArtifact[]) => {
          const newNextToken = artifacts.length > 0 ? artifacts[artifacts.length - 1].next : null;
          return [new LoadNextSuccess(context, artifacts, newNextToken)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadNextFail(context, err));
        }));
    })
  ));

  loadAll$ = createEffect(() => this.actions.pipe(
    ofType(ProcessArtifactActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll(action.id, action.recursive).pipe(
        first(),
        concatMap((res: ProcessArtifact[]) => {
          return [new LoadAllSuccess(res, action.upsertEntities)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err));
        }));
    })
  ));

  loadOne$ = createEffect(() => this.actions.pipe(
    ofType(ProcessArtifactActionTypes.LoadOne),
    switchMap((action: LoadOne) => {
      return this._svc.getOne(action.processId, action.id).pipe(
        first(),
        concatMap((artifact: ProcessArtifact) => {
          return [new LoadOneSuccess(artifact)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadOneFail(err));
        }));
    })
  ));

  markSeen$ = createEffect(() => this.actions.pipe(
    ofType(ProcessArtifactActionTypes.MarkSeen),
    switchMap((action: MarkSeen) => {
      return this._svc.markSeen(action.id).pipe(
        first(),
        concatMap((artifact: ProcessArtifact) => {
          return [new MarkSeenSuccess(action.id)];
        }),
        catchError(err => {
          console.error(err);
          return of(new MarkSeenFail(err));
        }));
    })
  ));

  loadOneById$ = createEffect(() => this.actions.pipe(
    ofType(ProcessArtifactActionTypes.LoadOneById),
    concatMap((action: LoadOneById) => {
      return this._svc.getOneById(action.id).pipe(
        first(),
        concatMap((artifact: ProcessArtifact) => {
          return [new LoadOneByIdSuccess(artifact)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadOneByIdFail(err));
        }));
    })
  ));

  rename$ = createEffect(() => this.actions.pipe(
    ofType(ProcessArtifactActionTypes.Rename),
    switchMap((action: Rename) => {
      return this._svc.rename(action.processId, action.id, action.fileName).pipe(
        first(),
        concatMap((artifact: ProcessArtifact) => {
          this._notifyService.success('GENERAL.DOCUMENT_RENAME_SUCCESSFULLY')
          return [new LoadAllExternalSuccess([artifact]), new RefreshUploads(action.processId, artifact.id)];
        }),
        catchError(err => {
          this._notifyService.error('GENERAL.DOCUMENT_RENAME_FAIL')
          console.error(err);
          return of(new LoadOneFail(err));
        }));
    })
  ));

  remove$ = createEffect(() => this.actions.pipe(
    ofType(ProcessArtifactActionTypes.Delete),
    switchMap((action: Delete) => {
      return this._svc.remove(action.processId, action.id, action.deleteFile).pipe(
        first(),
        concatMap((artifact: ProcessArtifact) => {
          if (action.deleteFile) {
            return [new DeleteSuccess(action.id), new dmsActions.RemoveSuccess(String(artifact.dmsDocumentId))];
          }
          return [new DeleteSuccess(action.id)];
        }),
        catchError(err => {
          console.error(err);
          return of(new DeleteFail(err));
        }));
    })
  ));

  loadAllExternal$ = createEffect(() => this.actions.pipe(
    ofType(ProcessArtifactActionTypes.LoadAllExternal),
    switchMap((action: LoadAllExternal) => {
      return this._svc.getAllExternal(action.token).pipe(
        first(),
        concatMap((res: ProcessArtifact[]) => {
          return [new LoadAllExternalSuccess(res)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllExternalFail(err));
        }));
    })
  ));

  pickFileFromDms$ = createEffect(() => this.actions.pipe(
    ofType(ProcessArtifactActionTypes.PickFileFromDms),
    concatMap((action: PickFileFromDms) => {
      return this._svc.pickFileFromDms(action.processId, action.referenceId, action.dmsDocumentId, action.dmsAccountType).pipe(
        first(),
        concatMap((artifact: ProcessArtifact) => { // Returns an upload, not an process artifact
          return [new CollectorPickFileFromDmsSuccess(artifact), new LoadAll(action.processId)]; // TODO: Remove all. We do not use pick file actions anymore.
        }),
        catchError(err => {
          console.error(err);
          return of(new PickFileFromDmsFail(err));
        }));
    })
  ));

  collectorPickFileFromDms$ = createEffect(() => this.actions.pipe(
    ofType(ProcessArtifactActionTypes.CollectorPickFileFromDms),
    concatMap((action: CollectorPickFileFromDms) => {
      return this._svc.collectorPickFileFromDms(action.collectorId, action.nodeId, action.role, action.dmsDocumentId, action.dmsAccountType).pipe(
        first(),
        concatMap((artifact: ProcessArtifact) => { // Returns an upload, not an process artifact
          return [new CollectorPickFileFromDmsSuccess(artifact), new LoadAll(action.collectorId)]; // TODO: Remove all. We do not use pick file actions anymore.
        }),
        catchError(err => {
          console.error(err);
          return of(new CollectorPickFileFromDmsFail(err));
        }));
    })
  ));

  constructor(private store: Store<any>,
              private actions: Actions,
              private _svc: ProcessArtifactService,
              private _notifyService: FivefNotificationService) {
  }
}




