/**
 * Default toolbar buttons of editor.
 *
 * Functions to be discussed:
 * 'insertVideo', 'imageReplace', 'imageAlign', 'imageRemove', '|', '-', 'imageDisplay', 'imageStyle', 'imageAlt', 'imageSize'
 */
export const WYSIWYG_DEFAULT_TOOLBAR = [
  [
    // Main history actions
    'undo', 'redo', '|',
    // Font manipulation.
    'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', '|',
    // Font sizing and coloring.
    'fontSize', 'fontFamily', 'textColor', 'backgroundColor', '|',
    // Alignment
    'align', 'outdent', 'indent', '|',
    // Extras
    'emoticons', 'insertTable', 'clearFormatting', 'html'
  ]
];

/**
 * Default toolbar for externals without HTML editing.
 */
export const WYSIWYG_RICH_EXTERNAL_TOOLBAR = [
  [
    // Main history actions
    'undo', 'redo', '|',
    // Font manipulation.
    'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', '|',
    // Font sizing and coloring.
    'fontSize', 'fontFamily', 'textColor', 'backgroundColor', '|',
    // Alignment
    'align', 'outdent', 'indent', '|',
    // Extras
    'emoticons', 'insertTable'
  ]
];

/**
 * Mini toolbar config for areas with less space.
 */
export const WYSIWYG_DEFAULT_TOOLBAR_XS = [['undo', 'redo', 'bold', 'italic', 'underline', 'textColor', 'backgroundColor', 'emoticons']];
