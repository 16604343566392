import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {FivefApiResourceService} from 'app/lib/fivef-net/fivef-api-resource/services/fivef-api-resource.service';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.state';
import {HttpClient} from '@angular/common/http';
import {ContentCacheBuilder} from './content-cache.builder';
import {ContentCache} from './content-cache';

@Injectable({providedIn: 'root'})
export class ContentCacheService {
  readonly BASE_PATH = 'api/v3/users/content_caches';

  constructor(private _http: FivefApiResourceService, private _store: Store<AppState> = null, private _plainHttp: HttpClient) {
  }


  getContentCacheById(id): Observable<ContentCache> {
    const encodedId = encodeURIComponent(id);
    const builder = new ContentCacheBuilder(this._store);
    return <Observable<ContentCache>>this._http.get<ContentCacheBuilder, ContentCache>(builder, `${this.BASE_PATH}/` + encodedId);
  }

  updateContentCache(contentCache: ContentCache): Observable<ContentCache> {
    const builder = new ContentCacheBuilder();
    const encodedId = encodeURIComponent(contentCache.id);
    const payload = {
      'data': {
        'attributes': {
          'content': contentCache.content
        }
      }
    };
    return <Observable<ContentCache>>this._http.put<ContentCacheBuilder, ContentCache>(builder, `${this.BASE_PATH}/` + encodedId, payload);
  }

  removeContentCache(id: string): Observable<ContentCache> {
    const encodedId = encodeURIComponent(id);
    const builder = new ContentCacheBuilder();
    return <Observable<ContentCache>>this._http.del<ContentCacheBuilder, ContentCache>(builder, `${this.BASE_PATH}/` + encodedId);
  }
}
